export default {
  apiTrafficManager:
    "https://talmudfinder-2-0.loadbalancer.dicta.org.il/TalmudFinder",
  synopsisApiTrafficManager: "https://synopsis-2-4.loadbalancer.dicta.org.il",
  removeNikudApiTrafficManager:
    "https://remove-nikud-0-1.loadbalancer2.dicta.org.il",
  uploadApiTrafficManager:
    "https://dicta-library-api.loadbalancer.dicta.org.il",
  // note: once the version of synopsis --
  // where the netlify redirect for /server goes to the load balancer --
  // goes live -- change the above to 'https://synoptic.dicta.org.il/server'
}
