<template>
    <div class="search d-flex justify-content-end" v-hotkey="keymap">
      <b-btn
        :href="searchUrl" 
        class="p-2 bg-white"
        >
        {{ hebrew ? 'חיפוש בספריה' : 'Search in library' }}
        <i class="fas fa-search"></i>
      </b-btn>
      <b-form-group v-if="currentRouteName === 'xPublications'" class="mb-0" :class="{'bg-white':searchText.length>0}" ref="formGroup" method="get">
        <b-input-group >
          <b-form-input 
            @focus="inputFocused"
            @blur="inputFocusedOut"
            v-model="searchText" 
            type="search" 
            action="https://search.dicta.org.il/"
            class="h-100 border-0 f-narkis"
            :class="{'bg-transparent':searchText.length===0, 'bg-white':searchText.length>0}"  
            >
          </b-form-input>
          <b-input-group-append>
            <b-btn 
              @click="goToSearch"
              class="bg-transparent border-0 px-2 shadow-none text-primary">
              <i class="fas fa-search"></i>
            </b-btn>        
          </b-input-group-append>
        </b-input-group >
      </b-form-group>    
    </div>
</template>
<script>
//import AboutDialog from "@/components/aboutDialog"

const searchUrl = process.env.VUE_APP_FILE_TYPE === 'shas' ? 'https://talmudsearch.dicta.org.il/'
                    : (process.env.VUE_APP_LIBRARY_SEARCH_FRONTEND_URL
                        ? process.env.VUE_APP_LIBRARY_SEARCH_FRONTEND_URL
                        : 'https://librarysearch.dicta.org.il')

export default {
  name: "Search",
  methods: {
    inputFocused () {
      this.$refs.formGroup.$el.classList.add('bg-white')
    },
    inputFocusedOut () {
      if (this.searchText.length===0)
        this.$refs.formGroup.$el.classList.remove('bg-white')
    },
    goToSearch () {
      if (this.searchText.length > 0 )
        window.open(searchUrl + "/result?text=" + this.searchText)
    }
  },
  data() {
    return {
      searchText: '',
      searchUrl: searchUrl
    }
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    },
    hebrew () {
      return this.$settings.hebrew
    },
    keymap () {
      return {
        'enter': () => {
          this.goToSearch()
        }
      }
    }    
  }  
}
</script>
<style lang="scss">
  .search {
    /* clears the ‘X’ from Internet Explorer */
    input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
    /* clears the ‘X’ from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }    
   
    .form-group {      
      border-radius: 4px;
      overflow: hidden;    
      transition: 0.6s;
      &:not(.bg-white) {
        background: rgba(255,255,255,0.5);
      }
      &:hover {
        //background: white;
        opacity: 1;
      }
      .input-group {
        height: 36px;
        .form-control {
          font-size: 22px;
        }
      }
      button {
        font-size: 18px;
        padding-top: 6px;
      }
    }
  }
</style>
<style lang="scss">  
  @media screen and (max-width: 767px) {
    .search {
      height: 72px;
      padding-top: 12px;
      width: 100%;
      .form-group {
        border-radius: 6px;
        .input-group {
          height: 48px;
          .form-control {
            font-size: 32px;
            padding: 7px 13px 11px 12px;
            background: white!important;
          }         
        }
        button {
          font-size: 22px;
          background: white!important;
          width: 50px;
        }         
      }
    }
  }
</style>