import { RunStates } from "./runStates"
export const StateChanges = {
  UPDATE_ROUTE: "UPDATE_ROUTE",
  SET_TEXT_FOR_API_CALL: "SET_TEXT_FOR_API_CALL",
  SET_BOOKS: "SET_BOOKS",
  SET_SELECTED_BOOK: "SET_SELECTED_BOOK",
  SET_BOOK_FILES: "SET_BOOK_FILES",
  SET_SELECTED_FILE: "SET_SELECTED_FILE",
  SET_FILE_DATA: "SET_FILE_DATA",
  SET_TEXT_FOR_REMOVE_NIKUD_API_CALL: "SET_TEXT_FOR_REMOVE_NIKUD_API_CALL",
  SET_TEXT_FOR_SYNOPSIS: "SET_TEXT_FOR_SYNOPSIS",
  SET_SESSION_ID: "SET_SESSION_ID",
  SET_SYNOPSIS_FILE_RESULTS: "SET_SYNOPSIS_FILE_RESULTS",
  SET_VERSE_FOR_CHAPTER: "SET_VERSE_FOR_CHAPTER",
  SET_CHAPTER_RESULTS: "SET_CHAPTER_RESULTS",
  LIBRARY_NOT_RUN: "LIBRARY_NOT_RUN",
  LIBRARY_COMPLETE: "LIBRARY_COMPLETE",
  LIBRARY_RUNNING: "LIBRARY_RUNNING",
  LIBRARY_FAILED: "LIBRARY_FAILED",
  DOWNLOAD_NOT_RUN: "DOWNLOAD_NOT_RUN",
  DOWNLOAD_COMPLETE: "DOWNLOAD_COMPLETE",
  DOWNLOAD_RUNNING: "DOWNLOAD_RUNNING",
  DOWNLOAD_FAILED: "DOWNLOAD_FAILED",
  SYNOPSIS_NOT_RUN: "SYNOPSIS_NOT_RUN",
  SYNOPSIS_COMPLETE: "SYNOPSIS_COMPLETE",
  SYNOPSIS_RUNNING: "SYNOPSIS_RUNNING",
  SYNOPSIS_FAILED: "SYNOPSIS_FAILED",
  SYNOPSIS_TIMED_OUT: "SYNOPSIS_TIMED_OUT",
  INCORRECT_BOOK: "INCORRECT_BOOK",
  INCORRECT_PAGE: "INCORRECT_PAGE",
  SERVER_FAILED: "SERVER_FAILED",
  SERVER_TIMED_OUT: "SERVER_TIMED_OUT",
  SET_REMOVE_NIKUD_RESULTS: "SET_REMOVE_NIKUD_RESULTS",
  SET_FONT_SIZE: "SET_FONT_SIZE",
  SET_SELECTED_DISPLAY: "SET_SELECTED_DISPLAY",
  SET_SHOW_NIKUD: "SET_SHOW_NIKUD",
  SET_USE_DICTA_FONT: "SET_USE_DICTA_FONT",
  SET_SHOW_PUNCTUATION: "SET_SHOW_PUNCTUATION",
  SET_SHOW_ABBR: "SET_SHOW_ABBR",
  SET_SHOW_TAGGING: "SET_SHOW_TAGGING",
  SET_SHOW_FLAGGED: "SET_SHOW_FLAGGED",
  SET_SHOW_EIM: "SET_SHOW_EIM",
  SET_HIDE_HEADER: "SET_HIDE_HEADER",
  SET_HIDE_ABBR_DIALOG: "SET_HIDE_ABBR_DIALOG",
  SET_HIDE_FEEDBACK_DIALOG: "SET_HIDE_FEEDBACK_DIALOG",
  CLEAR_RESULTS: "CLEAR_RESULTS",
  //SET_SHOW_SETTINGS: 'SET_SHOW_SETTINGS'
}

export const Actions = {
  LOAD_FROM_URL: "LOAD_FROM_URL",
  GET_BOOKS: "GET_BOOKS",
  GET_FILES: "GET_FILES",
  GET_FILE_DATA: "GET_FILE_DATA",
  RUN_CHAPTERS: "RUN_CHAPTERS",
  RUN_SYNOPSIS: "RUN_SYNOPSIS",
  RUN_REMOVE_NIKUD: "RUN_REMOVE_NIKUD",
  RUN_UPLOAD: "RUN_UPLOAD",
}

export const BlankState = {
  txtForApi: "",
  bookList: [],
  selectedBook: {},
  bookFiles: [],
  selectedFile: "",
  fileData: [],
  dataForSynopsis: [],
  textForRemoveNikudApiCall: "",
  synopsisFiles: [],
  removeNikudApiResponse: "",
  sessionID: 0,
  verseForChapter: [],
  chapterResults: "",
  serverState: RunStates.NOT_RUN,
  downloadState: RunStates.NOT_RUN,
  synopsisServerState: RunStates.NOT_RUN,
  incorrectPage: false,
  incorrectBook: false,

  // showPageSettings,
  // hideFeedbackDialog: ,
  // hideAbbrDialog,

  fontSize: 16,
  showParallels: false,
  showNikud: process.env.VUE_APP_FILE_TYPE === "shas",
  useDictaFont: false,
  showPunctuation: false,
  showAbbr: false,
  showManualTagging: true,
  showFlaggedWords: false,
  showEIM: process.env.VUE_APP_FILE_TYPE !== "shas",
  hideHeader: false,
  hideAbbrDialog: false,
  hideFeedbackModal: false,
  //showSettings: true
}
