<template>
  <div class="book-list">
    <spinner v-if="running"></spinner>
    <div class="container">
      <div class="search-wrap pt-4 position-relative">
        <i
          class="fas fa-search position-absolute pt-4 pr-2"
          style="top: 7px"
        ></i>
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          placeholder="הזינו שם של ספר או שם מחבר או שנת הדפסה"
          @input="replaceQuotes"
          @keydown="englishKeyEventToHebrew"
        ></b-form-input>
        <small
          id="book-not-exist"
          :class="{ invisible: bookData.length !== 0 || running }"
          >ספר לא קיים במאגר</small
        >
      </div>
      <span class="d-none d-lg-block">
        <div
          class="book-count text-muted"
          :class="{ invisible: bookData.length === 0 }"
        >
          {{ hebrew ? "מציג" : "Showing" }}
          <b
            >{{ (currentPage - 1) * perPage + 1 }} -
            {{ Math.min(currentPage * perPage, bookData.length) }}</b
          >
          {{ hebrew ? "מתוך" : "" }} <b>{{ bookData.length }}</b>
          {{ hebrew ? "ספרים" : "Books" }}
        </div>
        <b-table
          id="book-table"
          ref="table"
          dir="rtl"
          hover
          :fields="hebrew ? fields : fields"
          responsive
          sort-by="displayName"
          :items="bookData"
          :per-page="perPage"
          :current-page="currentPage"
          sticky-header="1500px"
          class="bg-transparent"
          @row-clicked="rowClicked"
        >
        </b-table>
        <div class="text-center pagination-wrap">
          <b-pagination
            use-router
            v-model="currentPage"
            :total-rows="bookData.length"
            :per-page="perPage"
            limit="8"
            prev-text="לעמוד  הקודם"
            next-text="לעמוד הבא"
            aria-controls="book-table"
            hide-goto-end-buttons
            page-class="num-btn"
            next-class="text-btn"
            prev-class="text-btn"
          >
          </b-pagination>
        </div>
      </span>
    </div>
    <div class="d-block d-lg-none pb-2">
      <h2
        class="mt-0 mb-3 text-body text-center d-flex align-items-center justify-content-center"
      >
        {{ hebrew ? "רשימת ספרים" : "Book List" }}
        <span class="rounded-circle bg-background mx-2">{{
          bookData.length
        }}</span>
      </h2>
      <div class="container">
        <div
          class="mb-3 bg-white book-frame"
          v-for="(book, index) in bookData"
          :key="index"
          @click.stop="rowClicked(book)"
        >
          <h3 class="mb-1">{{ book.displayName }}</h3>
          <small
            v-if="book.author"
            class="author mb-1 rounded-pill d-inline-block bg-background border text-muted px-2 ml-2"
          >
            {{ book.author }}
          </small>
          <small
            v-if="book.category"
            class="category mb-1 rounded-pill d-inline-block bg-background border text-muted px-2 ml-2"
          >
            {{ book.category }}
          </small>
          <small
            v-if="book.printYear"
            class="year mb-1 rounded-pill d-inline-block bg-background border text-muted px-2"
          >
            {{ book.printYear }}
          </small>
        </div>
      </div>
    </div>
    <server-failed-popup v-if="failed" v-model="failed" />
  </div>
</template>
<script>
const hebrewKeyboard = {
  q: "\u002F",
  w: "\u0027",
  e: "\u05E7",
  r: "\u05E8",
  t: "\u05D0",
  y: "\u05D8",
  u: "\u05D5",
  i: "\u05DF",
  o: "\u05DD",
  p: "\u05E4",
  a: "\u05E9",
  s: "\u05D3",
  d: "\u05D2",
  f: "\u05DB",
  g: "\u05E2",
  h: "\u05D9",
  j: "\u05D7",
  k: "\u05DC",
  l: "\u05DA",
  ";": "\u05E3",
  z: "\u05D6",
  x: "\u05E1",
  c: "\u05D1",
  v: "\u05D4",
  b: "\u05E0",
  n: "\u05DE",
  m: "\u05E6",
  ",": "\u05EA",
  ".": "\u05E5",
  "/": "\u002E",
  "'": ",",
}
const specialEnglishKeys = new Set([
  ";|Semicolon",
  "'|Quote",
  ",|Comma",
  ".|Period",
  "/|Slash",
])

//import data from "../../sources/index.json"
import { Actions } from "@/store/stateChanges"
import Spinner from "@/components/spinner"
import { RunStates } from "@/store/runStates"
import ServerFailedPopup from "@/components/ServerFailedPopup"
import eventBus from "@/js/EventBus"

export default {
  name: "PublicationsTable",
  props: ["value"],
  components: { Spinner, ServerFailedPopup },
  data() {
    return {
      fields: [
        {
          key: "displayName",
          label: "שם",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "category",
          label: "קטגוריה",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "printYear",
          label: "שנת הדפסה",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "printLocation",
          label: "מקום הדפסה",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "author", label: "מחבר", sortable: true, sortDirection: "desc" },
      ],
      engFields: [],
      perPage: 15,
      currentPage: 1,
      filter: null,
      filterOn: ["displayName", "author", "printYear"],
    }
  },
  created() {
    // Listen for the 'feedback mode changed' from header component from the global event bus
    eventBus.$on("logoClicked", this.resetParamsAndFilters)
  },
  mounted() {
    this.$store.dispatch(Actions.GET_BOOKS)
    this.setFilters()
  },
  methods: {
    setFilters() {
      // Get the values of 'search' and 'page' parameters from the URL
      const searchParam = this.$route.query.search || null
      const pageParam = parseInt(this.$route.query.page) || 1

      // Update the component's data with the retrieved values
      setTimeout(() => {
        this.filter = searchParam
        this.currentPage = pageParam
      }, 30)
    },
    resetParamsAndFilters() {
      setTimeout(() => {
        this.currentPage = 1
        this.filter = null
        const newRoute = { path: this.$route.path, params: {} }

        // Replace the current route with the new one
        this.$router.replace(newRoute).catch(() => {})
      }, 200)
    },
    englishKeyEventToHebrew(keyEvt) {
      // a Hebrew key was pressed, don't translate
      if (/^[א-ת]$/.test(keyEvt.key)) {
        return
      }
      let input = document.getElementById("filter-input")
      // if this is a regular English key that could be a Hebrew letter
      if (
        !(
          keyEvt.metaKey ||
          keyEvt.shiftKey ||
          keyEvt.ctrlKey ||
          keyEvt.altKey
        ) &&
        (/^[a-z]$/.test(keyEvt.key) ||
          // we only translate punctuation while using an English keyboard, but if someone is using Hebrew,
          // they should be able to type a comma without having it convert to a ת
          specialEnglishKeys.has(keyEvt.key + "|" + keyEvt.code))
      ) {
        keyEvt.preventDefault()
        this.translatingEnglishKeys = true
        // someone may be typing in the middle of the input box
        const startpos = input.selectionStart || 0
        const endpos = input.selectionEnd || startpos
        // replace the selection (in the case of a cursor, it's the same as a selection of 0 characters)
        input.value =
          input.value.substring(0, startpos) +
          hebrewKeyboard[keyEvt.key.toLowerCase()] +
          input.value.substring(endpos)
        // put the cursor back where the user is expecting it
        input.setSelectionRange(startpos + 1, startpos + 1)
        // trigger an input event so Vue will update v-model
        input.dispatchEvent(new Event("input", { bubbles: true }))
        // return true in case the caller wants to test to see if this function handled the key
        return true
      }
      return false
    },
    replaceQuotes(event) {
      //replace mac quotes with window quotes
      const inputValue = event.trim()
      const replacedValue = inputValue.replace("\u05f4", '"')
      this.filter = replacedValue
      //  this.$router.replace({ query: { search: replacedValue, page: 1 } });
    },
    rowClicked(item) {
      this.$router.replace({
        query: { search: this.filter, page: this.currentPage },
      })
      this.$store.commit("SET_SELECTED_FILE", "")
      this.$store.commit("SET_SELECTED_BOOK", item)
      this.$nextTick(() => {
        this.$store.dispatch(Actions.GET_FILES)
      })
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    items() {
      let books = [...this.$store.state.bookList]
      return books.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
    },
    bookData() {
      let filteredItems = JSON.parse(JSON.stringify(this.items))
      if (this.filter) {
        let filteredDisplayNames = filteredItems.filter((item) =>
          item.displayName
            ?.replace('"', "")
            .includes(this.filter.replace('"', ""))
        )
        let filteredAuthors = filteredItems.filter((item) =>
          item.author?.replace('"', "").includes(this.filter.replace('"', ""))
        )
        let filteredYears = filteredItems.filter(
          (item) => item.printYear && item.printYear === parseInt(this.filter)
        )
        if (
          filteredDisplayNames.length > 0 ||
          filteredAuthors.length > 0 ||
          filteredYears.length > 0
        ) {
          return [...filteredDisplayNames, ...filteredAuthors, ...filteredYears]
        } else {
          return []
        }
      }
      return filteredItems
    },
    rows() {
      return this.bookData.length
    },
    running() {
      return this.$store.state.serverState === RunStates.RUNNING
    },
    failed: {
      get() {
        return (
          this.$store.state.serverState === RunStates.FAILED ||
          this.$store.state.serverState === RunStates.TIMED_OUT
        )
      },
      set() {
        this.$store.commit("LIBRARY_NOT_RUN")
        // this.synopsisFileIndex = -1
      },
    },
  },
  watch: {
    $route(oldRoute, newRoute) {
      if (oldRoute.path !== newRoute.path) {
        this.setFilters()
      }
    },
  },
}
</script>
<style lang="scss">
.book-list {
  .book-frame {
    border-radius: 8px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #d8d8d8;
    padding: 11px 14px 8px;
  }

  h2 {
    background-color: #d6ecff;
    line-height: 59px;

    .rounded-circle {
      width: 33px;
      height: 33px;
      font-size: 14px;
      line-height: 34px;
    }
  }

  .search-wrap {
    .form-control {
      border-radius: 4px;
      padding-right: 30px;
    }
  }
}
</style>
<style lang="scss">
.publications {
  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 85px);
  }

  .book-count {
    padding-bottom: 3px;
  }

  //.table.b-table > thead > tr > [aria-sort=descending]
  //.table.b-table > thead > tr > [aria-sort=ascending]
  .table-responsive {
    .table.b-table {
      border-collapse: separate;
      border-spacing: 0 6px;

      thead {
        tr {
          th {
            &::before {
              content: "\f0d8";
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              position: absolute;
              font-size: 24px;
              top: -6px;
              right: 0;
            }

            &::after {
              content: "\f0d7";
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              position: absolute;
              font-size: 24px;
              top: 4px;
              right: 0;
            }
          }

          [aria-sort="none"],
          [aria-sort="descending"] {
            background-image: none;

            &::before {
              color: #bbb;
            }
          }

          [aria-sort="none"],
          [aria-sort="ascending"] {
            background-image: none;

            &::after {
              color: #bbb;
            }
          }

          th.table-b-table-default {
            padding-top: 6px;
            padding-bottom: 6px;
            background-color: #d6ecff;
            border: none;
            color: #646464;
            font-weight: normal;

            &:first-child {
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              padding-right: 28px;

              &::before,
              &::after {
                right: 8px;
              }
            }

            &:last-child {
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }
          }
        }
      }

      tr {
        border-radius: 2px;
        cursor: pointer;

        &:hover {
          td {
            background-color: #fbfbfb;
            border-color: #fbfbfb;

            &:first-child {
              border-color: #fbfbfb;
            }

            &:last-child {
              border-color: #fbfbfb;
            }
          }
        }

        td {
          border-bottom: solid 1px #e3e3e3;
          background-color: #fff;
          padding: 0.55rem;
          max-width: 185px;

          &:first-child {
            border-right: solid 1px #e3e3e3;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }

          &:last-child {
            border-left: solid 1px #e3e3e3;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
        }
      }
    }
  }

  .pagination-wrap {
    .b-pagination {
      direction: rtl;
      text-align: center;
      display: block;
      margin: 0;
      padding: 0 0 0.5rem 0;

      li {
        display: inline-block;
        margin: 0;

        &.text-btn {
          padding: 0.6rem 0.6rem 0;

          button {
            background-color: #e3e3e3;
            color: black;
            font-size: 14px;
            padding: 7px;
          }

          span {
            display: none;
          }
        }

        &.num-btn,
        &.disabled {
          .page-link {
            background: none;
            border-color: transparent;
            color: black;
            padding: 0.3rem;
            font-size: 14px;

            &:focus {
              box-shadow: none;
            }
          }

          &.active {
            .page-link {
              color: #9a9a9a;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
