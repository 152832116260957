<template>
  <div class="publications">
    <shas-list v-if="shasLibrary"></shas-list>
    <publications-table v-else></publications-table>    
  </div>
</template>

<script>
import PublicationsTable from "@/components/PublicationsTable"
import ShasList from "@/components/ShasList"
export default {
  name: "Publications",
  props: {},
  components: { PublicationsTable, ShasList },
  data() {
    return {
      shasLibrary: process.env.VUE_APP_FILE_TYPE === 'shas'
    }
  },
  mounted() {
  },
  methods: {
    loadFile() {

    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    }
  }
}
</script>
<style scoped lang=scss>

</style>
