<template>
  <div class="text-edit">
    <div class="position-absolute h-75 w-100" v-if="running">
      <spinner></spinner>
    </div>
    <b-container><text-editor></text-editor></b-container>

  </div>
</template>
   
<script>
import TextEditor from '@/components/TextEditor'
import { RunStates } from '@/store/runStates'
import Spinner from '@/components/spinner'
//import { Actions } from '@/store/stateChanges'
export default {
  name: 'TextEdit',
  components: { TextEditor, Spinner },

  methods: {
    
  },
  computed: {
    running() {
      return this.$store.state.serverState === RunStates.RUNNING
    },
  }
}
</script> 