<template>
  <div class="token-wrapper">
    <div
      v-if="loading"
      class="position-fixed text-center w-100 h-100"
      style="top: 0; left: 0; z-index: 9"
    >
      <spinner class="mt-5 pt-5"></spinner>
    </div>
    <div class="tokens bg-white mb-3 pb-sm-3 position-relative pl-md-4">
      <div class="custom" v-if="indexPage" v-html="fileData"></div>
      <div
        v-else
        class="text-right source-text scrollbar-hidden pl-md-3"
        ref="sourceText"
        dir="rtl"
        @scroll="checkMobilePosition"
        :class="{
          'show-parallels': showParallels,
          'parallels-minimized': parallelsMinimized,
        }"
      >
        <div class="wrap px-3 pt-1 px-sm-0 pt-sm-0 bg-white" ref="textWrap">
          <small
            class="error-note text-muted text-sm-center text-md-right d-block bg-light px-2 mb-2 py-1"
            v-if="
              (withNikud || withPunctuation || notHumanReviewed) && !shasLibrary
            "
          >
            {{
              notHumanReviewed
                ? "טקסט זה לא עבר הגהה, ולכן צפויים שיבושי ניקוד ופיסוק. אם נתקלתם בטעויות כאלה אנא ידעו אותנו."
                : "הניקוד והפיסוק בטקסט נוצרו אוטומטית, ולכן צפויים שיבושי ניקוד ופיסוק.אם נתקלתם בטעויות - אנא שלחו משוב."
            }}
          </small>
          <!--           <div
            v-if="topics"
            dir="ltr"
            class="text-left short-summary raised-frame px-2 py-2 mb-3 mx-1"
          >
            <small class="text-muted d-block">Topics</small>
            
          </div> -->
          <div v-if="summary">
            <div class="d-flex justify-content-between">
              <a href="#main"
                ><small class="text-underline">מעבר לטקסט</small></a
              >
              <b-btn
                class="bg-transparent shadow-none p-0 text-primary border-0"
                @click="enSummary = !enSummary"
              >
                {{ enSummary ? "עברית" : "English" }}
              </b-btn>
            </div>
            <ul class="px-1 list-unstyled text-left" dir="ltr" v-if="topics">
              <li class="mb-2" v-for="topic in topics" :key="topic">
                <small>{{ topic }}</small>
              </li>
            </ul>
            <generated-summary :eng="enSummary"></generated-summary>
            <english-translation v-if="englishText"></english-translation>
          </div>
          <span id="main" style="scroll-margin-top: 70px"></span>
          <span
            v-show="!loadingMobile"
            v-for="(token, j) in displayTokens"
            :key="j"
            class="token-wrapper"
            :class="{
              'feedback-item border border-warning rounded':
                token.feedbackClicked,
              'punctuation-token':
                !feedbackMode && token.punctuationIDs && withPunctuation,
            }"
          >
            <b-popover
              v-if="token.abbreviationID >= 0 && abbrChecked"
              :target="'token' + j"
              placement="top"
              title=""
              triggers="click blur"
              :content="
                stringWithoutMeteg(
                  fileData.data.abbreviatonResults[
                    fileData.tokens[j].abbreviationID
                  ].options[0]
                )
              "
            >
            </b-popover>
            <br v-if="token.text.includes('\n') && !shasLibrary" />

            <span
              @click="onTokenClicked(j)"
              :id="'token' + j"
              style="line-height: 2"
              :style="{
                fontSize: token.heading
                  ? computedFontSize + 10 + 'px'
                  : computedFontSize + 'px',
              }"
              :tabindex="feedbackMode ? 0 : -1"
              :class="{
                abbr: token.abbreviationID >= 0 && abbrChecked,
                'active position-relative':
                  token.active && !feedbackMode && showParallels,
                'first-active':
                  token.active &&
                  displayTokens[j - 1] &&
                  !displayTokens[j - 1].active &&
                  !feedbackMode &&
                  showParallels,
                'last-active':
                  token.active &&
                  displayTokens[j + 1] &&
                  !displayTokens[j + 1].active &&
                  !feedbackMode &&
                  showParallels,
                'f-narkis': !dictaFont,
                'f-dicta': dictaFont,
                edited: token.edited && showManualTagging,
                'font-weight-bold': token.bold || token.heading,
                'text-muted': token.flagged && showOCRFlaggedWords,
              }"
            >
              <br v-if="token.text.includes('\n') && shasLibrary" />
              <span v-if="token.markedParagraph"><br /><br /></span>
              <span
                v-if="token.question"
                id="question"
                style="scroll-margin-top: 70px"
              ></span>
              <span
                v-if="token.answer"
                id="answer"
                style="scroll-margin-top: 70px"
              ></span>
              <!-- <span v-if="token.flagged && showOCRFlaggedWords"><i class="text-muted mx-1 fas fa-flag"></i></span>
                            <span v-if="token.userStar && showManualTagging"><i class="text-muted fas fa-star"></i></span> -->
              <span class="d-inline-block" v-if="isMobile"></span>
              <a
                class="text-link text-body position-relative"
                target="_blank"
                :href="getLink(token.explicitCitationsIDs)"
                v-if="token.explicitCitationsIDs >= 0"
              >
                <span tabindex="0" v-html="wordForDisplay(token)"></span>
              </a>
              <span v-else tabindex="0" v-html="wordForDisplay(token)"></span>
              <span
                class="punctuation-mark text-danger"
                :class="{ 'd-inline-block': isMobile }"
                style="font-size: 0"
                v-if="token.dictaPunctuation && withPunctuation"
              >
                <span
                  :style="{ fontSize: computedFontSize + 'px' }"
                  class="d-inline-block"
                  @click.stop="onTokenClicked(j, token.dictaPunctuation)"
                  :tabindex="feedbackMode ? 1 : -1"
                >
                  {{ token.dictaPunctuation }}
                </span>
              </span>
              <!-- <span v-if="token.headingEnd"><br /></span> -->
              <span
                class="parallel"
                v-if="token.lastParallelBaseMatchedTextID && showParallels"
              >
                <small
                  v-for="(parallel, k) in token.lastParallelBaseMatchedTextID"
                  :key="k"
                  :id="
                    'parallel-link-' +
                    (token.parallelCounter -
                      (token.lastParallelBaseMatchedTextID.length - k) +
                      1)
                  "
                  :ref="'parallel-id' + parallel"
                  class="text-primary"
                  @click.stop="
                    getParallel(
                      parallel,
                      token.parallelCounter -
                        (token.lastParallelBaseMatchedTextID.length - k) +
                        1
                    )
                  "
                >
                  [{{
                    token.parallelCounter -
                    (token.lastParallelBaseMatchedTextID.length - k) +
                    1
                  }}]
                  <!--adjust counter according to length of parallels or citations-->
                </small>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/spinner"
import GeneratedSummary from "@/components/GeneratedSummary"
import EnglishTranslation from "@/components/EnglishTranslation"
import { RunStates } from "@/store/runStates"

export default {
  name: "TokenDisplay",
  props: ["feedbackMode", "feedbackTopic", "parallelsMinimized"],
  components: { Spinner, GeneratedSummary, EnglishTranslation },
  data() {
    return {
      indexPage: false,
      enSummary: false,
      hasPunctuation: false,
      displayTokens: [],
      abbrData: {},
      currentCitationOrParallelToken: {},
      boldParallelText: "",
      selectedParallel: 1,
      fileUpload: process.env.VUE_APP_FILE_TYPE === "file-upload",
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
    }
  },
  mounted() {
    this.loadSettings()
    if (this.fileUpload) {
      this.$nextTick(() => {
        this.getTokens()
      })
    }
  },
  methods: {
    loadSettings() {
      let savedSettings = localStorage.getItem("dicta-library-settings")
      if (savedSettings) {
        savedSettings = JSON.parse(savedSettings)
        this.$store.commit("SET_FONT_SIZE", savedSettings.fontSize)
        this.$store.commit("SET_SELECTED_DISPLAY", savedSettings.showParallels)
        this.$store.commit("SET_SHOW_NIKUD", savedSettings.withNikud)
        this.$store.commit(
          "SET_SHOW_PUNCTUATION",
          savedSettings.withPunctuation
        )
        this.$store.commit("SET_SHOW_ABBR", savedSettings.abbrChecked)
        this.$store.commit("SET_USE_DICTA_FONT", savedSettings.dictaFont)
        this.$store.commit("SET_SHOW_TAGGING", savedSettings.showManualTagging)
        this.$store.commit(
          "SET_SHOW_FLAGGED",
          savedSettings.showOCRFlaggedWords
        )
        this.$store.commit("SET_HIDE_ABBR_DIALOG", savedSettings.hideAbbrDialog)
        this.$store.commit(
          "SET_HIDE_FEEDBACK_DIALOG",
          savedSettings.hideFeedbackDialog
        )
        this.$store.commit("SET_HIDE_HEADER", savedSettings.hideHeader)
        //   this.$store.commit('SET_SHOW_SETTINGS', savedSettings.showPageSettings)
      }
    },
    wordForDisplay(token) {
      if (this.shasLibrary) {
        if (this.hideEIM) {
          let haserTxt = token.haser || token.nikudText
          return this.withNikud ? haserTxt : this.stringWithoutNikud(haserTxt)
          //return this.withNikud ? this.stringWithoutMeteg(token.nikudText) : this.stringWithoutNikud(this.stringWithoutMeteg(token.nikudText))
        } else {
          let txt = this.withNikud
            ? token.maleh || token.nikudText
            : token.nikudText.replace(/[^\sא-ת\p{P}]/gu, "")
          return txt.replace(
            /(.)\u05bd/g,
            '<span class="eim text-primary">$1</span>'
          )
        }
        //return this.withNikud ? token.nikudText.replace(/(.)\u05bd/g, '<span class="eim text-primary">$1</span>') : this.stringWithoutNikud(token.nikudText).replace(/(.)\u05bd/g, '<span class="eim">$1</span>')
      } else {
        return this.withNikud
          ? this.stringWithoutMeteg(token.nikudText)
          : token.text.replace(
              /(.)\u05bd/g,
              '<span class="eim text-primary">$1</span>'
            )
      }
    },
    checkMobilePosition() {
      if (this.$mq === "sm" && !this.showParallels) {
        if (
          this.$refs.textWrap.clientHeight - this.$refs.sourceText.scrollTop <
          600
        )
          this.$refs.sourceText.classList.add("scrollbar-hidden")
        else this.$refs.sourceText.classList.remove("scrollbar-hidden")
      } else if (this.$mq === "sm" && this.showParallels) {
        this.$refs.sourceText.classList.remove("scrollbar-hidden")
      }
    },

    onTokenClicked(tokenIndex, punctuation) {
      if (this.feedbackMode) {
        this.displayTokens.map((el) => (el.feedbackClicked = false))
        /*   if (punctuation !== undefined)
                      tokenIndex-- */
        let token = this.displayTokens[tokenIndex]
        token.feedbackClicked = true
        if (punctuation !== undefined) {
          this.$emit("tokenClicked", {
            text: this.withNikud
              ? this.stringWithoutMeteg(token.nikudText) + punctuation
              : token.text + punctuation,
            topic: "Punctuation",
          })
        } else {
          if (this.withNikud && token.nikudText !== token.text)
            // check if actual nikud
            this.$emit("tokenClicked", {
              text: this.stringWithoutMeteg(token.nikudText),
              topic: "Nikud",
            })
          else if (token.abbreviationID >= 0 && this.abbrChecked)
            this.$emit("tokenClicked", {
              text: token.text,
              topic: "Abbreviations",
            })
          else
            this.$emit("tokenClicked", { text: token.text, topic: "General" })
        }
      }
    },
    navigateParallels(direction) {
      if (direction === 1) {
        this.selectedParallel++
      } else {
        this.selectedParallel--
      }
      document.getElementById("parallel-link-" + this.selectedParallel).click()
      this.$nextTick(() => {
        let el = this.isMobile
          ? document.getElementById("parallel-link-" + this.selectedParallel)
          : document.getElementsByClassName("first-active")[0]
        if (this.isMobile) {
          el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        } else {
          let headerOffset = 60
          const bodyRect = document.body.getBoundingClientRect().top
          const elementRect = el.getBoundingClientRect().top
          const elementPosition = elementRect - bodyRect
          const offsetPosition = elementPosition - headerOffset

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          })
        }
      })
    },
    getLink(id) {
      let link = this.fileData.data.explicitCitationsResults[id].sources[0].url
      return "https://" + link
    },
    getParallel(parallelID, parallelCounter) {
      this.selectedParallel = parallelCounter
      this.displayTokens.map((el) => (el.active = false))
      let selectedTokens = this.displayTokens.filter((item) => {
        return item.parallelsIDs && item.parallelsIDs.indexOf(parallelID) > -1
      })
      this.setActiveTokens(selectedTokens)
      //let baseText = this.fileData.data.postProcessedSources[parallelID].baseMatchedText
      let parallels = this.fileData.data.postProcessedSources[parallelID] //.filter(obj => { return obj.baseMatchedText === baseText })
      this.$emit("results", {
        id: parallelCounter,
        data: parallels,
        selectedText: this.boldParallelText,
      })
    },
    getFirstCitationOrParallel() {
      let firstTokenWithParallel = this.displayTokens.find(
        (element) => element.lastParallelBaseMatchedTextID
      )
      if (firstTokenWithParallel) {
        this.getParallel(
          firstTokenWithParallel.lastParallelBaseMatchedTextID[0],
          firstTokenWithParallel.parallelCounter -
            firstTokenWithParallel.lastParallelBaseMatchedTextID.length +
            1
        ) //we can't use just parallel counter in case same token has multiple parallels
      } else {
        this.$emit("results", { noResults: true })
      }
    },

    getDictaPunctuation(index) {
      let dictaPunctuation = ""
      let dictaPunctuationId = this.fileData.tokens[index].punctuationID
      if (dictaPunctuationId) {
        dictaPunctuation =
          this.fileData.data.punctuationResults[dictaPunctuationId]
      }
      return dictaPunctuation
    },
    checkIfParagraph(i) {
      let index = i
      if (index === 0) return
      let currentToken = this.fileData.tokens[index]
      let prevToken = this.fileData.tokens[index - 1]
      /*  if (prevToken.sep) {
        while (prevToken.sep && index >= 1) {
          index--
          prevToken = this.fileData.tokens[index]
        }
      } */
      return currentToken.markedParagraph && !prevToken.markedParagraph
    },

    checkIfHeadingEnd(i) {
      let index = i
      let nextToken = null
      //  if (index === this.fileData.tokens.length-1) return
      nextToken = this.fileData.tokens[index + 1]
      if (nextToken?.sep) {
        while (nextToken?.sep) {
          index++
          nextToken = this.fileData.tokens[index]
        }
      }

      if (!nextToken || typeof nextToken !== "object") {
        return true
      }
      return nextToken && !nextToken.heading
    },
    containsPunctuation(inputString) {
      const regex = /[,:.;]/
      return regex.test(inputString) && inputString.length === 1
    },
    /*   checkIfNextContainsPunctuation(index) {
      let currentToken = this.fileData.tokens[index]
      //combine certain punctuations with previous word so they don't hang. Never display those specific punctuations as standalone tokens
      let nextToken = this.fileData.tokens[index + 1]
      if (nextToken && nextToken.sep && !currentToken.sep) {
        if (this.containsPunctuation(nextToken.str.trim())) {
          return nextToken.str
        }
        return ""
      }
      return ""
    }, */

    getTokens() {
      if (
        typeof this.fileData !== "object" ||
        this.fileData.type === "text/html"
      ) {
        this.indexPage = true
      } else {
        this.indexPage = false
        this.hasPunctuation = this.fileData?.data?.punctuationResults
          ? true
          : false
        let parallelCounter = 0

        this.displayTokens = this.fileData.tokens.map((element, index) => {
          const nikudResults = this.fileData.data.nikudResults || {}
          const options = nikudResults[element.nikudID]?.options || []
          //const nextChar = "" //this.checkIfNextContainsPunctuation(index)
          let obj = {}
          obj.text = element.str
          if (options[0]) {
            obj.nikudText = options[0]?.w || ""
            obj.haser = options[0]?.haser || ""
            obj.maleh = options[0]?.maleh || ""
          } else {
            obj.nikudText = element.str
            obj.haser = element.str
            obj.maleh = element.str
          }
          obj.sep = element.sep
          obj.dictaPunctuation = this.hasPunctuation
            ? this.getDictaPunctuation(index)
            : ""
          obj.active = false
          obj.feedbackClicked = false
          obj.bold = element.bold
          obj.userStar = element.userStar
          obj.edited = element.edited
          obj.flagged = element.flagged
          obj.heading = element.heading
          obj.headingEnd = element.heading
            ? this.checkIfHeadingEnd(index)
            : false
          ;(obj.markedParagraph = this.checkIfParagraph(index)),
            (obj.question =
              element &&
              element.sectionType &&
              element.sectionType === "question")
          obj.answer =
            element && element.sectionType && element.sectionType === "answer"

          if (
            this.fileData.tokens[index + 1] &&
            this.fileData.tokens[index + 1].punctuationIDs
          ) {
            obj.punctuationIDs = this.fileData.tokens[index + 1].punctuationIDs
          }
          if (element.abbreviationID >= 0) {
            obj.abbreviationID = element.abbreviationID
          }
          if (element.explicitCitationsIDs >= 0) {
            obj.explicitCitationsIDs = element.explicitCitationsIDs
          }
          if (element.sourcesPostProcessedIDs && element.nikudID) {
            obj.lastParallelBaseMatchedTextID = []
            //  let matchedTextArr = []
            element.sourcesPostProcessedIDs.forEach((item) => {
              let found = this.fileData.tokens.find(
                (el) =>
                  el.sourcesPostProcessedIDs &&
                  el.sourcesPostProcessedIDs.indexOf(item) > -1 &&
                  el.nikudID > element.nikudID
              )
              obj.parallelsIDs = element.sourcesPostProcessedIDs
              if (!found) {
                //if (matchedTextArr.indexOf(this.fileData.data.parallelsResults.results[0].data[item].baseMatchedText) === -1) {
                //   matchedTextArr.push(this.fileData.data.parallelsResults.results[0].data[item].baseMatchedText)
                parallelCounter++
                obj.lastParallelBaseMatchedTextID.push(item)
                obj.parallelCounter = parallelCounter
                //}
              }
            })
            if (obj.lastParallelBaseMatchedTextID.length < 1) {
              delete obj.lastParallelBaseMatchedTextID
            }
          }
          return obj
        })
        this.$emit("countItems", parallelCounter)
        if (this.showParallels)
          //if new page
          this.getFirstCitationOrParallel()
        if (!this.isMobile) {
          if (window.scrollY > 95) {
            window.scrollTo({
              top: 90,
            })
          } else {
            window.scrollTo({
              top: 0,
            })
          }
        }
      }
    },
    stringWithoutMeteg(wrd) {
      return wrd.replace(/.[\u05bd]/g, "")
    },
    stringWithoutNikud(wrd) {
      return wrd.replace(/[\u05b0-\u05bc\u05c1\u05c2\u05c7]/g, "")
    },
    setActiveTokens(selectedTokens) {
      this.boldParallelText = ""
      let firstIndex = this.displayTokens.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(selectedTokens[0])
      )
      let lastIndex = this.displayTokens.findIndex(
        (item) =>
          JSON.stringify(item) ===
          JSON.stringify(selectedTokens[selectedTokens.length - 1])
      )
      for (let i = firstIndex; i <= lastIndex; i++) {
        this.displayTokens[i].active = true
        this.boldParallelText += this.fileData.tokens[i].str
        if (i == lastIndex) {
          this.currentCitationOrParallelToken = this.displayTokens[i]
        }
      }
    },
    getAbbrData(index) {
      if (this.fileData.tokens[index].abbreviationID && this.abbrChecked) {
        this.abbrData =
          this.fileData.data.abbreviatonResults[
            this.fileData.tokens[index].abbreviationID
          ].options[0]
      }
    },
    checkAbbr(index) {
      return this.displayTokens[index].abbreviationID && this.abbrChecked
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    summary() {
      return this.fileData?.data?.AIGenerated
    },
    englishText() {
      return this.fileData?.data?.englishTranslation
    },
    topics() {
      return this.fileData?.data?.topics
    },
    notHumanReviewed() {
      return this.$store.state.selectedBook.notHumanReviewed
    },
    bookList() {
      return this.$store.state.bookList
    },
    fileData() {
      return this.$store.state.fileData
    },
    loading() {
      return (
        this.$store.state.serverState === RunStates.RUNNING ||
        this.$store.state.synopsisServerState === RunStates.RUNNING
      )
    },
    isMobile() {
      return this.$mq === "sm"
    },
    loadingMobile() {
      return this.loading && this.$mq === "sm"
    },
    /*    computedFontSize() {
               return this.dictaFont ? this.$mq === 'sm' ? this.fontSize + 8 : this.fontSize + 4 : this.$mq === 'sm' ? this.fontSize + 4 : this.fontSize
           }, */
    computedFontSize() {
      return this.fontSize + 8
    },
    showOCRFlaggedWords() {
      return this.$store.state.showFlaggedWords
    },
    fontSize() {
      return this.$store.state.fontSize
    },
    withNikud() {
      return this.$store.state.showNikud
    },
    hideEIM() {
      return !this.$store.state.showEIM
    },
    dictaFont() {
      return this.$store.state.useDictaFont
    },
    withPunctuation() {
      return this.$store.state.showPunctuation
    },
    abbrChecked() {
      return this.$store.state.showAbbr
    },
    showManualTagging() {
      return this.$store.state.showManualTagging
    },
    showParallels() {
      return this.$store.state.showParallels
    },
  },
  watch: {
    showParallels(val) {
      if (val) {
        this.getFirstCitationOrParallel()
      }
    },
    fileData() {
      //for pagination change
      this.getTokens()
    },
    feedbackTopic(val) {
      if (val === "Parallels" || val === "")
        this.displayTokens.map((el) => (el.feedbackClicked = false))
    },
    feedbackMode(val) {
      if (!val) {
        this.displayTokens.map((el) => (el.feedbackClicked = false))
      }
    },
  },
}
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge add Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Firefox */
  }
}

.tokens {
  @media screen and (max-width: 767px) {
    border-radius: 6px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #d8d8d8;
  }

  .wrap {
    line-height: 2.5;
    text-align: justify;
  }

  .heading {
    font-size: 25px !important;
  }

  .sep.invisible {
    font-size: 16px !important;
  }

  .text-link {
    border-bottom: solid 1px #979797;

    &:hover {
      text-decoration: none;
    }
  }

  .error-note {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    line-height: 20px;
  }
  overflow-x: hidden;
  @media screen and (max-width: 767px) {
    .error-note {
      background: #f6f6f6 !important;
    }

    height: calc(100vh - 160px);
    border: none;
    box-shadow: none;
    background: #fbfbfb !important;
    // overflow: hidden;

    .wrap {
      height: auto;
      background-color: #fbfbfb !important;
      min-height: calc(100vh - 195px);
    }
  }

  .source-text {
    overflow-y: auto;

    @media screen and (max-width: 767px) {
      height: calc(100% - 35px);

      &.show-parallels {
        height: 51.5%;
        // height: 25%;

        &.parallels-minimized {
          height: calc(100% - 70px);
        }
      }
    }

    .abbr {
      cursor: help;
    }

    .active {
      z-index: 1;
      background: #d6ecff;
      box-shadow: inset 0px 6px 0px -2px #fbfbfb;

      @media screen and (min-width: 768px) {
        box-shadow: inset 0px 6px 0px -2px #fff;
      }
    }

    .last-active {
      @media screen and (min-width: 768px) {
        span.parallel {
          background: white;
        }
      }
    }

    .parallel small {
      font-family: "roboto";
      scroll-margin-top: 125px;
    }

    small:not(.error-note) {
      cursor: pointer;
      //  margin-right: -1px;
      position: relative;
    }
  }
}
</style>
