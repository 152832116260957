<template>
  <b-modal
    ref="feedback-modal"
    :id="modalID ? modalID : 'feedback-modal'"
    v-model="show"
    centered
    size="sm"
    :title="modalTitle ? modalTitle : hebrew ? 'שליחת משוב' : 'Send Feedback'"
    footer-class="border-top p-2"
    hide-header-close
    no-close-on-backdrop
    header-class="bg-primary text-white"
    title-tag="span"
  >
    <div class="how-to-text custom-msg" v-if="msg">
      {{ msg }}
    </div>
    <span v-else>
      <div class="how-to-text" v-if="hebrew">
        <p class="mb-1">
          כדי לשלוח משוב, יש ללחוץ על המילה / הפיסוק / המקבילית שעליהם אתם רוצים
          לשלוח משוב.
        </p>
        <p class="mb-1">מייד בלחיצה תיפתח תיבה שבה תוכלו להזין משוב.</p>
        <p class="mb-1">
          ליציאה ממצב ״שליחת משוב״, יש ללחוץ שוב על הכפתור של ״שליחת משוב״ כדי
          שיהפוך
          <br />
          לאפור (
          <b-form-checkbox
            class="d-inline-block mx-0"
            switch
            disabled
          ></b-form-checkbox>
          <span class="position-relative" style="right: -5px">)</span>
        </p>
      </div>
      <div v-else class="how-to-text">
        <p class="mb-1">
          To submit feedback, click on the word / punctuation / parallel that
          you would like to comment upon.
        </p>
        <p class="mb-1">
          A form will appear enabling you to enter the relevant information.
        </p>
        <p class="mb-1">
          To exit Feedback mode, Turn off the Feedback switch so that it is
          disabled. (
          <b-form-checkbox
            class="d-inline-block mx-0"
            switch
            disabled
          ></b-form-checkbox>
          <span class="position-relative" style="left: -5px">)</span>
        </p>
      </div>
    </span>
    <template slot="modal-footer">
      <b-form-checkbox v-model="dissmissFeedbackMessage">
        <span>
          {{ hebrew ? "אל תציג הודעה זו שוב" : "Don't show again" }}
        </span>
      </b-form-checkbox>
      <b-btn variant="secondary" @click="hideModal">
        {{ hebrew ? "הבנתי" : "Okay" }}
      </b-btn>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "FeedbackDialog",
  props: ["value", "msg", "modalTitle", "modalID"],
  data() {
    return {
      dissmissFeedbackMessage: true,
    }
  },
  methods: {
    hideModal() {
      this.$refs["feedback-modal"].hide()
      if (this.modalID === "abbr-notification") {
        this.$store.commit("SET_HIDE_ABBR_DIALOG", this.dissmissFeedbackMessage)
      } else
        this.$store.commit(
          "SET_HIDE_FEEDBACK_DIALOG",
          this.dissmissFeedbackMessage
        )
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
}
</script>
<style lang="scss">
#feedback-modal {
  .modal-sm {
    max-width: 380px;
  }
  .modal-header {
    padding: 0.4rem 1rem;
  }

  .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: #e3e3e3;
  }
}
</style>
