import { Actions, StateChanges } from "./stateChanges"
import { StoreConfig } from "./storeConfig"
import axios from "axios"
import * as JSZip from "jszip"
import backendRedirect from "../../backendRedirect"
import router from "../router"

//const LIBRARY_SERVER = 'https://dicta-library-data.netlify.app'
//const LIBRARY_SERVER = 'https://dicta-library-data.pages.dev'
const LIBRARY_SERVER = "https://files.dicta.org.il"

const baseUrl = backendRedirect.synopsisApiTrafficManager + "/synopsis"
const urlExtend = "/api/synopsis"
const urlUpload = "/uploadfile"
//const excelExtend = '/ExcelOutput'
const serverUrl = StoreConfig.serverUrl
//const urlExtendForResponse = '/api/response';

const axiosInstance = axios.create()
axiosInstance.defaults.timeout = 120000
axiosInstance.defaults.headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
}
export default {
  [Actions.LOAD_FROM_URL]: async function ({ commit, state, dispatch }) {
    commit(StateChanges.LIBRARY_RUNNING)
    let [, , fileName, page] = window.location.pathname.split("/")

    if (!state.selectedBook || state.selectedBook.fileName !== fileName) {
      await dispatch(Actions.GET_BOOKS)
      var index = state.bookList.findIndex((e) => e.fileName === fileName)

      if (index < 0) {
        commit("INCORRECT_BOOK", true)
        return
      }
      commit("SET_SELECTED_BOOK", state.bookList[index]) //for title display
    }

    if (page) {
      if (state.bookFiles.length === 0) {
        await dispatch(Actions.GET_FILES)
        if (state.bookFiles) {
          let fileIndex = state.bookFiles.findIndex(
            (e) => e.fileName === page + ".zip"
          )
          let htmlIndex = state.bookFiles.findIndex((e) => e.fileName === page)

          if (fileIndex === -1 && htmlIndex === -1) {
            commit("INCORRECT_PAGE", true)
          } else {
            commit(
              "SET_SELECTED_FILE",
              state.bookFiles[fileIndex > -1 ? fileIndex : htmlIndex].fileName
            )
            dispatch(Actions.GET_FILE_DATA)
          }
        }
      } else {
        // If bookFiles already exists, set selected file and dispatch GET_FILE_DATA
        if (page.includes("html")) commit("SET_SELECTED_FILE", page)
        else commit("SET_SELECTED_FILE", page + ".zip")
        dispatch(Actions.GET_FILE_DATA)
      }
    } else {
      // If page doesn't exist, load first page
      dispatch(Actions.GET_FILES)
    }
  },

  [Actions.GET_BOOKS]: async function ({ state, commit, dispatch }) {
    commit("INCORRECT_BOOK", false)
    commit("INCORRECT_PAGE", false)
    let booksUrl =
      process.env.VUE_APP_FILE_TYPE === "shas"
        ? "/books_shas.json"
        : process.env.VUE_APP_FILE_TYPE === "internal" ||
          process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle")
        ? "/books_internal.json"
        : "/books.json"

    commit(StateChanges.LIBRARY_RUNNING)
    try {
      const response = await axiosInstance.get(LIBRARY_SERVER + booksUrl)

      commit(StateChanges.SET_BOOKS, response.data)
      commit(StateChanges.LIBRARY_COMPLETE)
      if (
        process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle") &&
        state.bookFiles.length === 0 &&
        router.currentRoute.path.split("/").length === 2
      ) {
        dispatch(Actions.GET_FILES)
      }

      return response
    } catch (error) {
      commit(StateChanges.LIBRARY_FAILED)
      throw error
    }
  },

  [Actions.GET_FILES]: async function ({ commit, state }) {
    let selectedBook = "/" + state.selectedBook.fileName
    commit(StateChanges.LIBRARY_RUNNING)

    try {
      const response = await axiosInstance.get(
        LIBRARY_SERVER + selectedBook + "/pages.json"
      )
      commit(StateChanges.SET_BOOK_FILES, response.data)
      const book = window.location.pathname.split("/")[3]
      if (book) {
        state.selectedFile = book
      } else if (state.selectedFile === "") {
        state.selectedFile = response.data[0].fileName
      }

      if (process.env.VUE_APP_FILE_TYPE === "shas") {
        commit(StateChanges.LIBRARY_COMPLETE) //different because of chapter dialog
      } else {
        commit(StateChanges.UPDATE_ROUTE)
      }

      return response.data
    } catch (error) {
      console.log(error)
      commit(StateChanges.SERVER_FAILED)
      throw error
    }
  },

  [Actions.GET_FILE_DATA]: async function ({ state, commit }) {
    let selectedBook = "/" + state.selectedBook.fileName
    let selectedFile = "/" + state.selectedFile
    commit(StateChanges.LIBRARY_RUNNING)
    axios
      .get(LIBRARY_SERVER + selectedBook + selectedFile, {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data.type === "text/html") {
          const blob = response.data
          const reader = new FileReader()
          reader.onload = () => {
            const htmlContent = reader.result
            commit(StateChanges.SET_FILE_DATA, htmlContent)
          }
          reader.readAsText(blob)
          commit(StateChanges.SET_FILE_DATA, response.data)
          commit(StateChanges.LIBRARY_COMPLETE)
        } else {
          var zip = new JSZip()
          zip.loadAsync(response.data).then((contents) => {
            Object.keys(contents.files).forEach((filename) => {
              zip
                .file(filename)
                .async("string")
                .then((content) => {
                  commit(StateChanges.SET_FILE_DATA, JSON.parse(content))
                  commit(StateChanges.LIBRARY_COMPLETE)
                })
            })
          })
        }
      })
      .catch((reason) => {
        console.log(`axios request failed: ${reason}`)
        commit(StateChanges.LIBRARY_FAILED)
      })
  },
  [Actions.RUN_CHAPTERS]: function ({ commit, state }) {
    let axiosConfig = {
      headers: {
        "Content-Type": "text/plain;charset=UTF-8",
      },
    }
    let mode = state.verseForChapter.mode
    let verseId = state.verseForChapter.compBookXmlId
    let verseiWords = state.verseForChapter.verseiWords
    let verseDispHeb = state.verseForChapter.verseDispHeb

    axios
      .post(
        serverUrl + "/getchaptertext",
        JSON.stringify({
          verseId: verseId,
          verseiWords: verseiWords,
          mode: mode,
          verseDispHeb: verseDispHeb,
        }),
        axiosConfig
      )
      .then((response) => {
        commit(StateChanges.SET_CHAPTER_RESULTS, JSON.parse(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  },

  [Actions.RUN_SYNOPSIS]: async function ({ state, commit }) {
    try {
      commit(StateChanges.SYNOPSIS_RUNNING)

      const group = JSON.parse(JSON.stringify(state.dataForSynopsis))
      const dataArr = [{ content: group.baseMatchedText, id: "" }]

      group.sources.forEach((row) => {
        const boldWords = row.compDisplayText
          ? row.compDisplayText
              .match(/<b>(.*?)<\/b>/g)
              ?.map((val) => val.replace(/<\/?b>/g, "")) || []
          : row.compDisplayText.replace(/(<([^>]+)>)/gi, "")

        const content = boldWords
          .join(" ")
          .replace(/[.,/?#!$%^&*;:"'{}=\-_`~()]/g, " ")

        dataArr.push({
          content,
          id: row.compNameHe ? row.compNameHe : row.verseDispHeb,
        })
      })

      const witnessesData = { witnesses: dataArr }

      const response1 = await axiosInstance.post(
        baseUrl + urlExtend + urlUpload + "/0"
      )
      const sessionId = response1.data.id
      const url1 = baseUrl + urlExtend + urlUpload + "/" + sessionId

      await axios.post(url1, new FormData(), {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 45000,
      })

      const response2 = await axiosInstance.post(url1)
      const dataId = response2.data.id

      const fileName = "CollateX.json"
      const fileData = JSON.stringify(witnessesData)
      const blob = new Blob([fileData], { type: "text/plain" })
      const formData = new FormData()
      formData.append("file", blob, fileName, { type: "text/plain" })

      const url2 = baseUrl + urlExtend + urlUpload + "/" + dataId

      await axios.post(url2, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 45000,
      })

      const response4 = await axios.post(
        baseUrl + urlExtend + "/" + dataId,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )

      commit(StateChanges.SET_SYNOPSIS_FILE_RESULTS, {
        file: baseUrl + response4.data.horizontal_output_url.substr(1),
        sourceData: {
          baseStartChar: group.baseStartChar,
          baseTextLength: group.baseTextLength,
          baseMatchedText: group.baseMatchedText,
        },
        data: response4.data.top_items_alignment,
      })

      commit(StateChanges.SYNOPSIS_COMPLETE)
    } catch (error) {
      console.error(error)

      if (axios.isCancel(error)) {
        commit(StateChanges.SYNOPSIS_TIMED_OUT)
      } else {
        commit(StateChanges.SYNOPSIS_FAILED)
      }
    }
  },
  [Actions.RUN_REMOVE_NIKUD]: function ({ commit, state }) {
    let textForApi = state.textForRemoveNikudApiCall
    if (textForApi !== "") {
      // commit(StateChanges.TRANSLITERATE_RUNNING)
      axiosInstance
        .post(
          StoreConfig.REMOVE_SERVER,
          JSON.stringify({
            data: textForApi,
            genre: "rabbinic",
            fQQ: true,
          })
        )
        .then((response) => {
          commit(StateChanges.SET_REMOVE_NIKUD_RESULTS, response.data.results)
          // commit(StateChanges.TRANSLITERATE_COMPLETE)
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            // console.log('Request cancelled')
          } else {
            //commit(StateChanges.TRANSLITERATE_FAILED)
            console.log("Error")
          }
        })
    } else {
      commit(StateChanges.SET_REMOVE_NIKUD_RESULTS, "")
    }
  },
  [Actions.RUN_UPLOAD]: function ({ commit, state }) {
    let textForApi = state.txtForApi
    if (textForApi !== "") {
      commit(StateChanges.LIBRARY_RUNNING)
      axiosInstance
        .post(
          StoreConfig.uploadServerUrl,
          JSON.stringify({
            text: textForApi,
          })
        )
        .then((response) => {
          commit(StateChanges.SET_FILE_DATA, response.data)
          commit(StateChanges.LIBRARY_COMPLETE)
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            commit(StateChanges.LIBRARY_TIMED_OUT)
          } else {
            commit(StateChanges.LIBRARY_FAILED)
          }
        })
    }
  },
}
