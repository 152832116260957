<template>
  <div class="english-translation mb-3">
    <div dir="ltr" class="raised-frame px-2 pb-3 pt-1 mb-3 mx-1 text-left">
      {{ englishText }}
    </div>
  </div>
</template>
<script>
export default {
  name: "EnglishTranslation",
  components: {},
  data() {
    return {}
  },
  computed: {
    fileData() {
      return this.$store.state.fileData
    },
    englishText() {
      return this.fileData?.data?.englishTranslation
    },
  },
}
</script>
<style lang="scss"></style>
