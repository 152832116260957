<template>
  <div class="enter-text mt-3">
    <div class="text-editor position-relative border rounded border-dark" ref="textAreaWrap" v-hotkey="keymap">
      <div class="erase position-absolute p-2" id="del-btn" v-if="nikudText && nikudText !== ''" @click="clearText">
        <span aria-hidden="true">&times;</span>
      </div>
      <textarea ref="textArea" id="textEntryArea" @focus="focusedEvent" @focusout="focusedOutEvent" dir="rtl"
        class="f-narkis w-100 p-2 pl-5 rounded text-right border-0"
        :placeholder="hebrew ? 'הזינו טקסט כאן' : 'Enter Hebrew text'" v-model="nikudText"></textarea>
      <div class="position-absolute d-flex justify-content-between w-100 p-3 action-btns">
        <form ref="fileForm" class="d-flex align-items-end">
          <label for="main-file-input" class="bottom-button load-button text-underline mb-0">
            {{ hebrew ? 'העלה קובץ' : 'Upload file' }}
          </label>
          <input type="file" accept=".txt, .docx" name="name" id="main-file-input" class="inputfile inputfile-2 d-none"
            ref="mainInputEl" @change="loadInputtedFile">
        </form>
        <button :disabled="nikudText === ''" class="run-btn btn btn-primary d-block" @click="goToFilter">{{ hebrew ?
          'המשך' :
          'Continue'
        }}
        </button>
      </div>
      <b-modal v-model="showTextExceeded" centered id="text-exceeded" hide-header size="sm" 
        footer-class="border-top p-3"
        body-class="px-2 py-3" >
       
        <div v-if="hebrew">
          אפשר להעלות טקסט של עד 10,000 תווים
        </div>
        <div v-else>
          The tool does not support files with over 10,000 characters
        </div>
        <template slot="modal-footer">
          <button type="button" class="btn btn-primary d-block w-100" @click="showTextExceeded=false">{{ hebrew ? 'בסדר, הבנתי' : 'Okay' }}</button>
      </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import readFile from '@/js/readFile'
import { Actions } from '@/store/stateChanges'
export default {
  name: 'TextEditor',
  props: [],
  components: {},
  data() {
    return {
      nikudText: '',
      showTextExceeded: false
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage, false);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  },
  methods: {
    receiveMessage(event) {
      const message = event.data
      if(message && message.length > 0) {
      this.$store.commit('SET_TEXT_FOR_API_CALL', message)
      this.nikudText = message
      setTimeout(() => {
        this.$store.dispatch(Actions.RUN_UPLOAD)
      }, 40)
     }
    },
    focusedEvent() {
      this.$refs.textAreaWrap.classList.remove('border-dark')
      this.$refs.textAreaWrap.classList.add('border-primary')
    },
    focusedOutEvent() {
      this.$refs.textAreaWrap.classList.remove('border-primary')
      this.$refs.textAreaWrap.classList.add('border-dark')
    },
    clearText() { //value former param
      //this.$store.commit("SET_TEXT_FOR_API_CALL", "");
      this.nikudText = "";
      if (!this.hideTextArea)
        this.$refs.textArea.focus();
    },
    goToFilter() {
      if (this.nikudText.length <= 15000) {
        this.$store.commit('SET_TEXT_FOR_API_CALL', this.nikudText)
        setTimeout(() => {
          this.$store.dispatch(Actions.RUN_UPLOAD)
        }, 150)
      } else {
        this.showTextExceeded = true
      }
    },
    loadInputtedFile() {
      const f = this.$refs.mainInputEl.files[0]
      const ftype = f ? f.type : null
      readFile({
        file: f,
        name: f.name,
        type: ftype
      }).then(results => {
        this.nikudText = results.docText
      })

      this.$refs.fileForm.reset()
    }
  },
  computed: {
    keymap() {
      return {
        'ctrl+enter': () => {
          this.goToFilter()
        }
      }
    },
    hebrew() {
      return this.$settings.hebrew
    }
  }
}
</script>  
<style lang="scss">
.content-error {
  .close-modal {
    top: 0;
    right: 0;
    font-size: 30px;
    line-height: 30px;
    width: 40px;
  }

  .modal-heading {
    font-size: 26px;
  }
}
</style>
<style scoped lang=scss>
.erase {
  left: 5px;
  top: 0;
  font-size: 20px;
  cursor: pointer;

  @media only screen and (max-width: 991px) {
    span {
      font-size: 34px;
    }
  }
}

.text-editor {
  height: calc(100vh - 120px);

  textarea {
    direction: rtl;
    font-size: 25px;
    overflow-y: auto;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    height: calc(100% - 60px);

    /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      /* Chrome/Safari/Webkit */
    }

    resize: none;

    &:focus {
      outline: none;
    }
  }

  .rounded {
    border-radius: 4px !important;
  }

  .action-btns {
    bottom: 0;
    left: 0;

    .run-btn {
      height: 36px;
      font-size: 16px;
      width: 120px;
    }

    form {
      height: 36px;

      label {
        cursor: pointer;
      }
    }
  }
}</style>