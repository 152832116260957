<template>
  <div class="display-settings py-md-2">
    <div
      class="d-none d-lg-inline-block mb-0 arrow-back position-relative form-group ml-4"
    >
      <router-link to="/" class="btn single-btn">
        <div class="w-100 text-center">
          <i class="fas fa-arrow-right text-body"></i>
        </div>
      </router-link>
    </div>
    <b-form-group class="d-md-inline-block mb-0">
      <div class="d-flex flex-row-reverse justify-content-between d-md-block">
        <b-form-radio-group
          id="display-btns"
          v-model="showParallels"
          :options="displayOptions"
          aria-describedby="בחירת תצוגה"
          name="radios-btn-display"
          button-variant="light"
          buttons
        >
        </b-form-radio-group>
        <small class="text-muted text-center mt-1 d-block d-md-none">
          {{ hebrew ? "מקבילות" : "Citations and Parallels" }}
        </small>
      </div>
    </b-form-group>
    <b-form-group class="d-md-inline-block mb-0">
      <div class="d-flex flex-row-reverse justify-content-between d-md-block">
        <b-form-radio-group
          id="nikud-btns"
          v-model="withNikud"
          :options="shasLibrary ? shasNikudOptions : nikudOptions"
          aria-describedby="עם ניקוד"
          name="radios-btn-display"
          class="f-narkis nikud-btns"
          button-variant="light"
          buttons
        ></b-form-radio-group>
        <small class="text-muted d-block d-md-none text-center mt-1">{{
          hebrew ? "ניקוד" : "Nikud"
        }}</small>
      </div>
    </b-form-group>
    <div
      class="form-group d-flex flex-row-reverse justify-content-between d-md-inline-block mb-0"
      v-if="shasLibrary"
    >
      <b-btn
        :disabled="!withNikud"
        class="single-btn flag f-narkis"
        :class="{ active: showEIM }"
        @click="toggleEIM"
      >
        <span>א</span>
        <span class="text-primary">ו</span>
      </b-btn>
      <!-- <b-form-checkbox v-model="showOCRTagging" switch></b-form-checkbox>         -->
      <small class="text-muted d-block d-md-none text-center mt-1">{{
        hebrew ? "אימות קריאה" : "Eimot"
      }}</small>
    </div>
    <b-form-group class="d-none xd-md-inline-block mb-0" v-if="!shasLibrary">
      <div class="d-flex flex-row-reverse justify-content-between d-md-block">
        <b-form-radio-group
          id="punctuation-btns"
          v-model="withPunctuation"
          :options="punctuationOptions"
          aria-describedby="עם פיסוק"
          name="radios-btn-display"
          class="f-narkis nikud-btns"
          button-variant="light"
          buttons
        >
        </b-form-radio-group>
        <!--settingsEvent-->
        <small class="text-muted d-block d-md-none text-center mt-1">{{
          hebrew ? "פיסוק" : "Punctuation"
        }}</small>
      </div>
    </b-form-group>
    <b-form-group
      class="d-md-inline-block text-center mb-0"
      v-if="!shasLibrary"
    >
      <div class="d-flex flex-row-reverse justify-content-between d-md-block">
        <b-form-radio-group
          id="abbr-btns"
          v-model="abbrChecked"
          :options="abbrOptions"
          aria-describedby="עם ראשי תיבות"
          class="f-narkis nikud-btns abbr"
          button-variant="light"
          buttons
          @change="abbrBtnChecked"
        ></b-form-radio-group>
        <!--settingsEvent-->
        <small class="text-muted d-block d-md-none text-center mt-1">{{
          hebrew ? "ראשי תיבות" : "Abbreviations"
        }}</small>
      </div>
    </b-form-group>
    <feedback-dialog
      :modalID="'abbr-notification'"
      :modalTitle="abbreviationsModalTitle"
      :msg="abbreviationsInstructions"
      v-model="showAbbrDialog"
    >
    </feedback-dialog>
    <b-form-group class="d-md-inline-block mb-0">
      <div class="d-flex flex-row-reverse justify-content-between d-md-block">
        <b-form-radio-group
          id="font-btns"
          v-model="dictaFont"
          :options="fontOptions"
          aria-describedby="בחירת גופן"
          name="radios-btn-display"
          class="f-narkis nikud-btns font-btns"
          button-variant="light"
          buttons
        ></b-form-radio-group>
        <small class="text-muted d-block d-md-none text-center mt-1">{{
          hebrew ? "גופן" : "Font"
        }}</small>
      </div>
    </b-form-group>
    <div
      class="form-group d-flex justify-content-between d-md-none mb-0 pr-2"
      v-if="!shasLibrary && notHumanReviewed"
    >
      <small class="text-muted d-block text-center mt-1">{{
        hebrew ? "תיוג" : "Tagging"
      }}</small>
      <b-btn class="single-btn flag" :class="{ active: showOCRFlaggedWords }">
        <i
          style="cursor: pointer"
          class="fas fa-flag d-block"
          @click="toggleFlaggedWords"
        ></i>
      </b-btn>
    </div>
    <b-form-group class="d-md-inline-block mb-0">
      <div class="d-flex flex-row-reverse justify-content-between d-md-block">
        <b-button-group>
          <b-button
            @click="zoomIn"
            class="zoom-in"
            :disabled="fSize == 40"
            variant="light"
          >
            <i class="fas fa-search-plus d-none d-md-block"></i>
            <img
              class="d-inline-block d-md-none"
              src="../assets/img/increase-font.png"
            />
          </b-button>
          <span class="d-inline-block d-md-none mx-2 font-size">{{
            fSize
          }}</span>
          <b-button
            @click="zoomOut"
            class="zoom-out"
            :disabled="fSize == 12"
            variant="light"
          >
            <i class="fas fa-search-minus d-none d-md-block"></i>
            <img
              class="d-block d-md-none"
              src="../assets/img/decrease-font.png"
            />
          </b-button>
        </b-button-group>
        <small class="text-muted d-block d-md-none text-center mt-1">{{
          hebrew ? "גודל" : "Font size"
        }}</small>
      </div>
    </b-form-group>

    <!-- <div class="form-group d-none justify-content-between d-md-inline-block mb-0 pr-2 mr-1">
            <b-btn class="single-btn flag download" @click="showDownloadModal = true">
                <i style="cursor: pointer;" class="fas fa-download d-block"></i>
            </b-btn>
            <small class="text-muted d-block d-md-none text-center mt-1">{{ hebrew ? 'הורדת קובץ' : 'Download' }}</small>
        </div>
        <download-modal v-model="showDownloadModal"></download-modal> -->
    <b-form-group
      class="mb-0 pr-2 mr-md-1 d-md-inline-block"
      v-if="shasLibrary"
    >
      <b-btn
        id="sefaria-btn"
        v-if="$mq === 'smLandscape'"
        class="single-btn w-auto px-2 py-1"
        :href="sefariaURL"
      >
        ספריא
      </b-btn>
      <a
        v-else
        :href="sefariaURL"
        id="sefaria-btn"
        class="text-decoration-none d-flex flex-row-reverse justify-content-between align-items-center m-n3 p-3"
      >
        <b-btn class="border-0 bg-transparent shadow-none text-primary">
          <i class="fas fa-external-link-alt" style="font-size: 20px"></i>
        </b-btn>
        <small>צפייה בעמוד באתר ספריא</small>
      </a>
    </b-form-group>
  </div>
</template>
<script>
//import DownloadModal from "@/components/DownloadModal"
import FeedbackDialog from "@/components/FeedbackDialog"
export default {
  name: "DisplaySettings",
  props: [],
  components: { FeedbackDialog },
  data() {
    return {
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
      displayOptions: [
        { text: "ללא", value: false },
        //{ text: 'אזכורים', value: 'citations' },
        { text: "מקבילות", value: true },
      ],
      nikudOptions: [
        { text: "א", value: false },
        { text: "אָ", value: true },
      ],
      shasNikudOptions: [
        { text: "אָ", value: true },
        { text: "א", value: false },
      ],
      abbrOptions: [
        { text: "ללא", value: false },
        { text: '"', value: true },
      ],
      fontOptions: [
        { text: "א", value: false },
        { text: "א", value: true },
      ],
      punctuationOptions: [
        { text: "א", value: false },
        { text: "א,", value: true },
      ],
      showAbbrDialog: false,
      showDownloadModal: false,
    }
  },
  mounted() {
    //window.addEventListener("beforeunload", this.saveDataToLocalStorage);
  },
  beforeUnmount() {
    //window.removeEventListener("beforeunload", this.saveDataToLocalStorage);
  },
  beforeDestroy() {
    //this.saveDataToLocalStorage;
  },
  methods: {
    abbrBtnChecked() {
      if (this.abbrChecked && !this.hideAbbrDialog) this.showAbbrDialog = true
    },
    saveDataToLocalStorage() {
      localStorage.setItem(
        "dicta-library-settings",
        JSON.stringify({
          hideFeedbackDialog: this.hideFeedbackDialog,
          hideAbbrDialog: this.hideAbbrDialog,
          fontSize: this.fSize,
          showPageSettings: this.showPageSettings,
          showParallels: this.showParallels,
          withNikud: this.withNikud,
          dictaFont: this.dictaFont,
          withPunctuation: this.withPunctuation,
          abbrChecked: this.abbrChecked,
          showManualTagging: this.showOCRTagging,
          showOCRFlaggedWords: this.showOCRFlaggedWords,
          showEIM: this.showEIM,
          hideHeader: this.hideHeader,
        })
      )
    },

    zoomIn() {
      let fontSize = JSON.parse(JSON.stringify(this.fSize))
      this.$store.commit("SET_FONT_SIZE", fontSize + 1)
    },
    zoomOut() {
      let fontSize = JSON.parse(JSON.stringify(this.fSize))
      this.$store.commit("SET_FONT_SIZE", fontSize - 1)
    },
    toggleFlaggedWords() {
      this.showOCRFlaggedWords = !this.showOCRFlaggedWords
    },
    toggleEIM() {
      this.showEIM = !this.showEIM
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    notHumanReviewed() {
      return this.$store.state.selectedBook.notHumanReviewed
    },
    abbreviationsModalTitle() {
      return this.hebrew ? "פיענוח ראשי תיבות" : "Abbreviations Expansion"
    },
    abbreviationsInstructions() {
      if (this.isMobile) {
        return this.hebrew
          ? "לתצוגת ראשי תיבות יש ללחוץ על הטקסט עם ראשי התיבות."
          : "Click on the abbreviation to see the expansion."
      } else {
        return this.hebrew
          ? "לתצוגת ראשי תיבות יש ללחוץ על הטקסט עם ראשי התיבות שמופיעות עם הסמן שאלה."
          : "Click on the abbreviation to see the expansion. Hovered abbreviations will appear with a question mark cursor icon."
      }
    },
    /*   showPageSettings() {
              return this.$store.state.showSettings;  
          }, */
    showParallels: {
      get() {
        return this.$store.state.showParallels
      },
      set(value) {
        this.$store.commit("SET_SELECTED_DISPLAY", value)
        this.saveDataToLocalStorage()
      },
    },
    withNikud: {
      get() {
        return this.$store.state.showNikud
      },
      set(value) {
        //if (!value) {
        this.$store.commit("SET_SHOW_EIM", !value)
        this.showEIM = !value
        //}
        this.$store.commit("SET_SHOW_NIKUD", value)
        this.saveDataToLocalStorage()
      },
    },
    withPunctuation: {
      get() {
        return this.$store.state.showPunctuation
      },
      set(value) {
        this.$store.commit("SET_SHOW_PUNCTUATION", value)
        this.saveDataToLocalStorage()
      },
    },
    abbrChecked: {
      get() {
        return this.$store.state.showAbbr
      },
      set(value) {
        this.$store.commit("SET_SHOW_ABBR", value)
        this.saveDataToLocalStorage()
      },
    },
    fSize: {
      get() {
        return this.$store.state.fontSize
      },
      set(value) {
        this.$store.commit("SET_FONT_SIZE", value)
        this.saveDataToLocalStorage()
      },
    },
    dictaFont: {
      get() {
        return this.$store.state.useDictaFont
      },
      set(value) {
        this.$store.commit("SET_USE_DICTA_FONT", value)
        this.saveDataToLocalStorage()
      },
    },
    showOCRTagging: {
      get() {
        return this.$store.state.showManualTagging
      },
      set(value) {
        this.$store.commit("SET_SHOW_TAGGING", value)
        this.saveDataToLocalStorage()
      },
    },
    showEIM: {
      get() {
        return this.$store.state.showEIM
      },
      set(value) {
        this.$store.commit("SET_SHOW_EIM", value)
        this.saveDataToLocalStorage()
      },
    },
    showOCRFlaggedWords: {
      get() {
        return this.$store.state.showFlaggedWords
      },
      set(value) {
        this.$store.commit("SET_SHOW_FLAGGED", value)
        this.saveDataToLocalStorage()
      },
    },
    hideHeader() {
      return this.$store.state.hideHeader
    },
    hideFeedbackDialog() {
      return this.$store.state.hideFeedbackModal
    },
    hideAbbrDialog() {
      return this.$store.state.hideAbbrDialog
    },
    bookFiles() {
      return this.$store.state.bookFiles
    },
    sefariaURL() {
      if (this.bookFiles.length > 0) {
        var index = this.bookFiles
          .map(function (e) {
            return e.fileName
          })
          .indexOf(this.$store.state.selectedFile)
        if (index >= 0) return this.bookFiles[index].sefariaURL
        else return ""
      } else return ""
    },
  },
}
</script>
<style lang="scss">
.display-settings {
  @media screen and (max-width: 767px) {
    padding-top: 10px;
  }

  .form-group {
    .single-btn {
      border-radius: 6px;

      &:not(.flag)::after {
        content: "";
        height: 27px;
        width: 1px;
        background: #e3e3e3;
        top: 0;
        left: -13px;
        position: absolute;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 12px 14px !important;
      box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 30%);
      border: 1px solid #c8c8c8;
      border-radius: 2px;
      height: 62px;
      font-size: 18px;
      margin-bottom: 15px !important;

      small {
        font-size: 18px !important;
        color: #000 !important;
      }

      span {
        line-height: 28px;

        &.font-size {
          line-height: 40px;
        }
      }

      .zoom-in,
      .zoom-out {
        border: none !important;
        background: transparent !important;
        width: 35px !important;
        height: 35px !important;
        text-align: center;
      }

      .btn:last-child {
        margin-left: 0 !important;

        &:last-child:after {
          width: 0;
        }
      }
    }

    position: relative;

    #display-btns {
      .btn {
        width: 80px;

        font-size: 16px !important;
        &:first-child {
          margin-right: 0;
        }
      }
    }

    .btn {
      height: 28px;
      width: 28px;
      font-size: 13px;

      @media screen and (max-width: 767px) {
        font-size: 16px;
        height: 36px;
        width: 36px;
        line-height: 28px;
      }

      padding-right: 0;
      padding-left: 0;

      box-shadow: none !important;
      border: solid 1px #d8d8d8;
      &:hover {
        border: solid 1px #bfc6cb;
      }
      background: white;
      cursor: pointer;

      &.disabled {
        cursor: default;
        opacity: 0.5;
      }

      &:first-child:not(.single-btn) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-right: 13px;
        border-left-width: 0;
      }

      &:last-child:not(.single-btn) {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-left: 13px;
        position: relative;
        @media screen and (min-width: 768px) {
          &:after {
            content: "";
            height: 27px;
            width: 1px;
            background: #e3e3e3;
            top: 0;
            left: -15px;
            position: absolute;
          }
        }
      }

      &:not([xdisabled]) {
        &.active {
          background-color: #bfe1fe !important;
        }

        &:not(.active):hover {
          background-color: #fbfbfb !important;
        }
      }
    }

    .f-narkis {
      &.btn,
      .btn {
        font-size: 18px;
        line-height: 20px !important;

        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 1.5;
        }

        // line-height: 24px;
      }

      &.font-btns {
        .btn {
          &:last-child {
            span {
              font-family: dicta !important;
              font-size: 22px;
              line-height: 16px !important;

              @media screen and (max-width: 767px) {
                font-size: 30px;
                line-height: 22px !important;
              }
            }
          }
        }
      }
    }

    .abbr {
      label:first-child {
        display: none;

        @media screen and (max-width: 767px) {
          line-height: 28px;
          font-size: 16px;
          display: inline;
          font-family: "arimo";
        }
      }

      label:last-child {
        @media screen and (min-width: 768px) {
          font-size: 35px;
          line-height: 41px !important;
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
          margin-right: 13px;
        }

        @media screen and (max-width: 767px) {
          span {
            font-size: 40px;
            line-height: 51px;
          }
        }

        font-family: mft_narkisclassic, sans-serif;
      }
    }

    &:last-child {
      .btn {
        &:last-child {
          &:after {
            background: transparent;
          }
        }
      }
    }
  }
}
</style>
