let bookDisplayNameMap = {}

export function initBookDisplayNameMap(bookList) {
  for (const book of bookList) {
    const english_book_title = book['displayNameEnglish'].replace(/ /g, "_").replace(/['’.]/g, "ʼ")
                                                         .replace(/\(/g, "-").replace(/\)/g, "-")
    bookDisplayNameMap[english_book_title] = book["fileName"]
  }
  // console.log('bookDisplayNameMap:')
  // console.log(bookDisplayNameMap)
}

export function bookAndPageFromXmlId(xmlId, bookList) {
  // console.log('xmlId:')
  // console.log(xmlId)
  // console.log('bookList:')
  // console.log(bookList)
  const xmlIdParts = xmlId.split('.')
  let bookNameIndex = xmlIdParts.length - 1
  while (xmlIdParts[--bookNameIndex].replace(/(Page|Siman)_/g, '').replace(/\d/g, '') === '');
  const bookName = bookDisplayNameMap[xmlIdParts[bookNameIndex]]
  const book = bookList.filter(b => b.fileName === bookName)[0]
  const rawPageNum = xmlIdParts[bookNameIndex + 1].replace(/(Page|Siman)_/g, '')
  // console.log('bookName:')
  // console.log(bookName)
  // console.log('rawPageNum:')
  // console.log(rawPageNum)
  return {
    bookName: bookName,
    pageNum: bookName + (!(book.source) || book.source.endsWith('דיקטה') ? ('-' + rawPageNum.padStart(3, '0')) : ('.' + rawPageNum))
  }
}
