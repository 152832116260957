import { BlankState, StateChanges } from "./stateChanges"
import { RunStates } from "./runStates"
import router from "../router"

function gtag() {
  window.dataLayer.push(arguments)
}

function settingsEvent(evtName) {
  gtag("event", evtName)
}

const basicMutations = {
  [StateChanges.SET_BOOKS]: function (state, data) {
    if (process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle")) {
      let singleBook = process.env.VUE_APP_FILE_TYPE.split("-")[1]
      state.bookList = data.filter((obj) => obj.fileName === singleBook)
      state.selectedBook = state.bookList[0]
    } else {
      state.bookList = data
    }
  },
  [StateChanges.SET_SELECTED_BOOK]: function (state, selectedBook) {
    state.selectedBook = selectedBook
  },
  [StateChanges.SET_BOOK_FILES]: function (state, bookFiles) {
    state.bookFiles = bookFiles
  },
  [StateChanges.UPDATE_ROUTE]: function (state) {
    if (state.txtForApi) {
      setTimeout(() => {
        if (router.currentRoute.name !== "File") router.push({ name: "File" })
      }, 20)
    } else {
      if (router.currentRoute.name !== "File") {
        router
          .push({
            name: "File",
            params: {
              bookName: state.selectedBook.fileName,
              pageNum: state.selectedFile.replace(".zip", ""),
            },
          })
          .catch((err) => err)
      } else {
        if (
          router.currentRoute.params.bookName !== state.selectedBook.fileName ||
          router.currentRoute.params.pageNum !==
            state.selectedFile.replace(".zip", "")
        ) {
          router.push({
            ...router.currentRoute,
            params: {
              ...router.currentRoute.params,
              bookName: state.selectedBook.fileName,
              pageNum: state.selectedFile.replace(".zip", ""),
            },
          })
        }
      }
    }
  },
  [StateChanges.SET_SELECTED_FILE]: function (state, fileName) {
    state.selectedFile = fileName
  },
  [StateChanges.SET_FILE_DATA]: function (state, fileData) {
    state.fileData = fileData
  },
  [StateChanges.SET_TEXT_FOR_REMOVE_NIKUD_API_CALL]: function (state, newText) {
    state.textForRemoveNikudApiCall = newText
  },
  [StateChanges.SET_TEXT_FOR_SYNOPSIS]: function (state, data) {
    state.dataForSynopsis = data
  },
  [StateChanges.SET_SYNOPSIS_FILE_RESULTS]: function (state, data) {
    state.synopsisFiles = data
  },
  [StateChanges.SET_REMOVE_NIKUD_RESULTS]: function (state, data) {
    state.removeNikudApiResponse = data
  },
  [StateChanges.SET_SESSION_ID]: function (state, id) {
    state.sessionID = id
  },
  [StateChanges.SET_VERSE_FOR_CHAPTER]: function (state, newData) {
    state.verseForChapter = newData
  },
  [StateChanges.SET_CHAPTER_RESULTS]: function (state, newData) {
    state.chapterResults = newData
  },
  [StateChanges.LIBRARY_NOT_RUN]: function (state) {
    state.serverState = RunStates.NOT_RUN
  },
  [StateChanges.LIBRARY_COMPLETE]: function (state) {
    state.serverState = RunStates.COMPLETE
  },
  [StateChanges.LIBRARY_RUNNING]: function (state) {
    state.serverState = RunStates.RUNNING
  },
  [StateChanges.LIBRARY_FAILED]: function (state) {
    state.serverState = RunStates.FAILED
  },
  [StateChanges.DOWNLOAD_NOT_RUN]: function (state) {
    state.downloadState = RunStates.NOT_RUN
  },
  [StateChanges.DOWNLOAD_COMPLETE]: function (state) {
    state.downloadState = RunStates.COMPLETE
  },
  [StateChanges.DOWNLOAD_RUNNING]: function (state) {
    state.downloadState = RunStates.RUNNING
  },
  [StateChanges.DOWNLOAD_FAILED]: function (state) {
    state.downloadState = RunStates.FAILED
  },
  [StateChanges.SERVER_FAILED]: function (state) {
    state.serverState = RunStates.FAILED
  },
  [StateChanges.SERVER_TIMED_OUT]: function (state) {
    state.serverState = RunStates.TIMED_OUT
  },
  [StateChanges.SYNOPSIS_NOT_RUN]: function (state) {
    state.synopsisServerState = RunStates.NOT_RUN
  },
  [StateChanges.SYNOPSIS_COMPLETE]: function (state) {
    state.synopsisServerState = RunStates.COMPLETE
  },
  [StateChanges.SYNOPSIS_RUNNING]: function (state) {
    state.synopsisServerState = RunStates.RUNNING
  },
  [StateChanges.SYNOPSIS_FAILED]: function (state) {
    state.synopsisServerState = RunStates.FAILED
  },
  [StateChanges.SYNOPSIS_TIMED_OUT]: function (state) {
    state.synopsisServerState = RunStates.TIMED_OUT
  },
  [StateChanges.INCORRECT_PAGE]: function (state, val) {
    state.incorrectPage = val
  },
  [StateChanges.INCORRECT_BOOK]: function (state, val) {
    state.incorrectBook = val
  },
  [StateChanges.SET_FONT_SIZE]: function (state, val) {
    state.fontSize = val
  },
  [StateChanges.SET_SELECTED_DISPLAY]: function (state, val) {
    state.showParallels = val
    settingsEvent(val ? "Show Parallels" : "Hide Parallels")
  },
  [StateChanges.SET_SHOW_NIKUD]: function (state, val) {
    state.showNikud = val
    settingsEvent(val ? "Show Nikud" : "Hide Nikud")
  },
  [StateChanges.SET_USE_DICTA_FONT]: function (state, val) {
    state.useDictaFont = val
    settingsEvent(val ? "Use Dicta font" : "Use regular font")
  },
  [StateChanges.SET_SHOW_PUNCTUATION]: function (state, val) {
    state.showPunctuation = val
    settingsEvent(val ? "Show Punctuation" : "Hide Punctuation")
  },
  [StateChanges.SET_SHOW_ABBR]: function (state, val) {
    state.showAbbr = val
    settingsEvent(val ? "Show Abbreviations" : "Hide Abbreviations")
  },
  [StateChanges.SET_SHOW_TAGGING]: function (state, val) {
    state.showManualTagging = val
    settingsEvent(val ? "Show Tagging" : "Hide Tagging")
  },
  [StateChanges.SET_SHOW_FLAGGED]: function (state, val) {
    state.showFlaggedWords = val
    settingsEvent(val ? "Show Flagged words" : "Hide Flagged words")
  },
  [StateChanges.SET_SHOW_EIM]: function (state, val) {
    state.showEIM = val
    settingsEvent(val ? "Show Eimot" : "Hide Eimot")
  },
  [StateChanges.SET_HIDE_HEADER]: function (state, val) {
    state.hideHeader = val
  },
  [StateChanges.SET_HIDE_ABBR_DIALOG]: function (state, val) {
    state.hideAbbrDialog = val
  },
  [StateChanges.SET_HIDE_FEEDBACK_DIALOG]: function (state, val) {
    state.hideFeedbackModal = val
  },
  [StateChanges.SET_TEXT_FOR_API_CALL]: function (state, txt) {
    state.txtForApi = txt
  },
  [StateChanges.CLEAR_RESULTS]: function (state) {
    Object.assign(state, JSON.parse(JSON.stringify(BlankState)))
    if (router.currentRoute.name !== "home") router.push({ name: "home" })
    router.replace({ query: {} })
  },
}

export default basicMutations
