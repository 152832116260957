<template>
  <b-modal
    id="chapter-list-modal"
    ref="chapter-modal"
    v-model="show"
    centered
    :size="$mq === 'sm' ? 'sm' : 'lg'"
    :title="displayName"
    footer-class="border-top p-2"
    hide-footer
    :header-class="$mq === 'sm' ? 'bg-primary text-white' : ''"
    title-tag="span"
    @show="scrollIntoView"
  >
    <div
      v-if="!shasLibrary"
      @click="showFullSource = !showFullSource"
      class="source-info position-relative"
      ref="sourceBox"
      :class="{ 'full-height': showFullSource }"
    >
      <div class="position-absolute toggle-source">
        <i class="fas fa-angle-down" v-if="showFullSource"></i>
        <i class="fas fa-angle-up" v-else></i>
      </div>
      <div id="category" v-if="selectedBook.category">
        {{ hebrew ? "קטגוריה" : "Category" }} - {{ selectedBook.category }}
      </div>
      <div id="year" v-if="selectedBook.printYear">
        {{ hebrew ? "שנת הדפסה" : "Year printed" }} -
        {{ selectedBook.printYear }}
      </div>
      <div id="location" v-if="selectedBook.printLocation">
        {{ hebrew ? "מקום הדפסה" : "Location" }} -
        {{ selectedBook.printLocation }}
      </div>
      <div id="author" v-if="selectedBook.author">
        {{ hebrew ? "מחבר" : "Author" }} - {{ selectedBook.author }}
      </div>
    </div>
    <hr v-if="!shasLibrary" class="mx-n3 mt-2" />
    <div
      class="overflow-auto list-wrap"
      :class="{
        'show-book-details': showFullSource,
        ios: isIOS,
        'shas-library': shasLibrary,
      }"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(item, j) in chapterList"
          :key="j"
          class="text-center"
          :class="{ 'active-item': j === chapterNum }"
          @click="chapterClicked(j)"
        >
          <span
            class="d-flex h-100 w-100 align-items-center justify-content-center"
            v-html="
              shasLibrary
                ? item.replace('דף', '')
                : item.replace(/\s+/g, '<br>')
            "
          ></span>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-modal>
</template>
<script>
//import { Actions } from "@/store/stateChanges"
export default {
  name: "ChapterListDialog",
  props: ["value", "selectedChapter"],
  data() {
    return {
      chapterNum: 0,
      showFullSource: false,
      isIOS: /iPad|iPhone|iPod/.test(navigator.platform),
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
    }
  },
  methods: {
    scrollIntoView() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.chapterNum = this.selectedChapter ? this.selectedChapter : 0
          document
            .getElementsByClassName("active-item")[0]
            .scrollIntoView({ behavior: "auto", block: "start" })
        }, 70)
      })
    },
    chapterClicked(index) {
      if (index !== this.selectedChapter) {
        this.chapterNum = index
        this.updatePage(this.chapterNum)
      }
      this.$refs["chapter-modal"].hide()
    },
    updatePage(pageNum) {
      this.$store.commit("SET_SELECTED_FILE", this.bookFiles[pageNum].fileName)
      //this.$store.dispatch(Actions.GET_FILE_DATA)
      this.$store.commit("UPDATE_ROUTE")
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    displayName() {
      if (this.shasLibrary && this.selectedBook.displayName) {
        let startIndex = this.selectedBook.displayName.indexOf("מסכת")
        return this.selectedBook.displayName.substring(startIndex)
      }
      return this.selectedBook.displayName
    },
    selectedBook() {
      return this.$store.state.selectedBook
    },
    bookFiles() {
      return this.$store.state.bookFiles
    },
    chapterList() {
      return this.bookFiles.map((element) => element.displayName)
    },
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
}
</script>
<style lang="scss">
#chapter-list-modal {
  .modal-sm {
    margin: 0rem;
    align-items: top;
    height: 100%;
    overflow: hidden;
  }

  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 630px;
    }
  }

  .modal-content {
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    .modal-header {
      font-size: 22px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .close {
        opacity: 1;
        text-shadow: none;
        top: 12px;
        color: white;
      }
    }
  }

  .source-info {
    max-height: 20px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;

    &.full-height {
      max-height: 500px;
      transition: max-height 0.5s ease-in;
    }
  }

  .toggle-source {
    left: 0;
    font-size: 20px;
    top: 0;
    line-height: 20px;
  }

  .list-wrap {
    max-height: 250px;
  }

  @media screen and (max-width: 768px) {
    .list-wrap {
      max-height: 79vh;
      height: 79vh;
      &.shas-library {
        max-height: 83vh;
        height: 83vh;
      }

      &.show-book-details {
        height: 75vh;
      }
      &.ios {
        height: 73vh;
        &.shas-library {
          height: 76vh;
        }
        &.show-book-details {
          height: 63vh;
        }
      }
    }
  }

  .list-group {
    display: block;

    .list-group-item {
      display: inline-block !important;
      width: 36px;
      height: 36px;
      margin: 0 0 13px 13px;
      padding: 0;
      border-radius: 6px;
      vertical-align: middle;
      cursor: pointer;
      &:hover {
        border: solid 1px var(--primary);
        background-color: rgba(0, 126, 229, 0.09);
        color: var(--primary);
      }
      @media screen and (max-width: 768px) {
        width: 23%;
        height: 72px;
        padding: 0;
        margin: 7px 1%;
        border-radius: 8px;
        background-color: #f6f6f6;
        &.active-item {
          background: #d6ecff;
          color: #000;
        }
      }
    }
  }
}
</style>
