import backendRedirect from '../../backendRedirect'

const SERVER_ROOT = '/api'
const SERVER_EXCEL = '/ExcelOutput'
const serverUrl = backendRedirect.apiTrafficManager + SERVER_ROOT
const uploadServerUrl = backendRedirect.uploadApiTrafficManager + SERVER_ROOT

//const visualizerUrl = '/visualize'


export const StoreConfig = {
  serverRoot: SERVER_ROOT,
  serverUrl: serverUrl,
  SERVER_EXCEL: SERVER_EXCEL,
  uploadServerUrl: uploadServerUrl
}
