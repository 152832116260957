<template>
  <div class="shas-list h-100">
    <spinner v-if="running"></spinner>
    <b-container class="pt-4 overflow-auto">
      <h1 class="f-narkis">
        <strong> שישה סדרי משנה מנוקדים </strong>
      </h1>
      <b-button-group
        v-for="(list, index) in bookLists"
        :key="index"
        class="d-block pb-4"
      >
        <h3 class="border-bottom mb-3 font-weight-bold f-narkis">
          {{ list.listName }}
        </h3>
        <b-button
          class="ml-3 mb-3 shadow-none"
          v-for="(book, bookIndex) in list.bookArr"
          :key="bookIndex"
          :id="book.toLowerCase()"
          @click="itemClicked(book)"
        >
          {{ getBookName(book) }}
        </b-button>
      </b-button-group>
    </b-container>
    <server-failed-popup v-if="failed" v-model="failed" />
    <chapter-list-dialog v-model="showChapterModal" />
  </div>
</template>
<script>
//import data from "../../sources/index.json"
import { Actions } from "@/store/stateChanges"
import Spinner from "@/components/spinner"
import { RunStates } from "@/store/runStates"
import ServerFailedPopup from "@/components/ServerFailedPopup"
import ChapterListDialog from "@/components/ChapterListDialog"
export default {
  name: "ShasList",
  props: ["value"],
  components: { Spinner, ServerFailedPopup, ChapterListDialog },
  data() {
    return {
      showChapterModal: false,
    }
  },
  mounted() {
    this.$store.dispatch(Actions.GET_BOOKS)
  },
  methods: {
    getBookName(book) {
      let selectedBook = this.bookData.find((item) => item.fileName === book)
      return selectedBook ? selectedBook.displayName.split("מסכת")[1] : ""
    },
    itemClicked(book) {
      this.$store.commit("SET_SELECTED_FILE", "")
      this.$store.commit(
        "SET_SELECTED_BOOK",
        this.bookData.find((item) => item.fileName === book)
      )
      this.$store.dispatch(Actions.GET_FILES)
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    bookData() {
      return this.$store.state.bookList
    },
    bookLists() {
      return [
        {
          listName: "סדר זרעים",
          bookArr: ["Berakhot"],
        },
        {
          listName: "סדר מועד (חגים)",
          bookArr: [
            "Shabbat",
            "Eruvin",
            "Pesachim",
            "Yoma",
            "Sukkah",
            "Beitzah",
            "Rosh_Hashanah",
            "Taanit",
            "Megillah",
            "Moed_Katan",
            "Chagigah",
          ],
        },
        {
          listName: "סדר נשים (דיני משפחה)",
          bookArr: [
            "Yevamot",
            "Ketubot",
            "Nedarim",
            "Nazir",
            "Sotah",
            "Gittin",
            "Kiddushin",
          ],
        },
        {
          listName: "סדר נזיקין",
          bookArr: [
            "Bava_Kamma",
            "Bava_Metzia",
            "Bava_Batra",
            "Sanhedrin",
            "Makkot",
            "Shevuot",
            "Avodah_Zarah",
            "Horayot",
          ],
        },
        {
          listName: "סדר קדשים (קורבנות)",
          bookArr: [
            "Zevachim",
            "Menachot",
            "Chullin",
            "Bekhorot",
            "Arakhin",
            "Temurah",
            "Keritot",
            "Meilah",
            "Tamid",
          ],
        },
        {
          listName: "סדר טהרות",
          bookArr: ["Niddah"],
        },
      ]
    },
    running() {
      return this.$store.state.serverState === RunStates.RUNNING
    },
    failed: {
      get() {
        return (
          this.$store.state.serverState === RunStates.FAILED ||
          this.$store.state.serverState === RunStates.TIMED_OUT
        )
      },
      set() {
        this.$store.commit("LIBRARY_NOT_RUN")
      },
    },
  },
  watch: {
    "$store.state.bookFiles": {
      handler(newValue) {
        //once we have a list of chapters
        if (newValue) this.showChapterModal = true
      },
    },
  },
}
</script>
<style scoped lang="scss">
.border-bottom {
  border-color: #bfbfbf !important;
}
h1 {
  font-size: 28px;
}

button {
  height: 42px;
  padding: 0 21px;
  border-radius: 8px !important;
  border: solid 1px #bfbfbf;
  background-color: #fff;
  font-size: 18px;
  &:hover,
  &:active {
    color: var(--primary) !important;
    border: solid 1px var(--primary) !important;
    background-color: rgba(0, 126, 229, 0.09) !important;
  }
}
</style>
