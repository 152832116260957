import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import dictaVueComponents from 'dicta-vue-components'
import VueCookies from 'vue-cookies'
import VueHotkey from 'v-hotkey'
import VueMq from 'vue-mq'


Vue.config.productionTip = false
Vue.use(dictaVueComponents, { hebrew: true, useBodyClass: true })
Vue.use(router)
Vue.use(VueCookies)
Vue.use(VueHotkey)
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 768,
    //smBeforeTablet: 768,
    smLandscape: 1023,
    md: 1024,
    lg: 1200,
    xl: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
