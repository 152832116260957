<template>
  <div
    class="display-settings page-settings"
    :class="{ 'sticky-top': $mq !== 'sm' }"
    v-hotkey="keymap"
  >
    <div class="page-heading bg-background d-flex align-items-center">
      <b-container class="d-flex justify-content-between">
        <div class="file-name d-flex align-items-center">
          <div
            class="d-none d-lg-inline-block mb-0 arrow-back position-relative form-group ml-2 mr-1"
          >
            <div class="w-100 text-center" v-if="!internalSingle">
              <i
                class="fas fa-arrow-right text-body"
                style="cursor: pointer"
                @click="goBack"
              ></i>
            </div>
          </div>
          <h2 v-if="selectedFileName" class="f-narkis d-inline text-muted m-0">
            {{ selectedBook }}, {{ selectedFileName }}
          </h2>
        </div>
        <div class="settings d-flex align-items-center" v-if="selectedFileName">
          <span v-if="!selectedFile.includes('html')">
            <div
              class="form-group form-group d-none d-md-inline-block mb-0 pr-2 mr-1"
            >
              <b-btn
                id="btn-nikud"
                class="single-btn f-narkis"
                :class="{ active: withNikud }"
                v-b-tooltip.hover.bottom
                title="טקסט מנוקד"
                @click="toggleNikud"
              >
                אָ
              </b-btn>
            </div>
            <div
              v-if="!shasLibrary"
              class="form-group d-flex justify-content-between d-md-inline-block mb-0 pr-2 mr-1 ml-2"
            >
              <b-btn
                id="btn-abbr"
                class="single-btn eim f-narkis"
                :class="{ active: showAbbr }"
                v-b-tooltip.hover.bottom
                title="ראשי תיבות"
                @click="toggleAbbr"
              >
                <span style="font-size: 30px; line-height: 35px">"</span>
              </b-btn>
            </div>
            <div
              v-if="shasLibrary"
              class="form-group d-flex justify-content-between d-md-inline-block mb-0 pr-2 mr-1 ml-2"
            >
              <b-btn
                :disabled="!withNikud"
                class="single-btn eim f-narkis"
                :class="{ active: showEIM }"
                v-b-tooltip.hover.bottom
                title="אימות קריאה"
                @click="toggleEIM"
              >
                <span>א</span>
                <span class="text-primary">ו</span>
              </b-btn>
            </div>
            <div
              class="form-group d-flex justify-content-between d-md-inline-block mb-0"
            >
              <b-btn
                class="single-btn dicta-font overflow-hidden"
                :class="{ active: dictaFont }"
                @click="toggleDictaFont"
                v-b-tooltip.hover.bottom
                title="פונט דיקטה"
              >
                <span class="position-relative" style="top: -9px">א</span>
              </b-btn>
            </div>
            <div
              class="form-group form-group d-none d-md-inline-block mb-0 pr-2 mr-1"
            >
              <b-btn
                id="btn-parallels"
                class="single-btn w-auto px-2"
                :class="{ active: showParallels }"
                @click="toggleParallels"
                v-b-tooltip.hover.bottom
                title="הצגת מקבילות"
              >
                מקבילות
              </b-btn>
            </div>
            <b-form-group class="d-md-inline-block mb-0">
              <div
                class="d-flex flex-row-reverse justify-content-between d-md-block"
              >
                <b-button-group>
                  <b-button
                    @click="zoomIn"
                    class="zoom-in"
                    :disabled="fSize == 40"
                    variant="light"
                    v-b-tooltip.hover.bottom
                    title="הגדלת טקסט"
                  >
                    <i class="fas fa-search-plus d-none d-md-block"></i>
                    <img
                      class="d-inline-block d-md-none"
                      src="../assets/img/increase-font.png"
                    />
                  </b-button>
                  <span class="d-inline-block d-md-none mx-2 font-size">{{
                    fSize
                  }}</span>
                  <b-button
                    @click="zoomOut"
                    class="zoom-out ml-0"
                    :disabled="fSize == 12"
                    variant="light"
                    v-b-tooltip.hover.bottom
                    title="הקטנת טקסט"
                  >
                    <i class="fas fa-search-minus d-none d-md-block"></i>
                    <img
                      class="d-block d-md-none"
                      src="../assets/img/decrease-font.png"
                    />
                  </b-button>
                </b-button-group>
                <small class="text-muted d-block d-md-none text-center mt-1">{{
                  hebrew ? "גודל" : "Font size"
                }}</small>
              </div>
            </b-form-group>
          </span>
          <div
            class="form-group d-flex justify-content-between d-md-inline-block mb-0 pr-2 mr-1"
          >
            <b-btn
              class="single-btn flag download"
              @click="showDownloadModal = true"
              v-b-tooltip.hover.bottom
              title="הורדה"
            >
              <i style="cursor: pointer" class="fas fa-download d-block"></i>
            </b-btn>
            <small class="text-muted d-block d-md-none text-center mt-1">{{
              hebrew ? "הורדת קובץ" : "Download"
            }}</small>
          </div>
          <download-modal v-model="showDownloadModal"></download-modal>
          <div
            class="form-group form-group d-inline-block mb-0 pr-2 mr-1"
            v-if="shasLibrary"
          >
            <b-btn
              id="sefaria-btn"
              class="single-btn w-auto px-2"
              :href="sefariaURL"
            >
              ספריא
            </b-btn>
          </div>
          <div
            class="form-group d-inline-block mb-0 pr-2 mr-1"
            v-if="!shasLibrary && notHumanReviewed"
          >
            <b-btn
              class="single-btn flag"
              :class="{ active: showOCRFlaggedWords }"
              v-b-tooltip.hover.bottom
              title="מילים מסופקות"
            >
              <i
                style="cursor: pointer"
                class="fas fa-flag d-block"
                @click="toggleFlaggedWords"
              ></i>
            </b-btn>
          </div>

          <div class="page-dd d-inline-block pr-sm-3">
            <b-btn
              v-b-tooltip.hover
              :title="pageNumber !== 0 ? 'עמוד קודם (N)' : ''"
              :disabled="pageNumber === 0"
              @click="updatePage(pageNumber - 1)"
              variant="link"
              class="p-0 bg-transparent shadow-none prev-btn d-none d-sm-inline-block"
            >
              <i class="fas fa-caret-right"></i>
            </b-btn>
            <b-dropdown
              id="page-list"
              @show="onDropdownShow('page-list')"
              variant="light"
              :text="pageList[pageNumber]"
            >
              <b-dropdown-item
                @click="updatePage(j)"
                :class="{ 'bg-secondary': pageNumber === j }"
                v-for="(item, j) in pageList"
                :key="j"
                >{{ item }}</b-dropdown-item
              >
            </b-dropdown>
            <b-btn
              v-b-tooltip.hover
              :title="pageNumber !== pageList.length - 1 ? 'עמוד הבא (B)' : ''"
              :disabled="pageNumber === pageList.length - 1"
              @click="updatePage(pageNumber + 1)"
              variant="link"
              class="p-0 bg-transparent shadow-none"
            >
              <i class="fas fa-caret-left"></i>
            </b-btn>
          </div>
        </div>
      </b-container>
      <feedback-dialog
        :modalID="'abbr-notification'"
        :modalTitle="abbreviationsModalTitle"
        :msg="abbreviationsInstructions"
        v-model="showAbbrDialog"
      >
      </feedback-dialog>
    </div>
  </div>
</template>

<script>
import DownloadModal from "@/components/DownloadModal"
import FeedbackDialog from "@/components/FeedbackDialog"
//import { Actions } from "@/store/stateChanges";

export default {
  name: "DesktopControls",
  props: [],
  components: { DownloadModal, FeedbackDialog },
  data() {
    return {
      showAbbrDialog: false,
      showDownloadModal: false,
      pageNumber: 0,
      pageList: [],
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
      internalSingle:
        process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle"),
      internal: process.env.VUE_APP_FILE_TYPE === "internal",
    }
  },
  mounted() {
    this.setDropDownItems()
  },
  methods: {
    saveDataToLocalStorage() {
      localStorage.setItem(
        "dicta-library-settings",
        JSON.stringify({
          hideFeedbackDialog: this.hideFeedbackDialog,
          hideAbbrDialog: this.hideAbbrDialog,
          fontSize: this.fSize,
          showPageSettings: this.showPageSettings,
          showParallels: this.showParallels,
          withNikud: this.withNikud,
          dictaFont: this.dictaFont,
          withPunctuation: this.withPunctuation,
          abbrChecked: this.showAbbr,
          showManualTagging: this.showOCRTagging,
          showOCRFlaggedWords: this.showOCRFlaggedWords,
          showEIM: this.showEIM,
          hideHeader: this.hideHeader,
        })
      )
    },
    goBack() {
      this.$router.back()
    },
    toggleNikud() {
      this.withNikud = !this.withNikud
      this.showEIM = !this.withNikud
    },
    toggleEIM() {
      this.showEIM = !this.showEIM
    },
    toggleAbbr() {
      this.showAbbr = !this.showAbbr
      if (this.showAbbr && !this.hideAbbrDialog) this.showAbbrDialog = true
    },
    toggleDictaFont() {
      this.dictaFont = !this.dictaFont
    },
    toggleParallels() {
      this.showParallels = !this.showParallels
    },
    toggleFlaggedWords() {
      this.showOCRFlaggedWords = !this.showOCRFlaggedWords
    },
    zoomIn() {
      let fontSize = JSON.parse(JSON.stringify(this.fSize))
      this.$store.commit("SET_FONT_SIZE", fontSize + 1)
    },
    zoomOut() {
      let fontSize = JSON.parse(JSON.stringify(this.fSize))
      this.$store.commit("SET_FONT_SIZE", fontSize - 1)
    },
    onDropdownShow(openDropdownId) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(openDropdownId)
        const selectedItem = dropdown.querySelector(".bg-secondary")
        if (selectedItem) {
          selectedItem.parentNode.scrollTop = selectedItem.offsetTop
        }
      })
    },
    setDropDownItems() {
      var index = this.bookFiles
        .map(function (e) {
          return e.fileName
        })
        .indexOf(this.selectedFile)
      if (index < 0) index = 0 //if user reloads page  incorrect or missing page name
      //this.title = this.selectedBook + ' ' + this.bookFiles[index].displayName
      this.pageNumber = index
      if (this.pageList.length == 0) {
        this.bookFiles.map((element) => {
          this.pageList.push(element.displayName)
        })
      }
    },
    updatePage(pageNum) {
      this.pageNumber = pageNum
      this.$store.commit(
        "SET_SELECTED_FILE",
        this.bookFiles[this.pageNumber].fileName
      )
      this.$store.commit("UPDATE_ROUTE")
      //this.$store.dispatch(Actions.GET_FILE_DATA);
    },
  },
  computed: {
    keymap() {
      return {
        n: () => {
          if (this.pageNumber > 0) this.updatePage(this.pageNumber - 1)
        },
        b: () => {
          if (this.pageNumber !== this.pageList.length - 1)
            this.updatePage(this.pageNumber + 1)
        },
      }
    },
    hebrew() {
      return this.$settings.hebrew
    },
    abbreviationsModalTitle() {
      return this.hebrew ? "פיענוח ראשי תיבות" : "Abbreviations Expansion"
    },
    abbreviationsInstructions() {
      if (this.isMobile) {
        return this.hebrew
          ? "לתצוגת ראשי תיבות יש ללחוץ על הטקסט עם ראשי התיבות."
          : "Click on the abbreviation to see the expansion."
      } else {
        return this.hebrew
          ? "לתצוגת ראשי תיבות יש ללחוץ על הטקסט עם ראשי התיבות שמופיעות עם הסמן שאלה."
          : "Click on the abbreviation to see the expansion. Hovered abbreviations will appear with a question mark cursor icon."
      }
    },
    notHumanReviewed() {
      return this.$store.state.selectedBook.notHumanReviewed
    },
    bookFiles() {
      return this.$store.state.bookFiles
    },
    selectedBook() {
      return this.$store.state.selectedBook.displayName
    },
    sefariaURL() {
      if (this.bookFiles.length > 0) {
        var index = this.bookFiles
          .map(function (e) {
            return e.fileName
          })
          .indexOf(this.selectedFile)
        if (index >= 0) return this.bookFiles[index].sefariaURL
        else return ""
      } else return ""
    },
    selectedFile() {
      return this.$store.state.selectedFile
    },
    selectedFileName() {
      if (this.bookFiles.length > 0) {
        var index = this.$store.state.bookFiles
          .map(function (e) {
            return e.fileName
          })
          .indexOf(this.$store.state.selectedFile)
        if (index >= 0) return this.$store.state.bookFiles[index].displayName
        else return ""
      } else return ""
    },
    hideAbbrDialog() {
      return this.$store.state.hideAbbrDialog
    },
    withNikud: {
      get() {
        return this.$store.state.showNikud
      },
      set(value) {
        this.$store.commit("SET_SHOW_NIKUD", value)
        this.saveDataToLocalStorage()
      },
    },
    showAbbr: {
      get() {
        return this.$store.state.showAbbr
      },
      set(value) {
        this.$store.commit("SET_SHOW_ABBR", value)
        this.saveDataToLocalStorage()
      },
    },
    showEIM: {
      get() {
        return this.$store.state.showEIM
      },
      set(value) {
        this.$store.commit("SET_SHOW_EIM", value)
        this.saveDataToLocalStorage()
      },
    },
    showOCRFlaggedWords: {
      get() {
        return this.$store.state.showFlaggedWords
      },
      set(value) {
        this.$store.commit("SET_SHOW_FLAGGED", value)
        this.saveDataToLocalStorage()
      },
    },
    dictaFont: {
      get() {
        return this.$store.state.useDictaFont
      },
      set(value) {
        this.$store.commit("SET_USE_DICTA_FONT", value)
        this.saveDataToLocalStorage()
      },
    },
    showParallels: {
      get() {
        return this.$store.state.showParallels
      },
      set(value) {
        this.$store.commit("SET_SELECTED_DISPLAY", value)
        this.saveDataToLocalStorage()
      },
    },
    fSize: {
      get() {
        return this.$store.state.fontSize
      },
      set(value) {
        this.$store.commit("SET_FONT_SIZE", value)
        this.saveDataToLocalStorage()
      },
    },
  },
  watch: {
    selectedFileName() {
      this.setDropDownItems()
    },
  },
}
</script>
<style lang="scss">
.display-settings {
  .sticky-top {
    height: 52px;
  }

  .single-btn {
    border-radius: 6px;

    &:not(.flag)::after {
      content: "";
      height: 27px;
      width: 1px;
      background: #e3e3e3;
      top: 0;
      left: -13px;
      position: absolute;
    }
  }

  .page-heading {
    height: 52px;
  }

  .form-group .btn {
    font-size: 13px;

    &::after {
      display: none;
    }

    &.f-narkis {
      font-size: 18px !important;
    }

    &.dicta-font {
      font-family: dicta !important;
      font-size: 23px !important;
      line-height: 33px;
    }
  }
  .page-dd {
    .btn-link {
      height: 24px;
    }

    .b-dropdown {
      margin: 0 4px;
      line-height: 20px;
      min-width: 120px !important;

      .col-auto {
        padding-right: 5px;
        padding-left: 5px;
      }

      .dropdown-toggle {
        background: white;

        &:hover {
          background: #f6f6f6;
        }

        border-radius: 6px !important;
        border: solid 1px #d4d4d4 !important;
        height: 28px;
        padding: 0 15px;
      }

      .dropdown-menu {
        padding: 0;
        max-height: 400px;
        overflow-y: auto;
        min-width: 175px;
      }
    }
  }
}
</style>
