<template>
  <b-modal v-model="show" id="about-dialog" ok-only centered size="lg" 
    footer-class="border-top d-none d-sm-block"
    header-class="px-3 w-100"
    :title="hebrew ? 'איך זה עובד' : 'How it works'" :ok-title="hebrew ? 'סגור' : 'Close'" ok-variant="secondary">
    <div v-if="shasLibrary" class="how-to-text overflow-auto">
      <h3 class="text-underline">תלמוד בבלי המנוקד</h3>
      <p>זה שלוש שנים אנחנו, צוות דיקטה, עמלים על ניקוד הש"ס, ולשמחתנו השלמנו אותו לא מכבר, וכעת אפשר ליהנות ממסכתות מנוקדות. </p>
      <p>ניקוד הש״ס התאפשר הודות לנקדן הרבני מבית דיקטה. הנקדן הרבני הוא נקדן אוטומטי שמתחשב בכללי הניקוד הייחודיים לטקסטים רבניים מוקדמים ומאוחרים.</p>
      <p>בשלב הראשון נוקדו המסכתות ניקוד אוטומטי בנקדן הרבני, ובשלב השני ערך את הניקוד הרב יעקב לויפר בעזרת הנקדן. המסכתות המנוקדות עומדות לרשות הציבור ללא כל מטרת רווח.</p>
      <p>הקישור לטקסט המלא בֿממשק Github:
        <br>
        <a href="https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud">https://github.com/Dicta-Israel-Center-for-Text-Analysis/Talmud-Bavli-with-Nikud</a>
      </p>
      <p>אפשר למצוא את הטקסט המנוקד גם במקומות האלה:
        <ul>
          <li>
            <span>ספריא: </span>
            <a href="https://www.sefaria.org.il/">https://www.sefaria.org.il</a>
          </li>
          <li>
            <span>על התורה: </span>
            <a href="https://alhatorah.org/">https://alhatorah.org</a>
          </li>
          <li>
            <span>פורטל הדף היומי: </span>
            <a href="https://www.daf-yomi.com/dafYomi.aspx/">https://www.daf-yomi.com</a>
          </li>                  
        </ul>
      </p>      
      <h3 class="text-underline">כתיב מלא / כתיב חסר</h3>      
      <p>
הש"ס, כדרכו של ספר ללא ניקוד, כתוב בחלקו בכתיב מלא. הינה דוגמה: כשהמילה סִפּוּר נכתבת ללא ניקוד, בדרך כלל מוסיפים בה יו"ד (סיפור), אך כשהיא מנוקדת, היא נכתבת בכתיב חסר ללא יו"ד (סִפּוּר). כך גם 'אוזנו' תחת אָזְנוֹ, 'כולם' תחת כֻּלָּם וכיוצא בזה. כשמנקדים טקסט כזה, אפשר אומנם 'להלביש' את הניקוד על גבי הכתיב המלא מתוך התעלמות מסוימת מכללי הדקדוק (סִיפּוּר; כּוּלָּם), אך דרך המלך בטקסטים מנוקדים היא להשמיט את אימות הקריאה שכן בטקסט מנוקד הן מתייתרות. זאת ועוד - כשהווי"ו באה לציין קמץ קטן או חטף קמץ (אָזְנוֹ, חֳדָשִׁים), היא תצרום לקוראים, המורגלים לווי"ו שמסמנת חולם או שורוק. אולם מכיון שיש קוראים המעדיפים מסיבות שונות את הכתיב בדיוק כפי שנדפס בדפוס וילנא, החלטנו לתת למשתמשים את האפשרות לבחור. ניקדנו אפוא את הש"ס לפי שתי השיטות, והמשתמשים יבחרו אם להוריד את הש"ס המנוקד בכתיב חסר או בכתיב מלא.
      </p>
      <p>
        אתם מוזמנים לבקר באתר הבית של דיקטה כדי ליהנות מכלים נוספים שישפרו את חוויית הלמידה שלכם. כתובת האתר: 
        <a href="https://dicta.org.il/">https://dicta.org.il</a>.
      </p>
      <p>
        קישור ישיר לנקדן הרבני של דיקטה:
        <a href="https://nakdanpro.dicta.org.il/">https://nakdanpro.dicta.org.il</a>.
      </p>
      <hr>
      <p>
        הטקסט המנוקד של התלמוד הבבלי משוחרר ברישיון CC BY-SA.
        <br>
        כשתשתמשו בטקסט המנוקד אנא ציינו מזכה (קרדיט) לדיקטה בנוסח הזה: טקסט זה של התלמוד הבבלי נוקד בדיקטה – המרכז הישראלי לניתוח טקסטים
        (<a href="https://dicta.org.il/">https://dicta.org.il</a>)
      </p>
      <p dir="ltr" class="text-left">
        This vocalized text of the Babylonian Talmud is released under a CC BY-SA license. If you make use of the text, please attribute as follows: This text of the Babylonian Talmud was vocalized by Dicta: The Israel Center for Text Analysis (<a href="https://dicta.org.il/">https://dicta.org.il</a>)
      </p>
    </div>
    <div v-else class="how-to-text overflow-auto">
      <p>
        הספרייה של דיקטה כוללת טקסטים של ספרים שעברו תהליך של סריקה אוטומטית. 
      </p>
      <p>
        בלחיצה על שם הספר תקבלו את הטקסט המלא, ותוכלו ליישם עליו מספר אפשרויות:
      <ul class="px-4 py-2">
        <li class="mb-1">מקבילות: מקורות רבניים שמאוזכרים באופן כלשהו בטקסט הנבחר, יוצגו בלחיצה על המספר שמוצג בתוך הטקסט.
        </li>
        <li class="mb-1">ניקוד: הטקסט יוצג בתוספת ניקוד אוטומטי.
        </li>
        <li class="mb-1">טקסט מפוסק: הטקסט יוצג בתוספת פיסוק אוטומטי.
        </li>
        <li>פענוח ראשי תיבות: יש ללחוץ על הכפתור ״ראשי תיבות״. לאחר מכן, לחיצה על מילה מקוצרת או ראשי תיבות תיתן פענוח אוטומטי.
        </li>
      </ul>
      </p>
      <p>
        כיוון שמדובר בתהליכים אוטומטיים ייתכנו שיבושים בטקסט, בניקוד ובפיסוק. אם נתקלתם בטעויות כאלה - אנא ידעו אותנו.
        <br />
        לשליחת משוב לחצו על הכפתור ״שליחת משוב״ המופיע בצד שמאל למעלה. לאחר מכן לחצו על המילה / הפיסוק / המקבילית שלגביהם אתם רוצים לשלוח משוב. מייד בלחיצה תיפתח תיבה שבה תוכלו להזין משוב. ליציאה ממצב ״שליחת משוב״, יש ללחוץ שוב על הכפתור של ״שליחת משוב״ כדי שיהפוך לאפור.
      </p>
      <p>
        הטקסט המלא של הספרים המוצגים באתר מוצע לתועלת הציבור וללא כל עלות. כדי לגשת לטקסטים המלאים, לחצו 
        <a href="https://github.com/Dicta-Israel-Center-for-Text-Analysis/Dicta-Library-Download" target="_blank">כאן</a>.
      </p>
      <p>
        <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank">https://creativecommons.org/licenses/by-sa/4.0/</a>.
      </p>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "about-dialog",
  props: ["value"],
  data() {
    return {
      shasLibrary: process.env.VUE_APP_FILE_TYPE === 'shas' 
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
<style lang="scss">

@media screen and (max-width: 767px) {
  #about-dialog {
    -webkit-overflow-scrolling: touch;

    .modal-dialog {
      margin: 0 !important;
      //width of dialog set here
    }

    .modal-content {
      border-radius: 0 !important;
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      background: white;
    }

    .modal-header {
      height: 50px;
      top: 0;
      right: 0;
      z-index: 1;
      position: fixed;
    }

    .how-to-text {
      padding-top: 50px;
    }
  }
}

.how-to-text {
  overflow-x: hidden;
  @media (min-width: 1280px) {
    max-height: 70vh
  }
}


.he #mobile-how-modal {
  text-align: right;
  direction: rtl;
}

@media (min-width: 1280px) {
  #mobile-how-modal {
    .modal-lg,
    .modal-xl {
      max-width: 1200px;
    }
  }
}
</style>