<template>
  <b-modal
    id="settings-modal"
    v-model="show"
    ok-only
    no-fade
    button-size="lg"
    size="lg"
    @show="modalShow"
    @close="restoreSettings"
    body-class="p-0"
    footer-class="position-relative"
    header-class="bg-light"
    :header-close-content="hebrew ? '&#x279C;' : '&larr;'"
    :title="hebrew ? 'חזור' : 'Back'"
    :ok-title="hebrew ? 'שמירה' : 'Save'"
  >
    <h3 class="px-3 mb-2 mt-3">
      {{ hebrew ? "הגדרות תצוגה" : "Display Settings" }}
    </h3>
    <div
      class="advanced-settings-mobile-wrap h-auto bg-white p-3 border-top border-bottom"
    >
      <display-settings ref="displaySettings"></display-settings>
    </div>
  </b-modal>
</template>
<script>
import DisplaySettings from "@/components/DisplaySettings"
export default {
  name: "MobileSettings",
  data() {
    return {
      showParallels: false,
      showNikud: false,
      // showPunctuation: false,
      showAbbr: false,
      fontSize: 0,
      dictaFont: false,
      showOCRTagging: false,
      showFlaggedWords: false,
      showEIM: false,
    }
  },
  components: {
    DisplaySettings,
  },
  props: ["value"],
  methods: {
    restoreSettings() {
      //user cancelled, revert any state changes
      this.$store.commit("SET_FONT_SIZE", this.fontSize)
      this.$store.commit("SET_SELECTED_DISPLAY", this.showParallels)
      this.$store.commit("SET_SHOW_NIKUD", this.showNikud)
      //  this.$store.commit("SET_SHOW_PUNCTUATION", this.showPunctuation)
      this.$store.commit("SET_SHOW_ABBR", this.showAbbr)
      this.$store.commit("SET_USE_DICTA_FONT", this.dictaFont)
      this.$store.commit("SET_SHOW_TAGGING", this.showOCRTagging)
      this.$store.commit("SET_SHOW_FLAGGED", this.showFlaggedWords)
      this.$store.commit("SET_SHOW_EIM", this.showEIM)
      this.$refs.displaySettings.saveDataToLocalStorage()
    },
    modalShow() {
      history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      )
      //set default values for settings, so we don't emit incorrect values when saving
      this.showParallels = this.$store.state.showParallels
      this.showNikud = this.$store.state.showNikud
      /*     this.showPunctuation =this.$store.state.showPunctuation
      ) */
      this.showAbbr = this.$store.state.showAbbr
      this.fontSize = this.$store.state.fontSize
      this.dictaFont = this.$store.state.useDictaFont
      this.showOCRTagging = this.$store.state.showManualTagging
      this.showFlaggedWords = this.$store.state.showFlaggedWords
      this.showEIM = this.$store.state.showEIM
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },

    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>
<style lang="scss">
.advanced-settings-mobile-wrap {
  overflow-y: auto;
  overflow-x: hidden;
}

#settings-modal {
  .modal-dialog {
    height: 100%;
    direction: ltr;
    margin: 0;
  }

  .modal-body {
    background: #f6f6f6;
    height: 69vh;
    overflow-y: auto;

    @media screen and (orientation: landscape) {
      //height: auto;
      height: 85%;
      flex: none;
      padding-bottom: 0;
    }
  }

  .modal-content {
    // width: 360px!important;
    // max-width:97%!important;
    // min-width: 330px;
    // min-height: 300px;
    @media screen and (orientation: landscape) {
      min-height: 0;
      //  width: 75% !important;

      .modal-footer {
        padding-top: 5px;
      }
    }

    height: 100%;
    direction: rtl;
  }

  .modal-header {
    padding-right: 37px;
    padding-left: 37px;
    border-radius: 0;

    h5 {
      font-size: 16px;
    }
  }

  .close {
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 30px !important;
    left: auto;
    top: 19px;
    font-size: 19px;
    text-shadow: none;
    font-weight: 300;
    color: var(--primary);
  }

  .modal-footer {
    background: white;
    border-top: solid 1px #c8c8c8;
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.07);
  }

  .btn-primary {
    width: 100%;
    height: 48px;
    font-size: 22px;
    border-radius: 2px;
  }
}
</style>
