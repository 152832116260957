var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"token-wrapper"},[(_vm.loading)?_c('div',{staticClass:"position-fixed text-center w-100 h-100",staticStyle:{"top":"0","left":"0","z-index":"9"}},[_c('spinner',{staticClass:"mt-5 pt-5"})],1):_vm._e(),_c('div',{staticClass:"tokens bg-white mb-3 pb-sm-3 position-relative pl-md-4"},[(_vm.indexPage)?_c('div',{staticClass:"custom",domProps:{"innerHTML":_vm._s(_vm.fileData)}}):_c('div',{ref:"sourceText",staticClass:"text-right source-text scrollbar-hidden pl-md-3",class:{
        'show-parallels': _vm.showParallels,
        'parallels-minimized': _vm.parallelsMinimized,
      },attrs:{"dir":"rtl"},on:{"scroll":_vm.checkMobilePosition}},[_c('div',{ref:"textWrap",staticClass:"wrap px-3 pt-1 px-sm-0 pt-sm-0 bg-white"},[(
            (_vm.withNikud || _vm.withPunctuation || _vm.notHumanReviewed) && !_vm.shasLibrary
          )?_c('small',{staticClass:"error-note text-muted text-sm-center text-md-right d-block bg-light px-2 mb-2 py-1"},[_vm._v(" "+_vm._s(_vm.notHumanReviewed ? "טקסט זה לא עבר הגהה, ולכן צפויים שיבושי ניקוד ופיסוק. אם נתקלתם בטעויות כאלה אנא ידעו אותנו." : "הניקוד והפיסוק בטקסט נוצרו אוטומטית, ולכן צפויים שיבושי ניקוד ופיסוק.אם נתקלתם בטעויות - אנא שלחו משוב.")+" ")]):_vm._e(),(_vm.summary)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._m(0),_c('b-btn',{staticClass:"bg-transparent shadow-none p-0 text-primary border-0",on:{"click":function($event){_vm.enSummary = !_vm.enSummary}}},[_vm._v(" "+_vm._s(_vm.enSummary ? "עברית" : "English")+" ")])],1),(_vm.topics)?_c('ul',{staticClass:"px-1 list-unstyled text-left",attrs:{"dir":"ltr"}},_vm._l((_vm.topics),function(topic){return _c('li',{key:topic,staticClass:"mb-2"},[_c('small',[_vm._v(_vm._s(topic))])])}),0):_vm._e(),_c('generated-summary',{attrs:{"eng":_vm.enSummary}}),(_vm.englishText)?_c('english-translation'):_vm._e()],1):_vm._e(),_c('span',{staticStyle:{"scroll-margin-top":"70px"},attrs:{"id":"main"}}),_vm._l((_vm.displayTokens),function(token,j){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingMobile),expression:"!loadingMobile"}],key:j,staticClass:"token-wrapper",class:{
            'feedback-item border border-warning rounded':
              token.feedbackClicked,
            'punctuation-token':
              !_vm.feedbackMode && token.punctuationIDs && _vm.withPunctuation,
          }},[(token.abbreviationID >= 0 && _vm.abbrChecked)?_c('b-popover',{attrs:{"target":'token' + j,"placement":"top","title":"","triggers":"click blur","content":_vm.stringWithoutMeteg(
                _vm.fileData.data.abbreviatonResults[
                  _vm.fileData.tokens[j].abbreviationID
                ].options[0]
              )}}):_vm._e(),(token.text.includes('\n') && !_vm.shasLibrary)?_c('br'):_vm._e(),_c('span',{class:{
              abbr: token.abbreviationID >= 0 && _vm.abbrChecked,
              'active position-relative':
                token.active && !_vm.feedbackMode && _vm.showParallels,
              'first-active':
                token.active &&
                _vm.displayTokens[j - 1] &&
                !_vm.displayTokens[j - 1].active &&
                !_vm.feedbackMode &&
                _vm.showParallels,
              'last-active':
                token.active &&
                _vm.displayTokens[j + 1] &&
                !_vm.displayTokens[j + 1].active &&
                !_vm.feedbackMode &&
                _vm.showParallels,
              'f-narkis': !_vm.dictaFont,
              'f-dicta': _vm.dictaFont,
              edited: token.edited && _vm.showManualTagging,
              'font-weight-bold': token.bold || token.heading,
              'text-muted': token.flagged && _vm.showOCRFlaggedWords,
            },staticStyle:{"line-height":"2"},style:({
              fontSize: token.heading
                ? _vm.computedFontSize + 10 + 'px'
                : _vm.computedFontSize + 'px',
            }),attrs:{"id":'token' + j,"tabindex":_vm.feedbackMode ? 0 : -1},on:{"click":function($event){return _vm.onTokenClicked(j)}}},[(token.text.includes('\n') && _vm.shasLibrary)?_c('br'):_vm._e(),(token.markedParagraph)?_c('span',[_c('br'),_c('br')]):_vm._e(),(token.question)?_c('span',{staticStyle:{"scroll-margin-top":"70px"},attrs:{"id":"question"}}):_vm._e(),(token.answer)?_c('span',{staticStyle:{"scroll-margin-top":"70px"},attrs:{"id":"answer"}}):_vm._e(),(_vm.isMobile)?_c('span',{staticClass:"d-inline-block"}):_vm._e(),(token.explicitCitationsIDs >= 0)?_c('a',{staticClass:"text-link text-body position-relative",attrs:{"target":"_blank","href":_vm.getLink(token.explicitCitationsIDs)}},[_c('span',{attrs:{"tabindex":"0"},domProps:{"innerHTML":_vm._s(_vm.wordForDisplay(token))}})]):_c('span',{attrs:{"tabindex":"0"},domProps:{"innerHTML":_vm._s(_vm.wordForDisplay(token))}}),(token.dictaPunctuation && _vm.withPunctuation)?_c('span',{staticClass:"punctuation-mark text-danger",class:{ 'd-inline-block': _vm.isMobile },staticStyle:{"font-size":"0"}},[_c('span',{staticClass:"d-inline-block",style:({ fontSize: _vm.computedFontSize + 'px' }),attrs:{"tabindex":_vm.feedbackMode ? 1 : -1},on:{"click":function($event){$event.stopPropagation();return _vm.onTokenClicked(j, token.dictaPunctuation)}}},[_vm._v(" "+_vm._s(token.dictaPunctuation)+" ")])]):_vm._e(),(token.lastParallelBaseMatchedTextID && _vm.showParallels)?_c('span',{staticClass:"parallel"},_vm._l((token.lastParallelBaseMatchedTextID),function(parallel,k){return _c('small',{key:k,ref:'parallel-id' + parallel,refInFor:true,staticClass:"text-primary",attrs:{"id":'parallel-link-' +
                  (token.parallelCounter -
                    (token.lastParallelBaseMatchedTextID.length - k) +
                    1)},on:{"click":function($event){$event.stopPropagation();_vm.getParallel(
                    parallel,
                    token.parallelCounter -
                      (token.lastParallelBaseMatchedTextID.length - k) +
                      1
                  )}}},[_vm._v(" ["+_vm._s(token.parallelCounter - (token.lastParallelBaseMatchedTextID.length - k) + 1)+"] ")])}),0):_vm._e()])],1)})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#main"}},[_c('small',{staticClass:"text-underline"},[_vm._v("מעבר לטקסט")])])
}]

export { render, staticRenderFns }