<template>
  <div
    class="page-settings"
    :class="{ 'sticky-top bg-secondary': $mq !== 'sm' }"
  >
    <div
      class=""
      :class="{ 'bg-secondary position-relative info-area': $mq !== 'sm' }"
    >
      <b-container
        :fluid="$mq !== 'xl'"
        class="breadcrumbs-bar d-flex justify-content-between align-items-center h-100 pb-md-1"
      >
        <small class="text-muted py-2 py-md-1" v-if="fileUpload"
          >הטקסט חיצוני למערכת. מכיוון שכך ייתכנו שיבושים בניקוד, בפיסוק ובמציאת
          המקורות, מאחר שאלו מיושמים באמצעות מנגנון אוטומטי.</small
        >
        <b-breadcrumb v-else class="my-0 px-0 p-md-0 bg-transparent d-flex">
          <b-breadcrumb-item href="/" class="px-0" v-if="!internalSingle">
            <small v-if="shasLibrary">{{
              hebrew ? "תוכן עניינים" : "Main"
            }}</small>
            <small v-else>{{
              hebrew ? "הספרייה של דיקטה" : "Dicta's Library"
            }}</small>
          </b-breadcrumb-item>
          <b-breadcrumb-item active class="px-0">
            <small>{{ selectedBook }}</small>
          </b-breadcrumb-item>
          <b-breadcrumb-item active class="px-0">
            <small>{{ selectedFile }}</small>
          </b-breadcrumb-item>
        </b-breadcrumb>
        <div class="d-block mobile-settings" v-if="$mq === 'sm'">
          <i
            style="font-size: 22px; line-height: 27px"
            class="fas fa-cog"
            @click="showMobileSettings = true"
          ></i>
          <chapter-list-dialog
            v-model="showChapterModal"
            :selected-chapter="pageNumber"
          />
          <mobile-settings
            v-if="showMobileSettings"
            v-model="showMobileSettings"
          />
        </div>
      </b-container>
    </div>

    <div class="page-heading">
      <b-container fluid class="d-flex justify-content-between px-3">
        <b-btn
          v-if="!fileUpload"
          :disabled="pageNumber == 0"
          @click="updatePage(pageList[pageNumber - 1])"
          variant="link"
          class="p-0 bg-transparent shadow-none prev-btn"
        >
          <i class="fas fa-caret-right"></i>
        </b-btn>
        <h1 class="mb-0 py-1" @click="headingClicked">
          {{ fileUpload ? "תוצאות" : selectedBook }}
        </h1>
        <b-btn
          v-if="!fileUpload"
          :disabled="pageNumber === pageList.length - 1"
          @click="updatePage(pageList[pageNumber + 1])"
          variant="link"
          class="p-0 bg-transparent shadow-none"
        >
          <i class="fas fa-caret-left"></i>
        </b-btn>
      </b-container>
    </div>
  </div>
</template>
<script>
import MobileSettings from "@/components/MobileSettings"
import ChapterListDialog from "@/components/ChapterListDialog"
//import { Actions } from "@/store/stateChanges"

export default {
  name: "MobileControls",
  props: [],
  components: { MobileSettings, ChapterListDialog },
  data() {
    return {
      pageNumber: 0,
      showMobileSettings: false,
      feedbackMode: false,
      showChapterModal: false,
      fileUpload: process.env.VUE_APP_FILE_TYPE === "file-upload",
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
      internalSingle:
        process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle"),
    }
  },
  mounted() {
    this.setDropDownItems()
  },
  methods: {
    updatePage(pageNum) {
      this.pageNumber = this.pageList.indexOf(pageNum)
      this.$store.commit(
        "SET_SELECTED_FILE",
        this.bookFiles[this.pageNumber].fileName
      )
      //this.$store.dispatch(Actions.GET_FILE_DATA)
      this.$store.commit("UPDATE_ROUTE")
    },
    checkDropdownClicked() {
      let el =
        document.getElementsByClassName("fa-check")[0].parentNode.parentNode
          .parentNode.parentNode
      setTimeout(function () {
        el.scrollIntoView({ behavior: "auto", block: "start" })
      }, 70)
    },
    headingClicked() {
      if (this.$mq === "sm") this.showChapterModal = true
    },
    setDropDownItems() {
      var index = this.bookFiles
        .map(function (e) {
          return e.fileName
        })
        .indexOf(this.$store.state.selectedFile)
      if (index < 0) index = 0 //if user reloads page  incorrect or missing page name
      //this.title = this.selectedBook + ' ' + this.bookFiles[index].displayName
      this.pageNumber = index
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    isMobile() {
      return this.$mq === "sm"
    },
    selectedBook() {
      return this.$store.state.selectedBook.displayName
    },
    selectedFile() {
      if (this.bookFiles.length > 0) {
        var index = this.$store.state.bookFiles
          .map(function (e) {
            return e.fileName
          })
          .indexOf(this.$store.state.selectedFile)
        if (index >= 0) return this.$store.state.bookFiles[index].displayName
        else return ""
      } else return ""
    },
    fileData() {
      return this.$store.state.fileData
    },
    bookFiles() {
      return this.$store.state.bookFiles
    },
    pageList() {
      return this.bookFiles.map((element) => element.displayName)
    },
  },
  watch: {
    fileData() {
      //for pagination change
      this.setDropDownItems()
    },
  },
}
</script>
<style lang="scss">
.page-settings {
  .info-area {
    height: 32px;
    .breadcrumb-item {
      small {
        font-size: 12px;
      }
    }
    .breadcrumb-item + .breadcrumb-item:before {
      font-size: 11px;
    }
  }

  .breadcrumb-item + .breadcrumb-item:before {
    padding-right: 4px;
    padding-left: 4px;
  }

  .mobile-settings {
    small {
      line-height: 18px;
    }
  }

  .page-heading {
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);

    h1 {
      font-size: 22px;

      overflow: hidden;
      white-space: nowrap;
      max-width: 82%;
      text-overflow: ellipsis;
      text-decoration: underline;
      font-size: 22px;
      line-height: 24px;
      color: var(--primary);
    }

    .fa-caret-right,
    .fa-caret-left {
      font-size: 22px;

      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }

    @media screen and (max-width: 767px) {
      border-radius: 6px 6px 0 0;
      background: #d6ecff;
      height: 35px;
      box-shadow: none;

      .btn {
        font-size: 25px;
        left: 10px;
        top: 5px;
      }
    }
  }
}
</style>
