<template>
  <b-modal
    @show="onShow"
    v-model="show"
    centered
    id="download-modal"
    no-close-on-backdrop
    no-close-on-esc
    size="lg"
    title-tag="div"
    titleHtml='<i class="fas fa-download"></i> <span>הורדת קבצים</span>'
    body-class="px-4"
    @ok="download"
    ok-title="הורדה"
    cancel-title="ביטול"
    cancel-variant="outline-dark"
  >
    <spinner v-if="running"></spinner>
    <small
      v-if="!fileUpload && !shasLibrary"
      class="rounded text-muted text-sm-center text-md-right d-block bg-light px-2 py-2 mb-2"
    >
      <span></span>
      ניתן גם להוריד את הקבצים מהאתר גיטהאב של דיקטה:
      <br /><a
        href="https://github.com/Dicta-Israel-Center-for-Text-Analysis/Dicta-Library-Download/"
        >https://github.com/Dicta-Israel-Center-for-Text-Analysis/Dicta-Library-Download</a
      >
    </small>
    <small
      v-if="shasLibrary"
      class="rounded text-muted text-sm-center text-md-right d-block bg-light px-2 py-2 mb-3"
    >
      ניתן גם להוריד את הקבצים כאן:
      <a href="https://files.dicta.org.il/books_shas.json"
        >https://files.dicta.org.il/books_shas.json</a
      >
    </small>

    <div
      class="border-bottom border-top border-secondary py-3 mb-3"
      v-if="!fileUpload"
    >
      <label class="xheading ml-3">עמודים</label>
      <b-dropdown variant="light" :text="downloadOptions[downloadOptionIndex]">
        <b-dropdown-item
          @click="updateDownloadOptions(j)"
          :class="{ 'bg-secondary': downloadOptionIndex === j }"
          v-for="(item, j) in downloadOptions"
          :key="j"
          >{{ item }}</b-dropdown-item
        >
      </b-dropdown>
      <span v-if="!showRange && !downloadAll">
        <label class="mr-sm-4 ml-2">מס' עמוד</label>
        <b-dropdown
          :text="pageList[pageNumber]"
          variant="light"
          id="page-number"
          @show="onDropdownShow('page-number')"
        >
          <b-dropdown-item
            @click="pageNumber = j"
            :class="{ 'bg-secondary': pageNumber === j }"
            v-for="(item, j) in pageList"
            :key="j"
            >{{ item }}</b-dropdown-item
          >
        </b-dropdown>
      </span>
      <span v-if="showRange" class="d-block d-sm-inline mt-4 mt-sm-0">
        <label class="mr-sm-4 ml-2">מ</label>
        <b-dropdown
          :text="pageList[fromPage]"
          variant="light"
          id="from-page-number"
          @show="onDropdownShow('from-page-number')"
        >
          <b-dropdown-item
            @click="fromPage = j"
            :class="{ 'bg-secondary': fromPage === j }"
            v-for="(item, j) in pageList"
            :key="j"
            >{{ item }}</b-dropdown-item
          >
        </b-dropdown>
        <label class="mr-4 ml-2">עד</label>
        <b-dropdown
          :text="pageList[toPage]"
          variant="light"
          id="to-page-number"
          @show="onDropdownShow('to-page-number')"
        >
          <b-dropdown-item
            :disabled="j < fromPage"
            @click="toPage = j"
            :class="{ 'bg-secondary': toPage === j }"
            v-for="(item, j) in pageList"
            :key="j"
            >{{ item }}</b-dropdown-item
          >
        </b-dropdown>
      </span>
    </div>
    <div class="d-flex" style="min-height: 56px">
      <label class="ml-4">אפשרויות</label>
      <div>
        <b-form-checkbox
          :disabled="downloadingIndexPages && !downloadAll"
          id="with-nikud-cb"
          class="mb-2"
          v-model="downloadMenukad"
          name="with-nikud-cb"
        >
          עם ניקוד
        </b-form-checkbox>

        <b-form-checkbox
          v-if="shasLibrary && downloadMenukad"
          id="eim-cb"
          v-model="showEim"
          name="eim-cb"
        >
          עם אימות קריאה
        </b-form-checkbox>
      </div>
    </div>
    <server-failed-popup v-if="failed" v-model="failed" />
  </b-modal>
</template>
<script>
import { RunStates } from "@/store/runStates"
import ServerFailedPopup from "@/components/ServerFailedPopup"
import Spinner from "@/components/spinner"
import * as JSZip from "jszip"
import axios from "axios"

const LIBRARY_SERVER = "https://files.dicta.org.il"

export default {
  name: "DownloadModal",
  props: ["value"],
  components: {
    Spinner,
    ServerFailedPopup,
  },
  data() {
    return {
      fileUpload: process.env.VUE_APP_FILE_TYPE === "file-upload",
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
      downloadOptions: ["עמוד בודד", "מותאם אישית", "הכל"],
      downloadOptionIndex: 0,
      pageNumber: 0,
      fromPage: 0,
      toPage: 0,
      showRange: false,
      downloadAll: false,
      downloadMenukad: false,
      showEim: true,
    }
  },
  methods: {
    async getFileDataForDownload(files) {
      this.$store.commit("DOWNLOAD_RUNNING")

      const getAndProcessFile = async (file) => {
        try {
          const response = await axios.get(
            `${LIBRARY_SERVER}/${this.$store.state.selectedBook.fileName}/${file}`,
            { responseType: "blob" }
          )

          if (response.data.type === "text/html") {
            return response.data.text().then((text) => {
              return {
                text: text.replace(/<\/?[^>]+>/g, (tag) =>
                  tag.endsWith(">") ? "\n" : ""
                ),
              }
            })
          } else {
            const zip = new JSZip()
            const zipData = await zip.loadAsync(response.data)
            const content = await zipData
              .file(Object.keys(zipData.files)[0])
              .async("string")
            return {
              text: this.convertTokensToText(JSON.parse(content)) || [],
            }
          }
        } catch (error) {
          console.error("Error processing file:", file, error)
          throw error
        }
      }

      try {
        const results = await Promise.all(
          files.map(async (file) => {
            const processedFile = await getAndProcessFile(file)
            return processedFile.text
          })
        )
        await this.generateTxt(results.join("\n\n"))
        this.$store.commit("DOWNLOAD_COMPLETE")
      } catch (error) {
        console.log("Error:", error)
        this.$store.commit("DOWNLOAD_FAILED")
      }
    },
    updateDownloadOptions(index) {
      this.downloadOptionIndex = index
      this.showRange = index === 1
      if (this.showRange) {
        this.fromPage = this.selectedViewedFileIndex
        this.toPage = this.selectedViewedFileIndex
      }
      this.downloadAll = index === 2
    },
    onDropdownShow(openDropdownId) {
      this.$nextTick(() => {
        const dropdown = document.getElementById(openDropdownId)
        const selectedItem = dropdown.querySelector(".bg-secondary")
        if (selectedItem) {
          selectedItem.scrollIntoView({ behavior: "instant", block: "start" })
        }
      })
    },
    stringWithoutMeteg(wrd) {
      return wrd ? wrd.replace(/.[\u05bd]/g, "") : ""
    },
    download(evt) {
      evt.preventDefault()
      let selectedFilesForDownload = []
      if (this.downloadAll) {
        this.fromPage = 0
        this.toPage = this.pageList.length - 1
      }
      if (this.downloadAll || (this.showRange && this.toPage > this.fromPage)) {
        for (let i = this.fromPage; i <= this.toPage; i++) {
          selectedFilesForDownload.push(this.bookFiles[i].fileName)
        }
      } else {
        let fileName = this.showRange
          ? this.bookFiles[this.fromPage].fileName
          : this.bookFiles[this.pageNumber].fileName
        selectedFilesForDownload = [fileName]
      }
      this.getFileDataForDownload(selectedFilesForDownload)
    },
    generateTxt(text) {
      // text.replace(/ +/g, " ")
      let bookName =
        this.bookFiles[this.bookFiles.length - 1].fileName.split("-")[0]
      //get book name from last file in case first file is an html index page and inconsistent with json files
      let fileName =
        this.showRange && this.toPage > this.fromPage
          ? this.pageList[this.fromPage] + "-" + this.pageList[this.toPage]
          : this.showRange
          ? this.pageList[this.fromPage] //incorrect range selection fromPage > toPage
          : this.downloadAll
          ? ""
          : this.pageList[this.pageNumber]
      let element = document.createElement("a")
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      )
      element.setAttribute(
        "download",
        "dicta-library-" + bookName + fileName + ".txt"
      )
      element.style.display = "none"
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    onShow() {
      this.downloadOptionIndex = 0
      this.pageNumber = this.selectedViewedFileIndex
      this.showRange = false
      this.downloadAll = false
      this.downloadMenukad = this.$store.state.showNikud
      this.showEim = this.$store.state.showEIM
    },
    async convertTokensToText(fileDataForDownload) {
      let displayTokens = []
      let text = ""
      let prevToken = null
      fileDataForDownload.tokens.map((element) => {
        const options =
          (this.downloadMenukad &&
            fileDataForDownload.data.nikudResults[element.nikudID]?.options) ||
          []
        let obj = {}
        let nikudText, haser, maleh
        nikudText = element.str
        if (this.downloadMenukad) {
          nikudText = options[0]?.w || element.str
          haser = options[0]?.haser || nikudText
          maleh = options[0]?.maleh || nikudText
        }
        let txt = this.shasLibrary
          ? this.downloadMenukad
            ? this.showEim
              ? maleh
              : haser
            : nikudText.replace(/[^\sא-ת\p{P}]/gu, "")
          : this.downloadMenukad
          ? this.stringWithoutMeteg(nikudText)
          : element.str
        obj.text = txt
        obj.sep = element.sep
        obj.bold = element.bold
        obj.heading = element.heading
        obj.markedParagraph =
          element.markedParagraph && prevToken && !prevToken.markedParagraph
        displayTokens.push(obj)
        prevToken = element
      })

      displayTokens.map((obj) => {
        if (obj.markedParagraph) {
          text += "\r\r"
        }
        text += obj.text ? obj.text : ""
      })
      return text
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    downloadingIndexPages() {
      return (
        (this.bookFiles &&
          this.showRange &&
          this.bookFiles[this.fromPage].fileName.includes("html") &&
          this.bookFiles[this.toPage].fileName.includes("html")) ||
        (this.bookFiles[this.pageNumber] &&
          !this.showRange &&
          this.bookFiles[this.pageNumber].fileName.includes("html"))
      )
    },
    runState() {
      return this.$store.state.downloadState
    },
    failed: {
      get() {
        return this.runState === RunStates.FAILED
      },
      set() {
        this.$store.commit("DOWNLOAD_NOT_RUN")
      },
    },
    running() {
      return this.runState === RunStates.RUNNING
    },
    bookFiles() {
      return this.$store.state.bookFiles
    },
    pageList() {
      return this.bookFiles.map((element) => {
        return element.displayName
      })
    },
    selectedViewedFileIndex() {
      return this.bookFiles
        .map((e) => e.fileName)
        .indexOf(this.$store.state.selectedFile)
    },

    /*     fileDataForPrint() {
      return this.$store.state.fileDataForPrint
    },
    */
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>
<style lang="scss">
#download-modal {
  .modal-lg {
    max-width: 620px;
    border-radius: 8px;
  }

  .rounded {
    border-radius: 8px !important;
  }

  footer {
    button {
      height: 36px;
      width: 100px;
      font-size: 16px;
      border-radius: 4px;

      &.btn-outline-dark {
        border: solid 1px #bfbfbf;
        color: var(--primary);

        &:hover {
          background: var(--light);
        }
      }
    }
  }
}
</style>
