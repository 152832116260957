<script>
    // @ is an alias to /src
    import {StoreConfig} from '@/store/storeConfig';
    export default {
        name: 'api-child-handler',
        created() {
            window.location = StoreConfig.serverUrl + '/' + this.$route.params.pathMatch
        }
    }
</script>
