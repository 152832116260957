<template>
    <p dir="ltr" class="w-100 text-center mt-3">Getting Book Names...</p>
</template>

<script>
import {initBookDisplayNameMap, bookAndPageFromXmlId} from '../js/processXmlId'
import router from '@/router'
import {Actions} from "@/store/stateChanges"
export default {
  name: 'XmlId',
    created () {
        if (this.$store.state.bookList.length === 0) {
            this.$store.dispatch(Actions.GET_BOOKS)
        }
        let tries = 0
        const maxTries = 2
        const intervalHolder = setInterval(async () => {
            if (++tries > maxTries) clearInterval(intervalHolder)
            if (this.$store.state.bookList.length) {
                initBookDisplayNameMap(this.$store.state.bookList)
                const bookAndPage = bookAndPageFromXmlId(this.$route.query.id, this.$store.state.bookList)
                // console.log('bookAndPage:')
                // console.log(bookAndPage)
                router.push({ name: "File", params: bookAndPage }) // .catch(err => err)
            }
        }, 500)
        
    }
}
</script>