<template>
  <div
    id="app"
    class="bg-background"
    :class="{ 'd-header-none': headerHidden }"
  >
    <!-- <div class="mobile-landscape-msg text-center alert alert-success mx-5 mt-5" v-if="mobileLandscape">
      לא קיימת תצוגה זמינה במצב אופקי. בבקשה חזור למצב אנכי
    </div> -->
    <!--h-100-->
    <Header />
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header"

export default {
  name: "app",
  components: {
    Header,
  },

  created() {
    /*     const urlParams = new URLSearchParams(window.location.search)
        const lang = urlParams.get('lang')
        if (this.$cookies.get('DICTA_USE_HEBREW') !== null) {
          this.$settings.hebrew = (this.$cookies.get('DICTA_USE_HEBREW') === 'true')
        } else if (lang !== null) {
          this.$settings.hebrew = lang.includes('he')
        } else {
          this.$settings.hebrew = true
        }
        if (this.$settings.hebrew) {
          document.body.classList.add('he')
        } else {
          document.body.classList.remove('he')
        }    */
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    mobileLandscape() {
      return this.$mq === "sm" && false //window.innerHeight < window.innerWidth
    },
    headerHidden() {
      return this.$store.state.hideHeader
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: "mft_narkisclassic";
  src: url("assets/fonts/mft_narkisclassic-regular.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "mft_narkisclassic";
  src: url("assets/fonts/MFTW_NarkisClassicBold.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

.f-narkis {
  font-family: mft_narkisclassic, sans-serif;
  line-height: 1.5;
}

@font-face {
  font-family: "dicta";
  src: url("assets/fonts/Dicta-Medium.otf") format("opentype");
}

@font-face {
  font-family: "dicta";
  font-weight: bold;
  src: url("assets/fonts/Dicta-Bold.otf") format("opentype");
}

.f-dicta {
  font-family: "dicta";
  line-height: 1.5;
  letter-spacing: 0.6;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1180px;
  }
}

@media (min-width: 576px) and (max-width: 769px) {
  .container {
    max-width: 100%;
  }
}

.fluid-small {
  @media screen and (min-width: 578px) and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  html {
    height: 100%;
  }
  /*  body {
    position: fixed;
    width: 100%;
    left: 0;
  } */
}

.dropdown-menu {
  max-height: 150px;
  overflow: auto;
}

.dropdown-toggle {
  border-radius: 8px;
  border: 1px solid;
  height: 36px;
  min-width: 90px;
  padding: 0 10px 0 25px;
  text-align: right;
  box-shadow: none;
  background-color: white !important;

  &::after {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: 7px;
    border: none;
  }
}

.dropdown-item.disabled {
  color: #aab3bb;
}

.dropdown-menu {
  right: 0 !important;
}
</style>
