var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"chapter-modal",attrs:{"id":"chapter-list-modal","centered":"","size":_vm.$mq === 'sm' ? 'sm' : 'lg',"title":_vm.displayName,"footer-class":"border-top p-2","hide-footer":"","header-class":_vm.$mq === 'sm' ? 'bg-primary text-white' : '',"title-tag":"span"},on:{"show":_vm.scrollIntoView},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(!_vm.shasLibrary)?_c('div',{ref:"sourceBox",staticClass:"source-info position-relative",class:{ 'full-height': _vm.showFullSource },on:{"click":function($event){_vm.showFullSource = !_vm.showFullSource}}},[_c('div',{staticClass:"position-absolute toggle-source"},[(_vm.showFullSource)?_c('i',{staticClass:"fas fa-angle-down"}):_c('i',{staticClass:"fas fa-angle-up"})]),(_vm.selectedBook.category)?_c('div',{attrs:{"id":"category"}},[_vm._v(" "+_vm._s(_vm.hebrew ? "קטגוריה" : "Category")+" - "+_vm._s(_vm.selectedBook.category)+" ")]):_vm._e(),(_vm.selectedBook.printYear)?_c('div',{attrs:{"id":"year"}},[_vm._v(" "+_vm._s(_vm.hebrew ? "שנת הדפסה" : "Year printed")+" - "+_vm._s(_vm.selectedBook.printYear)+" ")]):_vm._e(),(_vm.selectedBook.printLocation)?_c('div',{attrs:{"id":"location"}},[_vm._v(" "+_vm._s(_vm.hebrew ? "מקום הדפסה" : "Location")+" - "+_vm._s(_vm.selectedBook.printLocation)+" ")]):_vm._e(),(_vm.selectedBook.author)?_c('div',{attrs:{"id":"author"}},[_vm._v(" "+_vm._s(_vm.hebrew ? "מחבר" : "Author")+" - "+_vm._s(_vm.selectedBook.author)+" ")]):_vm._e()]):_vm._e(),(!_vm.shasLibrary)?_c('hr',{staticClass:"mx-n3 mt-2"}):_vm._e(),_c('div',{staticClass:"overflow-auto list-wrap",class:{
      'show-book-details': _vm.showFullSource,
      ios: _vm.isIOS,
      'shas-library': _vm.shasLibrary,
    }},[_c('b-list-group',_vm._l((_vm.chapterList),function(item,j){return _c('b-list-group-item',{key:j,staticClass:"text-center",class:{ 'active-item': j === _vm.chapterNum },on:{"click":function($event){return _vm.chapterClicked(j)}}},[_c('span',{staticClass:"d-flex h-100 w-100 align-items-center justify-content-center",domProps:{"innerHTML":_vm._s(
            _vm.shasLibrary
              ? item.replace('דף', '')
              : item.replace(/\s+/g, '<br>')
          )}})])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }