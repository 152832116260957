<template>
  <div
    class="file"
    @click="pageClicked"
    :class="isMobile ? 'bg-light' : 'bg-white position-relative'"
  >
    <mobile-controls
      v-if="$mq !== 'lg' && $mq !== 'xl'"
      @feedbackModechanged="(val) => feedbackModechanged(val)"
    />
    <desktop-controls v-else></desktop-controls>
    <!-- <toggle-header /> -->
    <b-container :fluid="$mq !== 'xl'" class="pt-3">
      <div class="pt-4" v-if="incorrectBook">
        {{
          hebrew ? "הספר שחיפשת לא קיים בספרייה של דיקטה" : "Book not in list"
        }}
      </div>
      <div class="pt-4" v-else-if="incorrectPage">
        {{
          hebrew ? "העמוד שחיפשת לא קיים במאגר של דיקטה" : "Page does not exist"
        }}
      </div>
      <b-row
        v-else-if="
          (!incorrectBook && !incorrectPage && bookFiles.length > 0) ||
          fileUpload
        "
      >
        <b-col
          cols="12"
          md="8"
          class="mx-auto"
          :class="{ 'pl-lg-5': sidebar, 'px-lg-3': !sidebar }"
        >
          <token-display
            ref="tokenDisplay"
            :feedbackMode="feedbackMode"
            :feedbackTopic="feedbackTopic"
            :parallelsMinimized="resultsMinimized"
            @show-mobile-parallels="resultsMinimized = false"
            @results="setTokenSidebarInfo"
            @tokenClicked="(obj) => setFeedbackInfo(obj)"
            @countItems="(val) => (resultsCount = val)"
          >
          </token-display>
        </b-col>
        <b-col
          cols="12"
          md="4"
          v-if="sidebar"
          class="pr-0"
          :class="{ sticky: $mq !== 'sm', 'shas-file': shasLibrary }"
        >
          <div
            class="bg-white results mt-md-1"
            :class="{ 'parallels-enlarged': !resultsMinimized }"
          >
            <div
              class="parallels-heading pr-3 pl-2 py-1 bg-secondary text-center d-flex d-md-none justify-content-between position-relative"
            >
              <b-btn
                :disabled="results.id === 1 || resultsCount === 0"
                @click="navigate(-1, results.id)"
                id="prev-btn-mobile"
                variant="link"
                class="py-0 px-1 bg-transparent shadow-none"
              >
                <i class="fas fa-caret-right"></i>
              </b-btn>

              <h2 class="d-inline-block" v-if="sidebar">
                {{ hebrew ? "מקבילות" : "Parallels" }}
                <span
                  class="result-number rounded-circle bg-light d-inline-block text-center"
                >
                  {{ loading ? "" : resultsCount }}
                </span>
              </h2>
              <b-btn
                id="next-btn-mobile"
                :disabled="resultsCount === results.id || resultsCount === 0"
                @click="navigate(1)"
                variant="link"
                class="py-0 px-1 bg-transparent shadow-none d-inline-block"
              >
                <i class="fas fa-caret-left"></i>
              </b-btn>
            </div>
            <div class="results-wrap" ref="parallelResults">
              <div v-if="showNoResults" class="my-4 text-muted text-center">
                {{ hebrew ? "לא נמצאו תוצאות" : "No results found" }}
              </div>
              <div v-else class="d-none d-md-block px-3 pt-3">
                <b-btn
                  id="prev-p-btn"
                  :disabled="results.id === 1 || resultsCount === 0"
                  @click="navigate(-1, results.id)"
                  variant="link"
                  class="py-0 px-1 bg-transparent shadow-none text-dark"
                >
                  <i class="fas fa-caret-right"></i>
                </b-btn>
                <small class="text-primary" id="current-parallel"
                  >[{{ results.id }}/{{ resultsCount }}]</small
                >
                <b-btn
                  id="next-p-btn"
                  :disabled="resultsCount === results.id || resultsCount === 0"
                  @click="navigate(1)"
                  variant="link"
                  class="py-0 px-1 bg-transparent shadow-none d-inline-block text-dark"
                >
                  <i class="fas fa-caret-left"></i>
                </b-btn>
              </div>
              <parallels
                v-if="sidebar"
                :tokenData="results"
                :feedbackMode="feedbackMode"
                :feedbackTopic="feedbackTopic"
                @tokenClicked="(obj) => setFeedbackInfo(obj)"
              >
              </parallels>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <feedback-dialog v-model="showFeedbackDialog"> </feedback-dialog>
    <feedback-form
      v-if="feedbackMode && showFeedbackForm"
      :feedbackWord="feedbackText"
      :feedbackTopic="feedbackTopic"
      :topics="feedbackTopics"
      @formSubmitted="showFeedbackForm = false"
    >
    </feedback-form>
    <button
      v-if="showBackToTop"
      @click="scrollToTop"
      style="bottom: 15px; right: 15px; height: 50px; width: 50px"
      class="btn btn-secondary position-fixed rounded-circle"
    >
      <i class="fa fa-chevron-up"></i>
    </button>
  </div>
</template>

<script>
//import ToggleHeader from "@/components/toggleHeader"
import TokenDisplay from "@/components/TokenDisplay"
import MobileControls from "@/components/MobileControls"
import DesktopControls from "@/components/DesktopControls"
import Parallels from "@/components/Parallels"
import FeedbackDialog from "@/components/FeedbackDialog"
import FeedbackForm from "@/components/FeedbackForm"
import { Actions } from "@/store/stateChanges"
import { RunStates } from "@/store/runStates"
import eventBus from "@/js/EventBus"

export default {
  name: "File",
  props: {},
  components: {
    TokenDisplay,
    Parallels,
    FeedbackDialog,
    FeedbackForm,
    MobileControls,
    DesktopControls,
  },
  data() {
    return {
      showBackToTop: false,
      results: {},
      resultsCount: 0,
      showNoResults: false,
      feedbackMode: false,
      showFeedbackDialog: false,
      showFeedbackForm: false,
      feedbackText: "",
      feedbackTopic: "",
      feedbackElement: document.getElementsByClassName("feedback-item"),
      resultsMinimized: false,
      fileUpload: process.env.VUE_APP_FILE_TYPE === "file-upload",
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
      internalSingle:
        process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle"),
    }
  },
  created() {
    // Listen for the 'feedback mode changed' from header component from the global event bus
    eventBus.$on("feedbackModechanged", this.feedbackModechanged)
  },
  beforeDestroy() {
    eventBus.$off("feedbackModechanged", this.feedbackModechanged)
    window.removeEventListener("scroll", this.handleScroll)
  },
  async mounted() {
    if (this.indexPage) {
      this.importExternalCss()
    }
    window.addEventListener("scroll", this.handleScroll)
    //this.$root.$on('feedbackModechanged', this.feedbackModechanged)
    if (this.fileUpload) {
      if (this.$route.query.text) {
        this.$store.commit("SET_TEXT_FOR_API_CALL", this.$route.query.text)
        setTimeout(() => {
          this.$store.dispatch(Actions.RUN_UPLOAD)
        }, 100)
      } else if (this.uploadedText.length === 0) {
        this.$router.push({ name: "TextEditor" })
      }
    } else {
      let params = window.location.pathname.split("/")
      if (params.length > 2) {
        await this.$store.dispatch(Actions.LOAD_FROM_URL)
      } else {
        if (this.internalSingle) {
          this.$store.dispatch(Actions.GET_BOOKS)
        } else this.$router.push({ name: "Home" })
      }
    }
  },
  methods: {
    importExternalCss() {
      // Create a link element
      const linkElement = document.createElement("link")
      linkElement.rel = "stylesheet"
      linkElement.href =
        "https://files.dicta.org.il/" + this.selectedBookFileName + "/style.css"

      // Append the link element to the document's head
      document.head.appendChild(linkElement)
    },
    handleScroll() {
      const scrollY = window.scrollY || window.pageYOffset
      this.showBackToTop = scrollY > 350
    },
    scrollToHash() {
      const hash = this.$route.hash
      if (hash) {
        const cleanedHash = hash.substring(1) // Remove the leading hash symbol
        const decodedHash = decodeURIComponent(cleanedHash)

        this.$nextTick(() => {
          const targetElement = document.getElementById(decodedHash)
          if (targetElement) {
            const offset = targetElement.offsetTop - 50 // Adjust this value according to your sticky header height
            window.scrollTo({ top: offset, behavior: "smooth" })
          }
        })
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" })
    },
    feedbackModechanged(val) {
      this.feedbackMode = val
      if (!this.hideFeedbackDialog && val) this.showFeedbackDialog = true
      this.feedbackText = ""
      this.feedbackTopic = ""
    },
    closeBtnClicked() {
      this.$store.commit("SET_SELECTED_DISPLAY", false)
    },
    pageClicked(event) {
      if (this.feedbackMode) {
        this.showFeedbackForm =
          event.target.id.indexOf("token") > -1 ||
          event.target.getAttribute("tabindex") === "0" ||
          event.target.closest("#parallel-token") !== null ||
          event.target.closest("#token-feedback-form") !== null
        if (!this.showFeedbackForm) {
          this.feedbackText = ""
          this.feedbackTopic = ""
        }
      } else if (this.sidebar && this.isMobile) {
        if (
          !event.target.closest(".results") &&
          !event.target.classList.contains("fa-caret-left") &&
          !event.target.classList.contains("fa-caret-right")
        ) {
          this.resultsMinimized = true //minimize parallels when clicking on main area
        } else {
          if (
            event.target.classList.contains("parallels-heading") ||
            event.target.tagName === "H2"
          ) {
            this.resultsMinimized = !this.resultsMinimized
          }
        }
      }
    },
    navigate(direction, currentParallelId) {
      this.$refs.parallelResults.scrollTop = 0
      this.$refs.tokenDisplay.navigateParallels(direction, currentParallelId)
    },
    setFeedbackInfo(obj) {
      if (this.feedbackMode) {
        this.feedbackText = obj.text
        this.feedbackTopic = obj.topic
        this.showFeedbackForm = true
      }
    },
    setTokenSidebarInfo(res) {
      if (this.$refs.parallelResults) this.$refs.parallelResults.scrollTop = 0
      this.feedbackText = ""
      this.feedbackTopic = ""
      this.showFeedbackForm = false

      this.showNoResults = res.noResults
      if (res.data) {
        this.results = res
        if (document.activeElement.type !== "button" && this.isMobile)
          this.resultsMinimized = false
      } else {
        this.results = {}
      }
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    indexPage() {
      return this.$store.state.selectedFile.includes("html")
    },
    sidebar() {
      return this.$store.state.showParallels && !this.indexPage
    },
    isMobile() {
      return this.$mq === "sm"
    },
    loading() {
      return this.$store.state.serverState === RunStates.RUNNING
    },
    incorrectBook() {
      return this.$store.state.incorrectBook
    },
    incorrectPage() {
      return this.$store.state.incorrectPage
    },
    bookFiles() {
      return this.$store.state.bookFiles
    },
    hideFeedbackDialog() {
      return this.$store.state.hideFeedbackModal
    },
    feedbackTopics() {
      let topics = []
      if (this.$refs.tokenDisplay.withNikud)
        topics.push({ text: "ניקוד", value: "Nikud" })
      if (this.$refs.tokenDisplay.withPunctuation)
        topics.push({ text: "פיסוק", value: "Punctuation" })
      if (this.$refs.tokenDisplay.abbrChecked)
        topics.push({ text: "ראשי תיבות", value: "Abbreviations" })
      if (this.$refs.tokenDisplay.showParallels)
        topics.push({ text: "מקבילות", value: "Parallels" })
      topics.push({ text: "כללי", value: "General" })

      return topics
    },
    uploadedText() {
      return this.$store.state.txtForApi
    },
    selectedBookFileName() {
      return this.$store.state?.selectedBook?.fileName
    },
  },
  watch: {
    async $route(oldRoute, newRoute) {
      if (oldRoute.path !== newRoute.path) {
        await this.$store.dispatch(Actions.LOAD_FROM_URL)
      } else if (oldRoute.hash !== newRoute.hash) {
        this.scrollToHash()
      }
    },
    indexPage() {
      if (this.indexPage) {
        this.importExternalCss()
      }
    },
  },
}
</script>
<style scoped lang="scss">
.sticky {
  position: sticky;
  top: 91px;
  height: 20vh;
  &.shas-file {
    top: 65px;
  }
}

.results {
  // margin-top: 40px;
  //border-radius: 6px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #d8d8d8;
  border-radius: 8px;

  /*     .close-btn {
        margin: 0 6px 0 2px;
        height: 20px;
        width: 20px;
        line-height: 18px;
        font-size: 21px;

        &:hover {
            background: #f6f6f6;
        }
    } */

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 44px;
    z-index: 1;
    margin-top: 0;
    transition: height 0.2s ease;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 25%);

    &.parallels-enlarged {
      height: 40%;
      height: 60%;
      //update .tokens .source-text.show-parallels

      .results-wrap {
        height: calc(100% - 44px);
        overflow-y: auto;
      }
    }
  }

  .parallels-heading {
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.22);
    border: 1px solid #ada9a9;
    //  height: 44px;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }

  button {
    font-size: 23px;
  }

  h2 {
    margin-bottom: 0;
    vertical-align: middle;
    line-height: 34px;

    .result-number {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      font-family: Arimo;
      font-size: 12px;
      line-height: 20px;
      position: relative;
      top: -1px;
    }
  }

  @media screen and (min-width: 768px) {
    .results-wrap {
      //    max-height: calc(100vh - 190px);
      overflow-x: hidden;
    }
  }
}
</style>
