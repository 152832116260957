<template>
  <div
    class="bg-white p-2"
    :class="
      topics[topicIndex].value === 'Parallels'
        ? 'position-fixed'
        : 'position-absolute'
    "
    id="token-feedback-modal"
    :style="styleObject"
  >
    <form
      id="token-feedback-form"
      ref="feedback-form"
      action="https://formspree.io/f/xvovdkvj"
      target="_blank"
      method="POST"
    >
      <b-form-group>
        <input
          v-model="form.word"
          type="text"
          class="f-narkis border-0 bg-background px-1"
          name="selected-word"
          style="pointer-events: none"
        />
      </b-form-group>
      <b-form-group>
        <dicta-dropdown
          @change="(val) => updateTopic(val, true)"
          :value="topics[topicIndex].text"
          :options="topics.map((el) => el.text)"
          class="d-dropdown w-100"
        ></dicta-dropdown>
        <input v-model="form.topic" type="text" name="topic" class="d-none" />
        <input
          v-model="selectedBookAndFile"
          type="text"
          name="topic"
          class="d-none"
        />
        <input v-model="currentPageUrl" type="text" name="url" class="d-none" />
        <input
          v-model="form.email"
          type="email"
          name="_replyto"
          class="d-none"
        />
      </b-form-group>

      <b-form-group :invalid-feedback="invalidFeedback" :state="state">
        <!--    -->
        <b-form-input
          v-model="form.email"
          :placeholder="hebrew ? 'דואר אלקטרוני' : 'Email'"
          name="email"
          type="email"
          class="px-2"
          :class="{ 'border-danger': invalidFeedback }"
        />
      </b-form-group>

      <b-form-group class="mb-0">
        <textarea
          class="px-2"
          name="message"
          v-model="form.text"
          :placeholder="hebrew ? 'פרוט' : 'Description'"
          rows="3"
        ></textarea>
      </b-form-group>
      <div class="d-flex flex-row-reverse">
        <b-btn @click="submit" sm variant="primary">
          {{ hebrew ? "שלח משוב" : "Submit feedback" }}
        </b-btn>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "FeedbackForm",
  props: ["feedbackWord", "feedbackTopic", "topics"],
  data() {
    return {
      feedbackElement: document.getElementsByClassName("feedback-item"),
      styleObject: {
        top: 0,
        left: 0,
      },
      form: {
        word: "",
        text: "",
        email: "",
        topic: null,
      },
      topicIndex: 0,
      dropdownPlaceholder: "נושא",
      submitted: false,
    }
  },
  mounted() {
    this.updateFormValues()
  },
  methods: {
    setPosition() {
      const buttonRect = this.feedbackElement[0].getBoundingClientRect()
      const popupWidth = 200
      const popupHeight = 240

      let top = buttonRect.bottom + window.scrollY
      let left = buttonRect.left + (buttonRect.width - popupWidth) / 2

      const viewportHeight = window.innerHeight
      const viewportWidth = window.innerWidth

      if (top + popupHeight > viewportHeight + window.scrollY) {
        top = buttonRect.top - popupHeight
      }

      top = top - 70 // account for sticky header

      if (left + popupWidth > viewportWidth) {
        left = viewportWidth - popupWidth
      }

      this.styleObject.top = `${top}px`
      this.styleObject.left = `${left}px`
    },
    updateFormValues() {
      this.form.word = this.feedbackWord
      this.updateTopic(this.feedbackTopic)
      this.$nextTick(() => {
        this.setPosition()
      })
    },
    updateTopic(value, txt) {
      if (txt) {
        this.topicIndex = this.topics.findIndex((object) => {
          return object.text === value
        })
      } else {
        this.topicIndex = this.topics.findIndex((object) => {
          return object.value === value
        })
      }
      if (this.topicIndex < 0) this.topicIndex = 0
      this.form.topic = this.topics[this.topicIndex].value
    },
    resetData() {
      this.form.word = ""
      this.form.text = ""
      this.form.email = ""
      this.form.topic = null
    },
    submit() {
      this.submitted = true
      if (this.$refs["feedback-form"].checkValidity()) {
        this.$refs["feedback-form"].submit()
        this.$bvToast.toast(`המשוב נשלח בהצלחה`, {
          toastClass: "m-2",
          headerClass: "d-none",
          bodyClass: "bg-dark text-white text-center",
          toaster: "b-toaster-bottom-right",
          solid: false,
          appendToast: false,
        })
        setTimeout(() => this.$emit("formSubmitted"), 50)
      }
    },
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    currentPageUrl() {
      return window.location.origin + this.$route.fullPath
    },
    invalidFeedback() {
      if (!this.state) {
        return this.hebrew
          ? "יש להזין כתובת דוא״ל תקינה"
          : "Please enter a valid email address"
      } else {
        return ""
      }
    },
    state() {
      return !this.submitted ||
        (this.form.email.length >= 5 && this.form.email.includes("@")) ||
        this.form.email.length === 0
        ? true
        : false
    },
    selectedBookAndFile() {
      return (
        this.$store.state.selectedBook.displayName +
        ", " +
        this.$store.state.selectedFile.replace(".zip", "")
      )
    },
  },
  watch: {
    feedbackWord(val) {
      if (val.length > 0) this.updateFormValues()
    },
  },
}
</script>
<style lang="scss">
#token-feedback-modal {
  width: 200px;
  z-index: 9;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  border: solid 1px #d8d8d8;

  .form-group {
    margin-bottom: 10px;
  }

  input[type="text"],
  textarea {
    width: 100%;
  }

  .dicta-dropdown .col-auto {
    padding-left: 5px;
    padding-right: 5px;
  }

  .dd-dropdown,
  textarea {
    font-size: 14px;
  }

  .btn-primary {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.b-toaster {
  border-radius: 4px;
  width: 200px;

  .toast-body {
    font-size: 16px;
  }
}
</style>
