<template>
  <div class="parallels overflow-auto">
    <div
      ref="sourceItem"
      v-show="!loadingMobile"
      :class="{ 'show-all': isMobile }"
      class="mb-4 mb-md-0 position-relative p-group"
      v-if="tokenData.id"
    >
      <div :class="{ 'item-wrap pb-2 pt-0 border-0': !isMobile }">
        <div class="d-block f-narkis matched-text">
          <span class="text-primary parallel-num d-inline-block d-md-none ml-2"
            >[{{ tokenData.id }}]</span
          >
          <div
            class="base-text text-muted mb-4 mb-sm-2 d-inline-block"
            :class="{ 'f-dicta': dictaFont }"
            :style="{ fontSize: computedFontSize + 'px' }"
          >
            <span v-html="getBaseText()"> </span>
          </div>
        </div>
        <div
          class="d-block d-sm-flex justify-content-between cursor-pointer"
          :dir="hebrew ? 'rtl' : 'ltr'"
          @click="toggleList()"
        >
          <div
            class="results-num align-items-center d-block d-sm-flex text-center mt-3 mt-md-0"
            v-if="tokenData.data.sources.length >= 1"
          >
            <span v-if="hebrew">
              נמצאו
              {{ tokenData.data.sources.length }}
              מקבילות
            </span>
            <span class="text-left" dir="ltr" v-else>
              {{ tokenData.data.sources.length }}
              Parallels found
            </span>
          </div>
          <div class="btns d-none d-sm-block">
            <b-btn
              variant="link"
              class="synopsis-btn py-0 d-none d-sm-inline-block bg-white px-0 mt-1"
              style="font-size: 12px"
              @click.stop="showSynopsisClicked(tokenData.data)"
            >
              <span class="border-bottom border-primary text-primary">{{
                hebrew ? "השוואת גרסאות" : "View synopsis"
              }}</span>
            </b-btn>
          </div>
        </div>
        <hr class="py-0 m-1" />
        <span v-if="tokenData.data.sources">
          <div
            v-for="(item, index) in tokenData.data.sources"
            :key="index"
            class="py-2 px-3 px-md-0"
            :class="{ 'inner-results': index > 0 }"
          >
            <span v-if="tokenData.data.sources[0].compMatchedText">
              <div
                id="parallel-token"
                :style="{ fontSize: computedFontSize + 'px' }"
                :class="{
                  'f-dicta': dictaFont,
                  'f-narkis': !dictaFont,
                  'feedback-item border border-warning rounded':
                    checkFeedbackClicked(index),
                }"
                @click="
                  parallelClicked(
                    item.compMatchedText
                      .substring(0, item.compStartChar)
                      .split(' ')
                      .splice(-10)
                      .join(' ') +
                      item.compMatchedText.substring(
                        item.compStartChar,
                        item.compStartChar + item.compTextLength
                      ),
                    index
                  )
                "
              >
                <span
                  >...{{
                    item.compMatchedText
                      .substring(0, item.compStartChar)
                      .split(" ")
                      .splice(-10)
                      .join(" ")
                  }}</span
                >
                <b>{{
                  item.compMatchedText.substring(
                    item.compStartChar,
                    item.compStartChar + item.compTextLength
                  )
                }}</b>
                <span>{{
                  item.compMatchedText
                    .substring(
                      item.compStartChar + item.compTextLength,
                      item.compMatchedText.length
                    )
                    .replace(/(([^\s]+\s\s*){10})(.*)/, "$1…")
                }}</span>
              </div>
              <small
                @click="
                  showFullSource = true
                  itemForChapter = item
                "
                class="source-name text-primary cursor-pointer d-block mt-1 text-underline"
                >{{ item.compNameHe }}
                <span v-if="item.year > 0">({{ item.year }})</span>
              </small>
            </span>
            <span v-else>
              <div
                id="parallel-token"
                @click="
                  parallelClicked(item.compDisplayText.substring(0, 50), index)
                "
                :class="{
                  'f-dicta': dictaFont,
                  'f-narkis': !dictaFont,
                  'feedback-item border border-warning rounded':
                    checkFeedbackClicked(index),
                }"
                class="matched-text-inner"
                v-html="item.compDisplayText"
                :style="{ fontSize: computedFontSize + 'px' }"
              ></div>
              <small
                v-if="item.verseDispHeb"
                @click="setVerse(item)"
                class="source-name text-primary cursor-pointer d-block mt-1 text-underline"
                >{{
                  item.verseDispHeb.includes("00")
                    ? item.verseDispHeb.slice(0, -3)
                    : item.verseDispHeb
                }}
              </small>
            </span>
            <div
              class="cursor-pointer text-center mx-n2 mb-n2 mt-3"
              :class="{ 'bg-background': isMobile }"
              @click="toggleList()"
              v-if="!isMobile"
            >
              <b-btn
                variant="link"
                v-if="tokenData.data.sources.length > 1 && index == 0"
                class="read-more rounded-circle text-center p-0"
              >
                <i class="fas fa-angle-down"></i>
              </b-btn>
              <b-btn
                variant="link"
                v-if="
                  tokenData.data.sources.length > 1 &&
                  index == tokenData.data.sources.length - 1
                "
                class="read-less rounded-circle text-center p-0"
              >
                <i class="fas fa-angle-up"></i>
              </b-btn>
            </div>
          </div>
        </span>
      </div>
    </div>
    <synopsis
      v-model="showSynopsis"
      @closed="
        synopsisFileIndex = -1
        showSynopsis = false
      "
    />
    <ChapterPopup
      v-model="showFullSource"
      v-if="showFullSource"
      :src-item="itemForChapter"
    ></ChapterPopup>
    <server-failed-popup
      v-if="failed"
      v-model="failed"
      :msg="serverErrorMessage"
    />
  </div>
</template>

<script>
//import _ from 'lodash'
import { Actions } from "@/store/stateChanges"
import ChapterPopup from "@/components/ChapterPopup"
import Synopsis from "@/components/SynopsisResults"
import { RunStates } from "@/store/runStates"
import ServerFailedPopup from "@/components/ServerFailedPopup"

export default {
  name: "FootnoteDisplay",
  components: { ChapterPopup, Synopsis, ServerFailedPopup },
  data() {
    return {
      showFullSource: false,
      comparedText: [],
      showLoader: false,
      synopsisFileIndex: -1,
      itemForChapter: {},
      searchedTextArr: [],
      matchedArr: [],
      displayText: "",
      parallelResults: [],
      activeSrcIndex: -1,
      showMetaData: false,
      showSynopsis: false,
      sefariaData: [],
      singleObjectClicked: false,
      sourcesToExclude: [],
      showEarly: false,
      numResults: 0,
      selectedParallelIndex: -1,
    }
  },
  props: ["tokenData", "feedbackMode", "feedbackTopic"],
  mounted() {},
  methods: {
    stringWithoutMeteg(wrd) {
      return wrd.replace(/(.)\u05bd/g, "")
    },
    getBaseText() {
      // Function to remove Nikud and Eimot
      const removeHebrewMarks = (text) =>
        text.replace(/[\u0591-\u05C7\u05B0-\u05B9\u05BB-\u05BC]/g, "")

      // Compare base text and selected text
      let baseText = removeHebrewMarks(
        this.tokenData.data.baseMatchedText
      ).replace(/\s+/g, " ")
      let selectedText = removeHebrewMarks(this.tokenData.selectedText).replace(
        /\s+/g,
        " "
      )

      // Replace the selected text in the base text with bold tags
      return baseText.replace(
        new RegExp(selectedText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi"),
        `<b>${selectedText}</b>`
      )
    },
    checkFeedbackClicked(index) {
      return (
        this.feedbackMode &&
        index === this.selectedParallelIndex &&
        this.feedbackTopic === "Parallels"
      )
    },
    parallelClicked(txt, clickedIndex) {
      if (this.feedbackMode) {
        this.selectedParallelIndex = clickedIndex
        this.$emit("tokenClicked", {
          text: txt.replace(/<\/?[^>]+(>|$)/g, "") + "...",
          topic: "Parallels",
        })
      }
    },
    showSynopsisClicked() {
      this.synopsisFileIndex = this.synopsisFiles.length
      this.$store.commit("SET_TEXT_FOR_SYNOPSIS", this.tokenData.data)
      this.$store.dispatch(Actions.RUN_SYNOPSIS)
    },
    setVerse(item) {
      this.$store.commit("SET_VERSE_FOR_CHAPTER", item)
      this.$store.dispatch(Actions.RUN_CHAPTERS)
      this.itemForChapter = null
      this.showFullSource = true
    },
    /*     getSynopsisIndex(item) {
      let index = this.synopsisFiles.findIndex(el => JSON.stringify(el.sourceData.baseMatchedText) === JSON.stringify(item.baseMatchedText))
      return index
    }, */
    toggleList() {
      if (!this.isMobile) {
        const selectedList = this.$refs.sourceItem
        selectedList.classList.toggle("show-all")
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state.synopsisServerState === RunStates.RUNNING
    },
    hebrew() {
      return this.$settings.hebrew
    },
    loadingMobile() {
      return this.loading && this.isMobile
    },
    isMobile() {
      return this.$mq === "sm"
    },
    fontSize() {
      return this.$store.state.fontSize
    },
    dictaFont() {
      return this.$store.state.useDictaFont
    },
    computedFontSize() {
      return this.dictaFont
        ? this.$mq === "sm"
          ? this.fontSize + 6
          : this.fontSize + 4
        : this.$mq === "sm"
        ? this.fontSize + 2
        : this.fontSize
    },
    synopsisFiles() {
      return this.$store.state.synopsisFiles
    },
    synopsisComplete() {
      return this.$store.state.synopsisServerState === RunStates.COMPLETE
    },
    serverErrorMessage() {
      return this.hebrew
        ? "לא ניתן להציג את החלון להשוואת גרסאות"
        : "Unable to display the Parallels screen"
    },
    failed: {
      get() {
        return (
          this.$store.state.synopsisServerState === RunStates.FAILED ||
          this.$store.state.synopsisServerState === RunStates.TIMED_OUT
        )
      },
      set() {
        this.$store.commit("LIBRARY_NOT_RUN")
        // this.synopsisFileIndex = -1
      },
    },
  },
  watch: {
    synopsisComplete(val) {
      if (val) {
        this.showSynopsis = true
      }
    },
  },
}
</script>
<style lang="scss">
.parallels {
  @media screen and (min-width: 768px) {
    max-height: 72vh;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .f-dicta {
    line-height: 1.1;
  }

  .f-narkis {
    line-height: 1.3;
  }

  .source-name {
    &:hover {
      text-decoration: underline;
      color: #005499 !important;
    }
  }

  .identified-text {
    //font-size: 12px;
    font-family: "Arimo";
  }

  .item-wrap {
    padding: 20px;
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px #d7d7d7;
  }

  .p-group {
    @media screen and (max-width: 767px) {
      .matched-text {
        // margin: -20px -20px 20px;
        padding: 20px 20px 0;
        background-color: #f6f6f6;
      }
    }

    .parallel-num,
    .synopsis-btn {
      font-family: "Arimo";
      vertical-align: top;
    }

    .fas {
      font-size: 20px;
    }

    .read-less {
      display: none;
    }

    .inner-results {
      display: none;
    }
  }

  .read-less,
  .read-more {
    width: 28px;
    height: 28px;
    line-height: 33px;
  }

  .show-all {
    .read-less {
      display: inline-block !important;
      line-height: 30px;
    }

    .read-more {
      display: none;
    }

    .inner-results {
      display: block !important;
    }
  }
}
</style>
