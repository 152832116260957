<template>
  <b-modal id="chapter-modal" centered size="lg" body-class="d-flex flex-column p-3 f-narkis"
    header-class="bg-primary text-white" footer-class="border-top d-none d-sm-block" ok-variant="secondary"
    :ok-title="hebrew ? 'סגור' : 'Close'" ok-only v-model="show" @show="scrollIntoView">

    <template slot="modal-header">
      <h3 class="my-0 text-white pl-4 pl-sm-0" :class="{ expanded: expanded }" @click="expanded = !expanded">
        <span v-if="srcItem">
          {{ srcItem.compNameHe }}
        </span>
        <span v-else>
          {{ $store.state.verseForChapter.verseDispHeb }}
        </span>
      </h3>
      <small class="d-none d-sm-block" v-if="srcItem && srcItem.url && srcItem.url.includes('sefaria') || !srcItem">
        <a class="text-white" :href="srcItem ? srcItem.url : verseId" target=" _blank" rel="noopener noreferrer">
          <i class="fas fa-external-link-alt mx-1"></i> {{ hebrew ? 'ספריא' : 'Sefaria' }}
        </a></small>
      <span class="d-block d-sm-none text-primary text-left">
        <a v-if="srcItem && srcItem.url && srcItem.url.includes('sefaria') || !srcItem"
          class="sefaria-mobile text-white mx-2" :href="srcItem ? srcItem.url : verseId" target="_blank"
          rel="noopener noreferrer">
          {{ hebrew ? 'ספריא' : 'Sefaria' }}
        </a>
        <div @click="hidePopup" class="close-icon text-white">&times;</div>
      </span>
    </template>
    <div class="text-right px-2 verses-list" v-if="srcItem">
      <span>{{ srcItem.compMatchedText.substring(0, srcItem.compStartChar) }}</span>
      <b id="matched-text">{{ srcItem.compMatchedText.substring(srcItem.compStartChar,
          srcItem.compStartChar + srcItem.compTextLength)
      }}</b>
      <span>{{ srcItem.compMatchedText.substring(srcItem.compStartChar + srcItem.compTextLength,
          srcItem.compMatchedText.length)
      }}</span>
    </div>
    <ul class="verses-list p-0 m-0" v-else>
      <li v-for="(item, key) in $store.state.chapterResults.text" v-bind:key="key"
        v-bind:class="{ 'active-line': item.containsMatch }">
        <div class="row mb-1">
          <div class="col-2 col-sm-1"><small class="pasuk-header">{{ item.label }}</small></div>
          <div class="d-inline-block text-right pl-2">
            <p v-html="item.text" class="my-0"></p>
          </div>
        </div>
      </li>
    </ul>
  </b-modal>
</template>

<script>
export default {
  name: 'ChapterPopup',
  props: ['value', 'srcItem'],
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    hidePopup() {
      this.expanded = false
      this.show = false
      // history.pushState('', document.title, window.location.pathname + window.location.search)
    },
    scrollIntoView() {
      setTimeout(function () {
        if (document.getElementsByClassName('active-line')[0]) {
          document.getElementsByClassName('active-line')[0].
            scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 900)
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    verseId: function () {
      let verseId = this.$store.state.verseForChapter.compBookXmlId
      let mode = this.$store.state.verseForChapter.mode
      if (verseId) {
        let url = 'http://www.sefaria.org/' + verseId.split('.').slice(-3).join('.')
        if (mode === 'Talmud')
          url = url.replace(/.([^.]*)$/, '$1')
        if (this.hebrew)
          url = url + '?lang=he'
        else
          url = url + '?lang=en'
        return url
      } else {
        return ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.d-inline-block {
  max-width: 90%;

  @media only screen and (max-width: 676px) {
    max-width: 80%;
  }
}


.active-line {
  .row div {
    background-color: #d6ecff;
    border-radius: 2px;
  }
}
</style>
<style lang="scss" scoped >
.verses-list {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

li {
  display: inline;
  font-size: 20px;
}

.pasuk-letter {
  right: -32px;
  top: 25px;
  font-family: 'Arimo';
  margin-top: -20px;
}

@media only screen and (max-width: 767px) {
  #chapter-modal {
    .modal-dialog {
      margin: 0 !important;
      overflow: hidden;
    }

    .modal-content {
      border-radius: 0 !important;
      height: 100vh;
      background: white;
      overflow: hidden;
    }

    .close-icon {
      top: 6px;
      display: inline;
      font-size: 34px;
      line-height: 15px;
      position: relative;
    }

    h3 {
      padding: 5px 0;
      font-size: 20px;
      font-family: Arimo;
      width: 90%;
      width: 70%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;

      &.expanded {
        white-space: normal;
      }
    }
  }

  .sefaria-mobile {
    font-family: Arimo;
    text-decoration: underline;
  }

  .verses-list {
    max-height: 90vh;
  }

  .chapter-popup-content {
    padding-top: 50px;
  }
}
</style>
