import Vue from "vue"
import Router from "vue-router"
import Publications from "./views/Publications.vue"
import File from "./views/File.vue"
import XmlId from "./views/XmlId.vue"
import TextEditor from "./views/TextEdit.vue"
import ApiChildHandler from "./views/ApiChildHandler.vue"
import { StoreConfig } from "./store/storeConfig"

Vue.use(Router)

const isFileUpload = process.env.VUE_APP_FILE_TYPE === "file-upload"
const isInternalSingle =
  process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle")

const corpusRoutes = [
  {
    path: "/",
    name: "Home",
    component: isInternalSingle ? File : Publications,
  },
  {
    path: "/book/:bookName/:pageNum",
    name: "File",
    component: File,
  },
  {
    path: "/xmlid",
    name: "XmlId",
    component: XmlId,
  },
  {
    path: StoreConfig.serverRoot + "/*",
    name: "api-child-handler",
    component: ApiChildHandler,
  },
]

const fileRoutes = [
  {
    path: "/publications",
    name: "Publications",
    component: Publications,
  },
  {
    path: "/results",
    name: "File",
    component: File,
  },
  {
    path: "/",
    name: "TextEditor",
    component: TextEditor,
  },
  {
    path: StoreConfig.serverRoot + "/*",
    name: "api-child-handler",
    component: ApiChildHandler,
  },
]

export default new Router({
  mode: "history",
  routes: isFileUpload ? fileRoutes : corpusRoutes,
})
