<template>
    <div class="generated-ai-summary mb-3">        
        <div class="short-summary raised-frame px-2 pb-3 pt-1 mb-3 mx-1">
            <div class="d d-flex justify-content-end">
                <small class="text-muted"
                    v-b-popover.hover.bottom="eng ? tooltipEng : tooltipHeb">AI
                </small>
            </div>
            <div v-if="!eng" class="f-narkis">
                <p>
                    <strong>שאלה: </strong>
                    {{ shortSummaryObj.question.he }}
                </p>
                <p class="mb-0">
                    <strong>תשובה: </strong>
                    {{ shortSummaryObj.answer.he }}
                </p>
            </div>
            <div v-else class="text-left" dir="ltr">
                <p>
                    <strong>Question: </strong>
                    {{ shortSummaryObj.question.en }}
                </p>
                <p class="mb-0">
                    <strong>Answer: </strong>
                    {{ shortSummaryObj.answer.en }}
                </p>

            </div>            
        </div>
        <div class="summary" v-if="summaryObj.question">
            <div class="heading w-100 d-flex justify-content-between bg-background px-2"
                :class="questionVisible ? null : 'collapsed'" :aria-expanded="questionVisible ? 'true' : 'false'"
                aria-controls="collapse-question" @click="questionVisible = !questionVisible">
                <small>
                    <i class="far fa-plus-square" v-if="!questionVisible"></i>
                    <i class="far fa-minus-square" v-else></i>
                    <span class="mx-2">{{eng ? 'Summary of Question' : 'סיכום שאלה'}}</span>
                </small>
                <small class="text-muted" v-b-popover.hover.bottom="eng ? tooltipEng : tooltipHeb">AI</small>
            </div>
            <b-collapse id="collapse-question" v-model="questionVisible">
                <div class="bg-background px-3 pb-3">
                    <div class="summary-content p-4">
                        <span v-if="!eng">
                            <p class="f-narkis" v-html="summaryObj.question.he"></p>
                            <a class="d-block text-center" href="#question"><small class="text-underline">שאלה מלאה</small></a>
                        </span>
                        <span v-else>
                            <p class="text-left mt-3" dir="ltr" v-html="summaryObj.question.en"></p>
                            <a class="d-block text-center" href="#question"><small class="text-underline">Full question</small></a>
                        </span>
                    </div>
                </div>
            </b-collapse>
        </div>

        <div class="summary mt-2" v-if="summaryObj.answer">
            <div class="heading w-100 d-flex justify-content-between bg-background px-2"
                :class="answerVisible ? null : 'collapsed'" :aria-expanded="answerVisible ? 'true' : 'false'"
                aria-controls="collapse-answer" @click="answerVisible = !answerVisible">
                <small>
                    <i class="far fa-plus-square" v-if="!answerVisible"></i>
                    <i class="far fa-minus-square" v-else></i>
                    <span class="mx-2">{{eng ? 'Summary of Answer' : 'סיכום תשובה'}}</span>
                </small>
                <small class="text-muted" v-b-popover.hover.bottom="eng ? tooltipEng : tooltipHeb">AI</small>
            </div>
            <b-collapse id="collapse-answer" v-model="answerVisible">
                <div class="bg-background px-3 pb-3">
                    <div class="summary-content p-4">
                        <span v-if="!eng">
                            <p class="f-narkis">{{ summaryObj.answer.he }}</p>
                            <a class="d-block text-center" href="#answer"><small class="text-underline">תשובה מלאה</small></a>
                        </span>
                        <span v-else>
                            <p class="text-left mt-3" dir="ltr">{{ summaryObj.answer.en }}</p>
                            <a class="d-block text-center" href="#answer"><small class="text-underline">Full answer</small></a>
                        </span>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>
<script>
export default {
    name: "GeneratedSummary",
    props: ['eng'],
    components: {},
    data() {
        return {
            tooltipEng: 'In the simplest terms, AI which stands for artificial intelligence refers to systems or machines that mimic human intelligence to perform tasks and can iteratively improve themselves based on the information they gather. AI manifests itself in several forms. Some examples are:',
            tooltipHeb: 'במונחים הפשוטים ביותר, AI אשר מייצג בינה מלאכותית מתייחס למערכות או מכונות המחקות אינטליגנציה אנושית לביצוע משימות ויכולות לשפר את עצמן באופן איטרטיבי על סמך המידע שהן אוספות. AI מתבטא במספר צורות. כמה דוגמאות הן:',
            questionVisible: false,
            answerVisible: false,
        }
    },
    computed: {
        fileData() {
            return this.$store.state.fileData
        },
        summaryData() {
            return this.fileData?.data?.AIGenerated
        },
        summary() {
            return this.summaryData ? this.summaryData.summaries.find(item => item.summaryType === "Long Summaries") : []
        },
        shortSummary() {
            return this.summaryData ? this.summaryData.summaries.find(item => item.summaryType === "One-line Summaries") : [] 
        },
        shortSummaryObj() {
            return this.shortSummary.summaries ? {
                question: {
                    en: this.shortSummary.summaries[0].summary[0].text,
                    he: this.shortSummary.summaries[0].summary[1].text
                },
                answer: {
                    en: this.shortSummary.summaries[1].summary[0].text,
                    he: this.shortSummary.summaries[1].summary[1].text
                } 
            } : {}
        },
        summaryObj() {
            return this.summary.summaries ? {
                question: {
                    en: this.summary.summaries[0].summary[0].text,
                    he: this.summary.summaries[0].summary[1].text
                },
                answer: {
                    en: this.summary.summaries[1].summary[0].text,
                    he: this.summary.summaries[1].summary[1].text
                } 
            } : {}
        }        
    }
}        
</script>
<style lang="scss">
.short-summary {
    background-color: #fffef7;
    line-height: 22px;
}
.summary {
    border-radius: 4px;
    border: solid 1px #d7d7d7;
    overflow: hidden;
    .heading {
        cursor: pointer;
        .text-muted {
            color: #acacac!important;
        }
    }    
    .summary-content {
        line-height: 25px;
        border-radius: 4px;
        background-color: #fffef7;
        ul {
            padding: 0 20px;
            li {
                margin-bottom: 5px;
            }
        }
    }
}
.popover {
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.14);
    border: solid 1px #d8d8d8;
    top: -6px!important
}
.popover .arrow {
    display: none;
}
</style>