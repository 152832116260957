<template>
  <b-modal v-model="show" id="synsposis-modal" ok-only centered size="lg" footer-class="border-top" header-class=""
    :title="hebrew ? 'השוואת גרסאות' : 'Synopsis'" :ok-title="hebrew ? 'סגור' : 'Close'" ok-variant="secondary"
    @hidden="$emit('closed')">
    <div class="synopsis-wrap px-2 pt-4">
      <table id="table" class="table-data table m-auto" dir="rtl">
        <tbody id="tableBody">
          <tr class="first-row">
            <th class="first-col">&nbsp;</th>
            <th v-for="(columnHead, index) in columnHeads" :key="'head-' + index">
              {{ trimExt(columnHead).replace('000', sourceText).replaceAll('_', ' ').replaceAll('%22', '"') }}</th>
          </tr>
          <tr :key="'row-' + index" :class="{ 'not-all-same': notAllSame(index) }"
            v-for="(showRow, index) in thisPageRows">
            <td class="first-col" v-if="index > 0">{{ index }}</td>
            <td class="f-narkis" v-for="(col, colIndex) in showRow" :key="'col-' + colIndex">
              {{ col }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'Synopsis',
  props: ["value"],
  data() {
    return {
      showAll: true,
    }
  },
  methods: {
    trimExt: function (fname) {
      return fname.replace(/\.[^/.]+$/, '')
    },
    notAllSame(index) {
      return !['none', 'Nav', 'Anchor', 'Nikud'].includes(this.synopsisData[index].diff)
    }
  },
  computed: {
    hebrew() {
      return this.$settings.hebrew
    },
    sourceText() {
      return this.hebrew ? 'טקסט מקור' : 'Source Text'
    },
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    synopsisData() {
      return this.$store.state.synopsisFiles && this.$store.state.synopsisFiles.data ?
        this.$store.state.synopsisFiles.data : []
    },
    synopsisFile() {
      return this.$store.state.synopsisFiles.file
    },
    thisPageRows() {
      return this.synopsisData.map((e, index) => {
        if (index > 0)
          return e.values
      })
    },
    columnHeads: function () {
      return this.synopsisData.length > 0 ? this.synopsisData[0].values : []
    }
  }
}
</script>  
<style lang="scss">
#synsposis-modal {
  @media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
      max-width: 90%;
    }
  }
}

.modal-header .close {
  position: absolute;
  right: 15px;
  top: 10px;
}

.he .modal-header .close {
  right: auto;
  left: 0px;
}

.synopsis-wrap {
  height: 70vh;
  overflow-y: auto;
}

#synsposis-modal {
  tr {
    display: table-cell;

    &.first-row {
      min-width: 250px;
      border-bottom: none;
      background-color: #F0F0F0;
      border-left: solid 1px #007ee5;
      text-align: right;
    }
  }

  .table {
    border: 1px solid #ccc;

    td,
    th {
      display: block;
      border-bottom: none;
      border-top: none;
      border-right: none;
      overflow-y: hidden;
      border-bottom: none;
      height: 54px;

      &:nth-child(odd) {
        background-color: #f8f8f8;
      }
      
      &.first-col {
        text-align: center;
        font-size: 12px;
        background: rgba(223, 223, 223, 0.87);
        font-weight: normal !important;
        line-height: 48px;
        padding: 0 !important;
        height: 48px;
      }
    }

    th {
      font-size: 13px;
      padding: 0 15px !important;
      border-left: none;
      font-weight: normal;
      line-height: 20px;
      padding: 8px 15px !important;
      overflow-y: auto;
      &:first-letter {
        text-transform: capitalize;
      }
    }

    td {
      padding: 7px 15px !important;
      border-left: 1px solid #ccc;
      line-height: 40px;
      min-width: 100px;
      font-size: 20px;
      text-align: center;
    }

  }

  .not-all-same {
    td:not(.first-col) {
      background-color: #d6ecff;
    }
  }
}
</style>