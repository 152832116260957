<template>
  <header
    class="position-relative bg-white"
    style="z-index: 1023"
    v-show="!headerHidden"
  >
    <!--  <div class="bg-white iframe-placeholder d-none d-sm-block" style="height:30px;"></div> -->
    <dicta-header :englishSupported="false"></dicta-header>
    <!-- <iframe
      ref="headerIframe"
      style="z-index: 999;top:0;left:0"
      class="position-absolute bg-white iframe-placeholder d-none d-sm-block overflow-hidden"
      scrolling="no"
      frameborder="0"
      height="30"
      width="100%"
      :src="iframeSrc"
        ></iframe> -->
    <div class="bg-primary second-header d-flex position-relative">
      <b-container :fluid="$mq !== 'xl'">
        <div class="d-flex justify-content-between">
          <div class="logo-div py-2">
            <h2
              :style="{
                cursor: internalSingle ? 'default' : 'pointer',
              }"
              @click="logoClicked"
              class="logo d-inline-block bg-white rounded-circle text-primary text-center m-0 pt-sm-1"
            >
              <strong>ד</strong>
            </h2>
            <div class="d-inline-block text-white mx-2">
              <div v-if="fileUpload" class="tool-name">
                {{ hebrew ? "דיקטה מייבין" : "Dicta Mayven" }}
              </div>
              <div v-else-if="shasLibrary" class="tool-name">
                {{ hebrew ? 'הש"ס המנוקד' : "Vocalized Shas" }}
              </div>
              <div v-else class="tool-name">
                {{ hebrew ? "הספרייה של דיקטה" : "The Dicta Library" }}
              </div>
              <small class="d-none dicta">{{
                hebrew ? "גרסת אלפא" : "Alpha Version"
              }}</small>
            </div>
          </div>

          <div class="d-flex align-items-center d-sm-none">
            <mobile-menu
              class="mt-1 text-body"
              @showHowDialog="showAbout = true"
              :howDialog="true"
              :custom-links="customMenuLinks"
            >
            </mobile-menu>
            <!-- <iframe
              ref="mobileIframe"
              height="124"
              width="16vw"
              class="mobile-iframe"
              scrolling="no"
              frameborder="0"
              :src="iframeSrc"
              ></iframe> -->
          </div>
          <div class="search-form-wrap d-none d-sm-block">
            <div class="d-flex align-items-center h-100 justify-content-end">
              <search v-if="!internalSingle" class="mx-sm-2"></search>
              <div
                v-if="$route.name === 'File'"
                class="d-none d-lg-flex flex-row-reverse align-items-center mx-3"
              >
                <b-form-checkbox
                  v-model="feedbackMode"
                  switch
                  @input="feedBackToggled"
                >
                  <span class="text-white">
                    {{ hebrew ? "שליחת משוב" : "Send Feedback" }}
                  </span>
                </b-form-checkbox>
              </div>
              <b-button
                id="about-btn"
                variant="primary"
                class="btn-outline-light px-0 d-none d-sm-inline-block"
                @click="showAbout = true"
              >
                {{ hebrew ? "איך זה עובד" : "How it works" }}
              </b-button>
            </div>
            <about-dialog v-model="showAbout"></about-dialog>
          </div>
        </div>
      </b-container>
    </div>
  </header>
</template>
<script>
import AboutDialog from "@/components/aboutDialog"
import Search from "@/components/Search"
import eventBus from "@/js/EventBus"

export default {
  name: "Header",
  components: {
    AboutDialog,
    Search,
  },
  data() {
    return {
      //iframeSrc: 'https://dicta.org.il/dicta-header',
      iframeSrc: "http://10.0.0.240:8080/dicta-header",

      showBackdrop: false,
      feedbackMode: false,
      showAbout: false,
      fileUpload: process.env.VUE_APP_FILE_TYPE === "file-upload",
      shasLibrary: process.env.VUE_APP_FILE_TYPE === "shas",
      internal: process.env.VUE_APP_FILE_TYPE === "internal",
      internalSingle:
        process.env.VUE_APP_FILE_TYPE.startsWith("internalSingle"),
      customMenuLinks: [
        {
          engHref: "https://librarysearch.dicta.org.il/",
          hebHref: "https://librarysearch.dicta.org.il/",
          logo: "/Logo_Search_Blue_Circle.png",
          engTitle: "Dicta Library Search",
          engSubtitle: "",
          hebTitle: "חיפוש בספרייה של דיקטה",
          hebSubtitle: "",
        },
      ],
    }
  },
  mounted() {
    window.addEventListener("message", this.handleMessage)
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage)
  },
  methods: {
    logoClicked() {
      if (!this.internalSingle) {
        if (this.$route.name === "Publications") eventBus.$emit("logoClicked")
        else this.$router.push({ name: "Home" })
      }
    },
    handleMessage(event) {
      if (event.data.message === "PAGE_LOADED") {
        const iframe = document.querySelector("iframe")
        if (iframe) {
          iframe.contentWindow.postMessage(
            {
              message: "HEADER_EMBEDDED",
              data: {
                hebrew: this.hebrew,
                url: window.location.href,
              },
            },
            this.iframeSrc
          )
        }
      }
      if (event.data.message === "SHOW_MOBILE_ABOUT") {
        this.showAbout = true
      }
      if (event.data.message === "MOBILE_MENU_TOGGLE") {
        if (event.data.value) {
          this.$refs.mobileIframe.classList.add("mobile-iframe-full-height")
        } else {
          this.$refs.mobileIframe.classList.remove("mobile-iframe-full-height")
        }
        document.body.style.overflow = event.data.value ? "hidden" : "auto"
      }
      if (event.data.message === "MENU_TOGGLE") {
        this.showBackdrop = event.data.value
        this.$refs.headerIframe.style.height = event.data.value
          ? "380px"
          : "30px"
      }
      if (event.data.message === "CONTACT_TOGGLE") {
        this.$refs.headerIframe.style.height = event.data.value
          ? "100vh"
          : "30px"
        document.body.style.overflow = event.data.value ? "hidden" : "auto"
      }
    },
    feedBackToggled() {
      eventBus.$emit("feedbackModechanged", this.feedbackMode)
    },
  },
  computed: {
    headerHidden() {
      return this.$store.state.hideHeader
    },
    hebrew() {
      return this.$settings.hebrew
    },
  },
}
</script>
<style lang="scss">
.mobile-iframe {
  height: 24px;
  width: 16vw;
  &.mobile-iframe-full-height {
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    z-index: 1;
    top: 0;
  }
}
.second-header {
  box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 32%);
  z-index: 1;
  .custom-control.custom-switch .custom-control-label:before {
    opacity: 0.46;
    background-color: #e3e3e3;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    opacity: 1;
    background-color: #fff;
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
    background-color: var(--dark);
  }
  .logo {
    height: 36px;
    width: 36px;

    @media screen and (max-width: 767px) {
      height: 32px;
      width: 32px;
      line-height: 32px;

      strong {
        line-height: 21px;
        padding-right: 1px;
      }
    }
    vertical-align: middle;
  }

  button.btn-primary {
    height: 36px;
    width: 100px;
    font-size: 16px;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 576px) {
    .search-form-wrap {
      background: #bfe1fe;
    }
  }
}
</style>
