import Vue from "vue";
import Vuex from "vuex";
import basicMutations from "./store/basicMutations";
import { BlankState } from "./store/stateChanges";
import libraryActions from "./store/libraryActions";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: JSON.parse(JSON.stringify(BlankState)),
  mutations: {
    ...basicMutations,
  },
  actions: {
    ...libraryActions,
  },
});

export default store;
